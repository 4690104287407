<!-- <app-category-header></app-category-header> -->
<div class="text-center body-img" *ngIf="!productResponse">
    <img src="assets/image/gif.gif">
</div>
<!---<a rel="canonical" [routerLink]="['/'+product.urlKey+'/p/'+product._id]"></a>--->

<!------JsonCode------>
<script type="application/ld+json" *ngIf="productResponse">
    {
      "@context":"http://schema.org",
      "@type":"Product",     
      "name": {{ product?.name | json }},
      "sku": {{ product?.sku | json }},
      "description": {{ product?.description | strip_html | truncatewords: 50 | json }},
      "url": {{ canonical_url | json }},
      "image": "https://aapkabazar.co/{{ product?.urlKey+'/p/'+product?._id }}",
      "brand": {
        "@type": "Brand",
        "name": "Aap Ka Bazar",
        "url": {{ canonical_url | json }}
      },
      "gtin": "{{ product?.sku }}",
      "offers": [{
        "@type": "Offer",
        "priceCurrency": "{{ cart.currency.iso_code }}",
        "price": "{{ product?.minSellPrice | money_without_currency | remove: ',' }}",
        "availability": "http://schema.org/{% if bag[product?._id]?.itemQuantity > 0 %}InStock{% else %}OutOfStock{% endif %}",
        "itemCondition": "http://schema.org/NewCondition",
        "seller": "Aap Ka Bazar",
        "url": "https://aapkabazar.co/{{product?.urlKey+'/p/'+product?._id}}"
      }]      
    }
    </script>
<!------JsonCode------>

<!---------FB-Auto-Fetch-Tag--------->
<div itemscope itemtype="http://schema.org/Product" *ngIf="productResponse">
    <meta itemprop="brand" content="Aap Ka Bazar">
    <meta itemprop="name" content="{{ product?.name | titlecase }}">
    <meta itemprop="description" content="{{product?.description}}">
    <meta itemprop="productID" content="{{ product?.sku }}">
    <meta itemprop="url" content="https://aapkabazar.co/{{product?.urlKey+'/p/'+product?._id}}">
    <meta itemprop="image" content="{{ product?.id | image}}/{{ product?.images[0] | imageSize:400 }}">
    <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
        <link itemprop="availability" href="http://schema.org/InStock">
        <link itemprop="itemCondition" href="http://schema.org/NewCondition">
        <meta itemprop="price" content="{{ product?.minSellPrice}}">
        <meta itemprop="priceCurrency" content="INR">
    </div>
</div>
<!---------FB-Auto-Fetch-Tag--------->


<div *ngIf="productResponse">
    <div class="container" *ngIf="product?.name!=null">

        <div class="">
            <div class="">
                <div class="row SingalProductPage">
                    <div class="col-md-6">
                        <div class="row ">
                            <div class="col-md-8 offset-md-2">
                                <div class="card product-slider">
                                    <owl-carousel-o [options]="owlOptions">
                                        <ng-container>
                                            <ng-template *ngFor="let img of product?.images" carouselSlide>
                                                <img src="{{ product?.id | image}}/{{ img | imageSize:400 }}"
                                                    onerror="this.src='../../../assets/image/no-product-img.png';"
                                                    alt="{{ product?.name | titlecase }}"
                                                    title="{{ product?.name | titlecase }}">
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                </div>

                            </div>
                        </div>

                        <!-- <owl-carousel-o class="row sub-carousel mt-2" [options]="owlImageOptions">
                        <ng-container class="col-2">
                            <ng-template *ngFor="let img of product.images" carouselSlide>
                                <div class="card sub-product pointer">
                                    <img class="mx-auto img-fluid py-1 px-2"
                                        src="{{ product?.id | image}}/{{ img | imageSize:120 }}"
                                        alt="product"
                                        onerror="this.src='../../../assets/image/no-product-img.png';">
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o> -->
                    </div>

                    <div class="col-md-6 p-3">
                        <h1 class="product-heading">{{ product?.name | titlecase }}</h1>
                        <!-- <div class="border-line"></div> -->
                        <!-- <hr class="grey-hr accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 100%;"> -->
                        <div class="d-flex">
                        </div>
                        <!-- <div>
                        <label class="mb-0 col-5 col-xl-3 col-sm-3 pl-0">Price : </label>
                        <label class=" mt-0 grey-text"><del>₹130</del></label>
                    </div> -->
                        <!-- <div class="PriceSingalpro">
                      <p *ngIf="product?.sellPrice!=product?.price">
                          <span class="rupya"> ₹{{ product?.sellPrice }}</span>
                          <del>₹{{ product?.price }}</del>
                          <span class="discountBadge" *ngIf="product?.sellPrice!=product?.price">
                              {{((product.price-product.sellPrice)/(product.price)*100).toFixed()}} % Off
                          </span>
                      </p>
  
                    </div> -->
                        <div class="PriceSingalpro">
                            <span *ngIf="product?.sellPrice!=product?.price"><del>₹{{ product?.price }}</del></span>
                            <!-- <span class="rupya"> ₹{{ product?.sellPrice }}</span> -->
                            <span class="rupya" *ngIf="bag[product?._id]?.itemQuantity > product?.storeMinQuantity"><i
                                    class="fas fa-inr-sign" aria-hidden="true"></i> ₹{{ product?.sellPrice }}</span>
                            <span class="rupya" *ngIf="bag[product?._id]?.itemQuantity <= product?.storeMinQuantity"><i
                                    class="fas fa-inr-sign" aria-hidden="true"></i> ₹{{ product?.minSellPrice}}</span>
                            <span class="discountBadge" *ngIf="product?.sellPrice!=product?.price">
                                {{(((product?.price-product?.minSellPrice)/(product?.price)*100).toFixed(1)) | roundoff
                                }}% OFF
                            </span>
                        </div>
                        <div>
                            <!-- <div class=" align-items-center mb-0">
                            <p><strong>Brand : </strong>
                              <a href="" *ngIf="product?.subBrand?.name"  class="brand-apka">{{ product?.subBrand?.name | titlecase }}</a>
                              <a href="" *ngIf="!product?.subBrand?.name"  class="brand-apka">Brand no found</a>
                            </p>
                        </div> -->
                        </div>
                        <!-- <div class=" align-items-center mb-0">
                        <ng-template #notStock>
                            <label class=" mt-0 grey-text">Out of Stock</label>
                        </ng-template>
                    </div> -->
                        <!-- <div class=" align-items-center detail mb-0">
                        <p>Available in :<br>
                        <button mdbBtn type="button" class=" bag-btn-outline detail-button-2"
                            mdbWavesEffect>{{ product?.recommendedAttribute }}</button></p>
                    </div> -->
                        <div class="row" *ngIf="product?.childProductList?.length>0">
                            <div class="col-xl-4 col-6">
                                <select class="custom-select" (change)="getProduct($event.target.value)">
                                    <option *ngFor="let data of product?.childProductList" [value]="data?.productId">
                                        {{data?.recommendedAttribute}} - ₹{{data?.sellPrice}}</option>
                                </select>
                            </div>
                        </div>
                        <h6 class="HeadH6">&nbsp; Unit : {{ product?.recommendedAttribute }}</h6>
                        <h6 class="HeadH6">&nbsp; Item Code : {{ product?.barCode }}</h6>
                        <div class="row mt-4 addTocartBtn">
                            <div class="col-xl-12 col-md-12">
                                <div class="AddToCartBtn" *ngIf="bag[product?._id]!=null">
                                    <button type="button" class="mt-1 pt-2 waves-effect btn-outline-light"
                                        (click)=decrementQuantity()>
                                        <i class="fa  fa-minus " aria-hidden="true"></i>
                                    </button>
                                    <button type="button" class=" waves-effect  qty-btn"><span class="">{{
                                            bag[product?._id]?.itemQuantity }}</span></button>
                                    <button type="button" class="pt-2  waves-effect btn-outline-light"
                                        tooltip="Limited Quantity Avalilable. You can't add more"
                                        [isDisabled]="(bag[product?._id]?.itemQuantity<bag[product?._id]?.perUserOrderQuantity)"
                                        (click)=incrementQuantity() placement="bottom">
                                        <i class="fa fa-plus " aria-hidden="true"></i>
                                    </button>
                                </div>
                                <button class="btn btn-custom-second-primary mt-1 mb-0"
                                    *ngIf="product?.quantity!=0 && bag[product?._id]==null"
                                    (click)=incrementQuantity()>Add to cart</button>
                                <p class="grey-text out-of-stock" *ngIf="product?.quantity==0">
                                    Out Of Stock
                                </p>

                                <button class="btn btn-custom-second-primary mt-1 mb-0" mdbBtn color="light"
                                    mdbWavesEffect (click)=showBulkOrder()>Bulk Order</button>
                            </div>
                        </div>

                        <div class="apkabazar">
                            <h5 class="why-heading">Why Shop from Aap Ka Bazar?</h5>
                            <div class="row mb-usp">
                                <ul class="SignalPageAKB">
                                    <li>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <img src="assets/image/detail-3.png" class="img-main-d">
                                            </div>
                                            <div class="ml-2">
                                                <p class="icon-detail">100% return available</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <img src="assets/image/detail-1.png" class="img-main-d">
                                            </div>
                                            <div class="ml-2">
                                                <!-- <h5 class="mb-0">Original Branded Products</h5> -->
                                                <p class="icon-detail">Original Branded Products</p>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <img src="assets/image/detail-2.png" class="img-main-d">
                                            </div>
                                            <div class="ml-2">
                                                <!-- <h5 class="mb-0">Easy return & refunds</h5> -->
                                                <p class="icon-detail">Pure & Hygenically Packed</p>
                                            </div>
                                        </div>
                                    </li>

                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row SingalDisBox">
                    <tabset type="pills" class="pills">
                        <tab heading="Description" class="Description" id="DiscriptionBox">
                            <hr class="grey-hr accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 100%;">
                            <p class="px-3 mb-3" [innerHtml]='product?.description'></p>

                        </tab>
                        <button (click)="DiscriptionBtn()" type="checkbox" id="DiscriptionBtn">Show More</button>
                    </tabset>
                </div>

                <div class="SingalRetaledPro SingalDisBox">
                    <h4>Recently Viewed Products</h4>
                    <app-product-carousel [products]="mostViewProducts" [heading]="'Recently View Products'"
                        class="product-main"></app-product-carousel>
                </div>

            </div>
        </div>

    </div>
</div>




<!-- Location section -->
<ng-template #bulkOrder>
    <div class="new-location">
        <div class="modal-content location-modal">
            <button type="button" class="close pull-right" aria-label="Close" (click)=closeLoginModal()>
                <span aria-hidden="true">×</span>
            </button>
            <div class="modal-header">
                <h3>Welcome to APP KA BAZAR</h3>
            </div>
            <div class="model-body">
                <div class="container">
                    <div class="row">
                        <form [formGroup]="bulkOrderForm">
                            <div class="form-row" style="padding: 10px;">
                                <div class="form-group col-md-6">
                                    <label for="inputEmail4">Full Name</label>
                                    <input type="text" class="form-control" formControlName="name" id="inputEmail4"
                                        placeholder="Full Name">
                                    <small class="alert-address"
                                        *ngIf="bulkOrderForm.controls['name'].hasError('required') && bulkOrderForm.controls['name'].touched">*
                                        This field
                                        is required</small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputPassword4">Mobile No</label>
                                    <input type="number" class="form-control" formControlName="phoneNo"
                                        id="inputPassword4" placeholder="Mobile No">
                                    <small class="alert-address"
                                        *ngIf="bulkOrderForm.controls['phoneNo'].hasError('required') && bulkOrderForm.controls['phoneNo'].touched">*
                                        This field
                                        is required</small>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputAddress">Address</label>
                                    <input type="text" class="form-control" formControlName="address" id="inputAddress"
                                        placeholder="Address">
                                    <small class="alert-address"
                                        *ngIf="bulkOrderForm.controls['address'].hasError('required') && bulkOrderForm.controls['address'].touched">*
                                        This field
                                        is required</small>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputAddress2">Quantity</label>
                                    <input type="number" class="form-control" formControlName="quantity"
                                        id="inputAddress2" placeholder="Quantity">
                                    <small class="alert-address"
                                        *ngIf="bulkOrderForm.controls['quantity'].hasError('required') && bulkOrderForm.controls['quantity'].touched">*
                                        This field
                                        is required</small>
                                </div>
                            </div>
                            <button type="submit" (click)=orderBulkProduct() class="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /Location section -->