<main class="main">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <app-profile-sidebar></app-profile-sidebar>
            </div>

            <div class="col-md-8">
                <div class="card">
                    <div class="card-header p-3 information">
                        Change Password
                    </div>

                    <div class="card-body">
                        <form role="form" [formGroup]="passwordForm" (ngSubmit)="changePassword()">
                            <div class="form-group">
                                <label class="grey-text" for="currentPassword">Current Password :</label>
                                <input type="password" class="form-control col-sm-5 " id="currentPassword" placeholder="Enter Current password" required formControlName="currentPassword">
                                <!-- <div class="alertmsg">* Please enter correct password </div> -->
                            </div>

                            <!-- <p class="alertmsg" *ngIf="registrationFormGroup.controls.email.errors?.pattern">Please enter the valid email</p> -->
                            <div>
                                <div class="form-group mb-3">
                                    <label class="grey-text" for="new">New Password :</label>
                                    <input type="password" class="form-control col-sm-5" id="new" placeholder="Enter new password" required formControlName="newPassword">
                                    <!-- <div class="alertmsg">* Please enter your email address</div> -->
                                </div>
                                <div class="form-group mb-3">
                                    <label class="grey-text" for="confirm">Confirm Password :</label>
                                    <input type="password" class="form-control col-sm-5" id="confirm" placeholder="Enter confirm password" required formControlName="confirmPassword">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <button type="submit" class="btn btn-custom-primary" *ngIf='!isShowSpinner'>Update</button>
                                    <div class="spinner-border text-primary login-spinner" *ngIf='isShowSpinner'></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>