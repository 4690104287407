<div class="text-center body-img" *ngIf="!SubCategoryLoad">
  <img src="../../../assets/image/gif.gif">
</div>
<main class="main category-page">
  <div *ngIf="SubCategoryLoad">
    <div class="SubCatPageBanner">
      <img class="top-image"
        src="{{ categoryDetails.category.id | image:'cat' }}/{{ categoryDetails.category.images[1]}}"
        onerror="this.src='../../../assets/image/no-category.png';" [alt]="categoryDetails.category.category?.name"
        [title]="categoryDetails.category._name" *ngIf='categoryDetails.category.images[1]?.length>0'>
    </div>
    <div class="container-fluid product-page">
      <div class="cattegory-slide responsive-page">

        <!-- <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let subCategory of subCategories">
          <a [href]="['/'+rootCategoryName+'/'+subCategory.urlKey+'/c/'+rootCategoryId+'/'+subCategory._id]" >
            <img src="{{ subCategory.id | image:'cat' }}/{{ subCategory.images[0]}}" [alt]="subCategory?.name" [title]="subCategory._name" *ngIf='subCategory.images?.length>0'>
            <img src="../../../assets/image/no-category.png" *ngIf="subCategory.images[0] == null">
            <p>{{ subCategory?.name | capitalize}}</p>
          </a>
      </ng-template>
    </owl-carousel-o> -->
        <div class="category-overflow">
          <div class="topcategory active">
            <a [routerLink]="['/'+rootCategoryName+'/c/'+rootCategoryId]">
              <img src="assets/image/products.jpg" class="products">
            </a>
          </div>
          <div class="topcategory active" *ngFor="let subCategory of subCategories">
            <a [routerLink]="['/'+rootCategoryName+'/'+subCategory.urlKey+'/c/'+rootCategoryId+'/'+subCategory._id]">
              <img src="{{ subCategory.id | image:'cat' }}/{{ subCategory.images[0]}}" [alt]="subCategory?.name"
                [title]="subCategory._name" *ngIf='subCategory.images?.length>0'>
              <img src="../../../assets/image/no-category.png" *ngIf="subCategory.images[0] == null">
              <p>{{ subCategory?.name | capitalize}}</p>
            </a>
          </div>
        </div>

      </div>
      <div class="bg-bredcum MobBredcum">
        <span (click)="backClicked()"> <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span *ngIf="!subCategoryName">
            <span *ngIf="rootCategoryTitle">{{rootCategoryTitle}}</span>
          </span>
          <span *ngIf="subCategoryName">{{subCategoryName}}</span>
          <span *ngIf="leafCategoryName">{{leafCategoryName}}</span>
        </span>
      </div>

      <div class="row ">
        <div class="col-lg-3 fadeIn" data-wow-delay="2s">
          <div class="card category side-bar-category">
            <div class="category-header">
              <h1 class="pointer">{{rootCategoryTitle}}</h1>
              <!-- <div class="border-line"></div> -->
            </div>
            <ul class="root-category" *ngFor="let subCategory of subCategories">
              <a [href]="['/'+rootCategoryName+'/'+subCategory.urlKey+'/c/'+rootCategoryId+'/'+subCategory._id]">
                <li class="root-list pointer">
                  <span>{{ subCategory?.name | capitalize}}</span><span class="float-right custom-primary-color"></span>
                </li>
                <i [ngClass]='leafCategories.id==subCategory._id ? "expand fa fa-angle-up" : "expand fa fa-angle-down"'></i>
              </a>
              <div *ngIf="leafCategories.id==subCategory._id">
                <ul class="sub-category" *ngFor="let leafCategory of leafCategories.categories">
                  <a
                    [href]="['/'+rootCategoryName+'/'+subCategoryName+'/'+leafCategory.urlKey+'/c/'+rootCategoryId+'/'+subCategory._id]+'/'+leafCategory._id">
                    <li class="sub-list pointer">{{ leafCategory.name }}</li>
                  </a>
                </ul>
              </div>
            </ul>
          </div>

        </div>
        <div class="col-lg-9" infinite-scroll [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown()">
          <div class="card category-product">
            <div class="bg-bredcum WebBredcum">
              <a (click)="backClicked()">{{rootCategoryTitle}}</a>
              <a (click)="backClicked()">{{subCategoryName}}</a>
              <a (click)="backClicked()">{{leafCategoryName}}</a>
            </div>
            <div class="card-header">
              <div class="row">
                <div class="col-sm-4 col-5 ResultFound">
                  <h4 class="card-title"><a>Showing {{products?.length}} Results From AKB Store.</a></h4>
                </div>
                <div class="col-sm-8 col-12 px-sm-3 text-sm-right">
                  <div class="FiltterBtn">
                    <label class="mr-2"><img src="assets/image/sort.png" class="sort"> Sort By </label>
                    <div class="btn-group right-btn" mdbDropdown>
                      <button mdbDropdownToggle type="button" mdbBtn color="white"
                        class="dropdown-toggle custum-dropdown waves-light" mdbWavesEffect>{{ sortArg ? sortSearch+" ("
                        + sortArg+")" :"All products" | capitalize}}</button>
                      <div class="dropdown-menu dropdown-primary custum-dropdown-menu w-100">
                        <a class="dropdown-item" (click)="sortBy('','')">All</a>
                        <div class="divider dropdown-divider"></div>
                        <a class="dropdown-item" (click)="sortBy('low to high','price')">Price (Low to High)</a>
                        <div class="divider dropdown-divider"></div>
                        <a class="dropdown-item" (click)="sortBy('high to low','price')">Price (High to Low)</a>
                        <div class="divider dropdown-divider"></div>
                        <a class="dropdown-item" (click)="sortBy('low to high','discount')">Discount (Low to high)</a>
                        <div class="divider dropdown-divider"></div>
                        <a class="dropdown-item" (click)="sortBy('high to low','discount')">Discount (High to Low)</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body main-categories">
              <div>
                <div class="row" *ngIf="products?.length>0">
                  <div class="col-xl-3 col-md-3 col-sm-6 col-6 p-2 productList"
                    *ngFor="let product of products | sort : sortArg : sortSearch">
                    <app-product-card [product]="product"></app-product-card>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="products?.length==0">
                <div class="col-xl-3 col-md-3 col-sm-6 col-6" *ngFor="let sp of skeletonProducts">
                  <app-product-card-skeleton *ngIf="!isProductListAvailable"></app-product-card-skeleton>
                </div>
                <div class="col-xl-12 text-center">
                  <img src="../../../assets/image/noProductFound.png" *ngIf="isProductListAvailable" alt="">
                  <p style="color: grey;">Sorry, no product found!</p>
                  <a [routerLink]="['/']" class="continue">Shop Now</a>
                </div>
              </div>
              <div class="text-center" *ngIf='isShowLoader'>
                <!--  <div class="spinner-border text-primary"></div>
                    <h6 class="text-primary">Products Loading.....</h6> -->
                <img style="width: 200px;" src="../../../assets/image/load.gif" alt="">
              </div>

              <!-----No-More-Poroduct---->
              <div class="row NoMoreProduct" *ngIf='isAllProductEnd'>
                <div class="noProduct">In your service since - 2005</div>
              </div>
              <!-----No-More-Poroduct---->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button (click)="refresh()" class="RefreshBtn" *ngIf="RefreshBtn">Refresh</button>
</main>