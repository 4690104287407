<main class="main">
  <!--Carousel Wrapper-->
  <mdb-carousel class="d-none">
    <!--First slide-->
    <mdb-carousel-item>
      <img class="d-block img-fluid" src="assets/image/Grocery-Products-image/about-us.jpg" alt="First slide">
    </mdb-carousel-item>
    <!--/First slide-->

  </mdb-carousel>
  <!-- latest about us -->
  <section class="staticSection container">
    <div class="row align-items-center py-3 px-4">
      <span class="mainHeadding">FAQs: Because Every Customer Is Curious</span>
      <div class="MainContent">
        <ul class="faqsList">
          <li>
            <span>What are the AKB store's hours of operation?</span>
            <p>AKB stores are open for visitors from 9 AM to 10 PM (Time changes during special occasions), and online
              orders are also delivered during this time frame. However, online shopping is available 24/7.</p>
          </li>
          <li>
            <span>Does AKB offer home delivery services, and what is the delivery area?</span>
            <p>Yes, AKB delivers to your doorstep anywhere in Delhi and Gurugram, but soon, AKB will deliver throughout
              Delhi NCR.</p>
          </li>
          <li>
            <span>How can I place an order from AKB for a home delivery?</span>
            <p>Visit the official AKB website or download the AKB app (Play Store/Apple Store). Log in with your mobile
              number, select your desired items, click on bulk order, provide your necessary details, choose your
              payment method, and your order will be delivered to your doorstep.</p>
          </li>
          <li>
            <span>Is there a minimum order requirement for home delivery in AKB?</span>
            <p>There are no limitations for the minimum order amount in AKB.</p>
          </li>
          <li>
            <span>What are the AKB's accepted payment methods?</span>
            <p>When ordering through the AKB website or app, you can pay in advance with credit cards, debit cards,
              e-wallets, or third-party payment methods like Paytm, PhonePe, and BHIM. For pay-on-delivery or in-store
              purchases, AKB accepts cash (in valid Indian rupees), credit cards, debit cards, e-wallets, and
              third-party payment methods like Paytm, PhonePe, and BHIM etc.</p>
          </li>
          <li>
            <span>Does AKB have a return/refund/exchange policy for products?</span>
            <p>Yes! If you're not completely satisfied with the product, AKB offers returns and refunds for the full
              amount you paid through your chosen payment method, or exchanges for a product of the same
              amount/brand/quantity. For additional details, please review AKB's return/refund/exchange policy.</p>
          </li>
          <li>
            <span>Can I find a special discount on regional/home products in the AKB store?</span>
            <p>Yes, You Can! AKB offers exclusive discounts/special offers/different prices on their home products,
              which you can find on their app, website, or stores.</p>
          </li>
          <li>
            <span>How can I contact AKB's customer support for inquiries or issues?</span>
            <p>For inquiries, updates, issues, or information, you can reach us at 9150030303 between 10 am and 7 pm on
              working days, email us at <a href="mailto:support@aapkabazar.co">support@aapkabazar.co</a>, or visit
              the store and ask for the store manager.</p>
          </li>
          <li>
            <span>Does AKB have social media accounts for online updation?</span>
            <p>Yes, to connect with our customers, AKB is active on Instagram, Facebook, YouTube, Twitter, and Pinterest
              etc. for exciting offers and updates. Follow us for more fun.</p>
          </li>
          <li>
            <span>Can I buy goods/items in bulk to sell further?</span>
            <p>All AKB items are for domestic use only and cannot be resold.</p>
          </li>
          <li>
            <span>Are there any additional charges for home delivery?</span>
            <p>Yes! there are charges that based on your delivery location and amount of order purchase.</p>
          </li>
          <li>
            <span>Can I schedule a delivery for a specific date and time?</span>
            <p>Yes, you can select a specific time and date within 7 days of the order.</p>
          </li>
          <li>
            <span>What if I am not satisfied 100% with the received product?</span>
            <p>If you're not completely satisfied with your product, just email us <a
                href="mailto:support@aapkabazar.co">support@aapkabazar.co</a> for a
              return/refund/exchange. AKB credits to the same payment method of debited or provides a
              similar(price/weight/brand) product. Please refer to our return/refund/exchange policy for details.</p>
          </li>
          <li>
            <span>Can I cancel my order after it's been placed?</span>
            <p>You can cancel the order, but please do so before AKB begins the delivery process. If, for any reason,
              you don't want to accept the order, you can then request a return/refund/exchange.</p>
          </li>
          <li>
            <span>Why did AKB cancel my order?</span>
            <p>AKB may cancel orders due to: (a) customers' incomplete information, (b) rule violations, (c)
              unavailability of products, or (d) external factors, such as bad weather or government guidelines.</p>
          </li>
          <li>
            <span>Is my personal information secure when I shop with AKB?</span>
            <p>Yes, AKB doesn't share your personal information with third parties. We use your name, address, phone
              number, and email for our promotions and advertisements. To learn more, please review our privacy policy.
            </p>
          </li>
          <li>
            <span>How can I get today's special offers?</span>
            <p>For our latest offers: WhatsApp 'hello' to 01142208243, log in to our website with your phone number for
              text alerts, download the AKB app for pop-up notifications, visit our store for assistance, follow us on
              social media for updates, or check print media weekly for latest discounts.</p>
          </li>
          <li>
            <span>What else is available at the AKB grocery store?</span>
            <p>AKB offers more than just groceries, including beverages, health supplements, bakery goods, handmade
              food, snacks, decorations, cosmetics, electronics, grooming tools, puja samagri, crockery, clothing, and
              more. That's why our tagline is #YahaSabMilega.</p>
          </li>
          <li>
            <span>Is AKB only an online store or does it have physical stores also?</span>
            <p>AKB currently has 4 active stores in Delhi, with 3 in Dwarka and 1 in Mahavir Enclave. We're also
              planning to open more stores soon to serve our customers better.</p>
          </li>
          <li>
            <span>Can I order alcohol-based and tobacco products from AKB?</span>
            <p>Users cannot purchase age-restricted items online because it's challenging for AKB to verify user ages.
              We strongly advise against using the AKB site/app if you're under 18 or operating it without parental
              supervision.</p>
          </li>
        </ul>

      </div>
    </div>
  </section>

</main>