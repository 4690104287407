<!-- <ng-template #skeleton> -->
    <div class="card mb-3">
        <img class="img-fluid product-img" [routerLink]="[]">
        <div class="card-body">
            <p class="brand-name"></p>
            <p class="product-name"></p>
            <div class="row mt-2">
                <div class="col-6">
                    <button mdbBtn type="button" class=" bag-btn-outline"></button>
                </div>
                <div class="col-6 text-right">
                    <button mdbBtn type="button" class=" bag-btn-outline"></button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="button" class="btn bag-btn w-100" mdbWavesEffect></button>
                </div>
            </div>
            <p class="member"></p>
            <p class="slot"></p>
            <!-- <app-product-delivery></app-product-delivery> -->
        </div>
    </div>
<!-- </ng-template> -->