<main class="main">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 side-menu">
                <app-profile-sidebar></app-profile-sidebar>
            </div>
            <div class="col-lg-8">
                <div class="card mb-0">
                    <div class="card-header p-0 bg-white border-bottom-0">
                        <div class="row align-items-center px-4 py-0">
                            <div class="col-sm-12">
                                <h6 class="my-2 custom-primary-color" *ngIf="status?.success">{{status.message}}</h6>
                                <h6 class="my-2 text-danger" *ngIf="!status?.success">{{status.message}}</h6>
                                <!-- <p class="mb-1">Friday 3 July 2020 </p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-2 pointer" *ngIf="!address.societyId" (click)="addNewAddressModal()">
                    <div class="card-body">
                        <div class="add-new-address">
                            <i class="fa fa-plus mr-2" aria-hidden="true"></i>
                            <h2 class="add-address-heading">Add New Address</h2>
                        </div>
                    </div>
                </div>
                <div class="card address mb-0" *ngIf="address.societyId">
                    <div class="card-header p-0 subscription-header">
                        <div class="row align-items-center px-4 py-1">
                            <div class="col-sm-6">
                                <h5 class="mb-0">Address</h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body py-1">
                        <!-- <div class="d-flex justify-content-between">
                            <p>{{ address?.name | titlecase }}</p>
                            <div class="d-flex align-items-center">
                                <i class="fa fa-pencil-square-o" aria-hidden="true" mb-text-color="second"
                                    tooltip="Request To Edit Address"></i>
                            </div>
                        </div> -->
                        <!-- <div class="d-flex ">
                            
                        </div> -->
                        <div>
                            <p>{{ address?.name | titlecase }} {{ address?.mobileNo }}</p>
                            <P>{{ address?.flatNo | titlecase }}, {{ address?.blockNo | titlecase }} {{ address?.society | titlecase }}, {{ address?.city | titlecase }}, {{ address?.state | titlecase }}</P>
                        </div>
                        <!-- <p>{{ address?.flatNo | titlecase }}, {{ address?.blockNo | titlecase }}</p> -->
                        <!-- <p>{{ address?.society | titlecase }}, {{ address?.city | titlecase }}, {{ address?.state | titlecase }}</p> -->
                    </div>
                </div>
                <!-- subscription list -->
                <div *ngIf="subscriptions.length>0">
                    <div class="card">
                        <div class="card-header p-0 subscription-header">
                            <div class="row align-items-center px-4 py-1">
                                <div class="col-sm-6">
                                    <h5 class="mb-0">Subscription</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-0" *ngFor="let subscription of subscriptions">
                        <div class="card-body py-2">
                            <div class="row align-items-center">
                                <div class="col-md-3">
                                    <div class="discount-badge" *ngIf="subscription?.product?.sellPrice!=subscription?.product?.price">
                                        <p>{{(((subscription?.product?.price-subscription?.product?.sellPrice)/(subscription?.product?.price)*100).toFixed(1)) | roundoff }}% OFF</p>
                                    </div>
                                    <img class="img-fluid product-img"
                                    src="{{ subscription?.product?.name | image}}/{{ subscription?.product?.images[0] | imageSize:200 }}" onerror="this.src='../../../assets/image/no-product-img.png';" >
                                </div>
                                <div class="col-md-9">
                                    <div class="name-header">
                                        <h6>{{subscription?.product?.name | titlecase}}</h6>
                                    </div>
                                    <!-- <p class="brand-name">{{subscription?.product?.subBrand?.name | titlecase}}</p> -->
                                    <p class="brand-name">brand name</p>
                                    <p class="text-left text-nowrap mb-0">
                                        <span class="mr-2 blue-text"><i class="ml-0 fas fa-inr-sign"
                                                aria-hidden="true"></i> ₹{{subscription?.product?.sellPrice}}</span>
                                        <span class=" grey-text" *ngIf="subscription?.product?.sellPrice!=subscription?.product?.price"><del><i class="ml-1 fas fa-inr-sign"
                                                    aria-hidden="true"></i>₹{{subscription?.product?.price}}</del></span>
                                    </p>
                                    <!-- <div class="row mt-2 align-items-center">
                                        <div class="col-md-3">
                                            <p class="text-left text-nowrap mb-0">
                                                <span class="mr-2 blue-text"><i class="ml-1 fas fa-inr-sign"
                                                        aria-hidden="true"></i> ₹{{subscription?.product?.sellPrice}}</span>
                                                <span class=" grey-text" *ngIf="subscription?.product?.sellPrice!=subscription?.product?.price"><del><i class="ml-1 fas fa-inr-sign"
                                                            aria-hidden="true"></i>₹{{subscription?.product?.price}}</del></span>
                                            </p>
                                        </div>
                                        <div class="col-9">
                                           
                                        </div>
                                    </div> -->
                                    <div class="row align-items-center">
                                        <div class="col-md-4">
                                            <div class=" text-center add-remove">
                                                <button type="button" mdbWavesEffect
                                                    class="z-depth-1 waves-effect btn-outline-light-success" (click)=decrementQuantity(subscription)>
                                                    <i class="fa fa-minus " aria-hidden="true"></i>
                                                </button>
                                                <button type="button" class=" waves-effect qty-btn mx-2"><span
                                                        class="">{{subscription?.quantity}}</span></button>
                                                <button type="button" mdbWavesEffect
                                                    class="z-depth-1  waves-effect btn-outline-light-success"
                                                    tooltip="Limited Quantity Avalilable. You can't add more" [isDisabled]="subscription.quantity<subscription.product.perUserOrderQuantity"
                                                    placement="left" (click)=incrementQuantity(subscription) >
                                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <button mdbBtn type="button"
                                            class=" bag-btn-outline mb-0">{{subscription?.product?.recommendedAttribute}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Morning buy list -->
                <div *ngIf="morningBuyProducts.length>0">
                    <div class="card" >
                        <div class="card-header p-0 subscription-header">
                            <div class="row align-items-center px-4 py-1">
                                <div class="col-sm-6">
                                    <h5 class="mb-0">Morning Buy</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-0" *ngFor="let morningBuyProduct of morningBuyProducts">
                        <div class="card-body py-2">
                            <div class="row align-items-center">
                                <div class="col-md-3">
                                    <div class="discount-badge" *ngIf="morningBuyProduct?.product?.sellPrice!=morningBuyProduct?.product?.price">
                                        <p>{{(((morningBuyProduct?.product?.price-morningBuyProduct?.product?.sellPrice)/(morningBuyProduct?.product?.price)*100).toFixed(1)) | roundoff }}% OFF</p>
                                    </div>
                                    <img class="img-fluid product-img"
                                        src="{{ morningBuyProduct?.product?.name | image}}/{{ morningBuyProduct?.product?.images[0] | imageSize:200 }}" onerror="this.src='../../../assets/image/no-product-img.png';" >
                                </div>
                                <div class="col-md-9">
                                    <div class="name-header">
                                        <h6>{{morningBuyProduct?.product?.name}}</h6>
                                    </div>
                                    <p class="brand-name">{{morningBuyProduct?.product?.subBrand?.name}}</p>
                                    <p class="text-left text-nowrap mb-0">
                                        <span class="mr-2 blue-text"><i class="ml-1 fas fa-inr-sign"
                                                aria-hidden="true"></i> ₹{{morningBuyProduct?.product?.sellPrice}}</span>
                                        <span class=" grey-text" *ngIf="morningBuyProduct?.product?.sellPrice!=morningBuyProduct?.product?.price"><del><i class="ml-1 fas fa-inr-sign"
                                                    aria-hidden="true"></i>₹{{morningBuyProduct?.product?.price}}</del></span>
                                    </p>
                                    <!-- <div class="row mt-2 align-items-center">
                                        <div class="col-md-3">
                                            <p class="text-left text-nowrap mb-0">
                                                <span class="mr-2 blue-text"><i class="ml-1 fas fa-inr-sign"
                                                        aria-hidden="true"></i> ₹{{morningBuyProduct?.product?.sellPrice}}</span>
                                                <span class=" grey-text" *ngIf="morningBuyProduct?.product?.sellPrice!=morningBuyProduct?.product?.price"><del><i class="ml-1 fas fa-inr-sign"
                                                            aria-hidden="true"></i>₹{{morningBuyProduct?.product?.price}}</del></span>
                                            </p>
                                        </div>
                                        <div class="col-9">
                                          
                                        </div>
                                    </div> -->
                                    <div class="row align-items-center">
                                        <div class="col-md-4">
                                            <div class=" text-center add-remove">
                                                <button type="button" mdbWavesEffect
                                                    class="z-depth-1 waves-effect btn-outline-light" (click)="updateMorningbuyQuantity(morningBuyProduct,morningBuyProduct.quantity-1,'dec')">
                                                    <i class="fa fa-minus " aria-hidden="true"></i>
                                                </button>
                                                <button type="button" class=" waves-effect qty-btn mx-2"><span
                                                        class="">{{morningBuyProduct?.quantity}}</span></button>
                                                <button type="button" mdbWavesEffect
                                                    class="z-depth-1  waves-effect btn-outline-light"
                                                    tooltip="Limited Quantity Avalilable. You can't add more" [isDisabled]="morningBuyProduct.quantity<morningBuyProduct.product.perUserOrderQuantity"
                                                    placement="left" (click)="updateMorningbuyQuantity(morningBuyProduct,morningBuyProduct.quantity+1,'inc')" >
                                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <button mdbBtn type="button"
                                            class=" bag-btn-outline mb-3">{{morningBuyProduct?.product?.recommendedAttribute}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Morning buy list -->
                <!-- total list -->
                <div class="card" *ngIf="status.itemCount>0">
                    <div class="card-header p-0 subscription-header">
                        <div class="row align-items-center px-4 py-2">
                            <div class="col-sm-6">
                                <p class="mb-0">Total Item: {{ status.itemCount }}</p>
                            </div>
                            <div class="col-sm-6">
                                <p class="mb-0 text-right">Total Amount: {{status.totalAmount}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</main>