<div class="container main">
  <section class="staticSection container">
    <span class="mainHeadding">Privacy Policy</span>
    <p class="p2">
      <span class="s1"><strong>INTRODUCTION</strong></span>
    </p>
    <!-- <p class="p1"><span class="s1"><strong>Your website may use the Privacy Policy given below:</strong></span></p> -->
    <p class="p3">
      <span class="s1">
        At Aap Ka Bazar®, we highly value your privacy. Our Privacy Policy explains how we handle your information when
        you use our website, mobile application, or visit our stores. Please read this policy carefully and agree to it.
        If you disagree, please refrain from using our site. We reserve the right to change this policy at any time,
        with changes taking effect immediately upon posting. By continuing to use the site or app, you are indicating
        your acceptance of these policies.
      </span>
    </p>
    <p class="p2">
      <span class="s1"><strong>COLLECTION OF YOUR INFORMATION</strong></span>
    </p>
    <p class="p2">
      <span class="s1"><strong>Personal Data</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        When you register or engage in activities on our site, mobile app, or in our stores like chatting and using
        message boards, you can provide information such as your name, address, email, phone number, age, gender,
        hometown, and interests. While sharing personal information is optional, not doing so may limit your access to
        certain sites and app features.
      </span>
    </p>


    <p class="p2">
      <span class="s1"><strong>Derivative Data</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        When you visit Aap Ka Bazar®, our servers gather information, including your IP address, browser type, operating
        system, access time, and the pages you looked at before and after.
        For mobile app users, this might also involve device details, phone numbers, country, preferences, responses to
        posts, interactions within the app, and any additional information you choose to share.
      </span>
    </p>


    <p class="p2">
      <span class="s1"><strong>Financial Data</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        <b>For Users:</b> If you've willingly given us personal or financial info, like phone numbers or email
        addresses,
        through any means, you're not just a visitor; you're a user. In that case, this Privacy Policy applies to you,
        and all the statements in it are relevant.
      </span>
    </p>
    <p class="p3">
      <span class="s1">
        <b>For Visitors:</b> No personal or financial info is automatically collected from visitors of stores or just
        browsing
        our Platforms. However, our Privacy Policy applies to physically store visitors too, and you should read and
        accept before/after visiting.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>Social Accounts Permissions and Data</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        The Site and our mobile app can automatically get your basic Social Media (Facebook, Instagram, YouTube, Etc.)
        Account Details like your name, email, gender, birthday, city, and profile picture link(optional), along with
        any other public info. AKB might also ask for other access permissions, like sending/accepting follow requests,
        accessing your friends/followers, and checking your id/accounts. You can choose to allow or deny each of these
        permissions on your social media account Permissions Reference page.
      </span>
    </p>



    <p class="p2">
      <span class="s1"><strong>Third-Party Data</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        AKB doesn't share your personal information with third parties. We keep your data private. However, we may use
        it for our promotions or advertisements within AKB, especially if you connect your account to a third party and
        permit us to access their information.
      </span>
    </p>


    <p class="p2">
      <span class="s1"><strong>Data from Contests, Giveaways, and Surveys</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        Your info from contest/giveaway/etc. entries, surveys, and common details like name, age, and gender may be kept
        safe from third parties but AKB uses this information solely for advertising and promotions of their
        brands/items.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>Geo-Location and Mobile Device Information Data</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        Aap Ka Bazar® might ask for location info from your mobile device, either continuously or when using our app for
        location-based services. You can change this in your device settings. We might also request access to various
        device features like push notifications, Bluetooth, camera, contacts, and more. Device data like ID, model, and
        operating system are collected, along with IP address. You may change these by changing the privacy settings of
        your device /AKB accounts.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>USE OF YOUR INFORMATION</strong></span>
    </p>

    <p class="p2">
      <span class="s1"><strong>Accurate information helps us provide you with a better experience. We use your data
          to:</strong></span>
    </p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Run contests and promotions.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Assist law enforcement when required.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Create stats for our use and with others.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Manage your account.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Send you targeted ads, coupons, and info about our
      promotions.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Email you about your account or orders.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Enable user-to-user communication.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Handle purchases, orders, payments, and transactions.
    </p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Personalize your future visits.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Improve site efficiency.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Monitor usage and trends for a better experience.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Keep you informed about updates.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Offer new products, services, or recommendations.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Perform necessary business activities.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Prevent fraud, theft, and criminal activity.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Process payments and refunds.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Ask for feedback and discuss your site use.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Resolve disputes and address issues.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Respond to customer service requests.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Send you newsletters.</p>
    <p class="mb-1"><i class="fa fa-check" aria-hidden="true"></i>Seek support for the site and app.</p>

    <br/>
    <p class="p2">
      <span class="s1"><strong>DISCLOSURE OF YOUR INFORMATION</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        Aap Ka Bazar® may share information we have collected about you in certain situations. Your information may be
        disclosed as follows:
      </span>
    </p>


    <p class="p2">
      <span class="s1"><strong>To Comply with the Law or Protect Rights:</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        Aap Ka Bazar® may share your info if required by law or to safeguard the rights, property, or safety of others.
        This includes cooperating with entities for fraud prevention and credit risk reduction.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>Third-Party Service Providers:</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        AKB may share your information with third parties who help with services like payments and customer support. If
        you agree, we can share your information with third parties for marketing, as the law allows. We also use
        third-party advertisers who use cookies to show you ads based on your interests. In our app, you might see offer
        walls from third parties that give rewards for completing ads, and they may get your user ID. We might share
        your info with advertisers and investors for general business analysis and marketing, following the law.
      </span>
    </p>


    <p class="p2">
      <span class="s1"><strong>Interactions with Other Users:</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        Your interactions on the site and app, such as chats, posts, and likes, might be visible to other users. Any
        content you post can be seen by all users and may be shared outside the site and app.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>Affiliates:</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        Your info could be shared with our affiliates, subject to this Privacy Policy. Aap Ka Bazar® may share your info
        with business partners for product offerings.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>Social Media Contacts:</strong></span>
    </p>

    <p class="p3">
      <span class="s1">
        If you connect with AKB through social networks/media, your contacts/followers/etc. can see your activity like
        comments, likes/dislikes, reviews, etc.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>SALE AND BANKRUPT</strong></span>
    </p>


    <p class="p3">
      <span class="s1">
        If AKB changes how we're organized, sells our assets, merges, or gets acquire, we might move your info to the
        new owner. If we close or go bankrupt, your info could be sold to someone else. You understand this might
        happen, and the new owner might not follow this Privacy Policy.
        We can't control what third parties do with the info you share, and we can't stop their messages. If you don't
        want their emails or messages, you need to contact them directly.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>COOKIES AND WEB BEACONS</strong></span>
    </p>


    <p class="p3">
      <span class="s1">
        AKB uses tracking technology like cookies, web beacons, and tracking pixels on the Site and our mobile app to
        customize and enhance your experience. When you use the Site or app, we don't collect your info with tracking
        tech. Most browsers accept cookies by default, but you can remove or reject them. Note that this may affect the
        Site or app's availability and features. You can't decline web beacons, but disabling all cookies or changing
        your browser settings to approve or deny each cookie individually can render them ineffective. Our Cookie
        Policy, part of this Privacy Policy, explains more about how we use cookies. By using the Site, you agree to
        follow our Cookie Policy.
      </span>
    </p>


    <p class="p2">
      <span class="s1"><strong>POLICY FOR CHILDREN</strong></span>
    </p>


    <p class="p3">
      <span class="s1">
        We do not knowingly solicit information from or market to children under the age of 13. If you become aware of
        any data we have collected from children under age 13, please contact us using the contact information provided
        below.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>Internet-Based Advertising</strong></span>
    </p>
    <p class="p3">
      <span class="s1">
        At Aap Ka Bazar®, we use third-party software for ads, email marketing, and interactive marketing on our website
        and app. This software may use cookies and tracking technology to enhance your online experience. If you want to
        opt out of interest-based ads, please visit the NAI Opt-Out page.
      </span>
    </p>


    <p class="p2">
      <span class="s1"><strong>WEBSITE ANALYTICS</strong></span>
    </p>
    <p class="p3">
      <span class="s1">
        At AKB, we work with third-party vendors such as Adobe Analytics and Google Analytics who use technologies like
        cookies on our website and app. This assists us in understanding user activity and popular content. When you use
        the website or app, you permit these vendors to gather and use your information. Please review their privacy
        policies and reach out to them for any questions. It's important to note that if you get a new computer or
        change browser settings, you opt-out preferences may be reset.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>THIRD-PARTY WEBSITES</strong></span>
    </p>
    <p class="p3">
      <span class="s1">
        Our AKB website and app may include links to third-party websites that are not part of our organization. Please
        be aware that the information you provide on these external sites is not protected by our Privacy Policy, and we
        can't ensure its security. It's advisable to review their privacy policies before sharing any information.
        <br />
        We make efforts to safeguard your information, but it's important to understand that no security is flawless.
        Online data can be susceptible to interception. While we do our utmost, we cannot promise absolute security.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>CONTROLS FOR DO-NOT-TRACK FEATURES</strong></span>
    </p>
    <p class="p3">
      <span class="s1">
        Several web browsers and some mobile systems offer a 'Do-Not-Track' feature to indicate you don't wish to have
        your online activity tracked. However, there isn't a universal method for this at present. Therefore, we
        currently do not act on these signals. If a standard is created in the future, Aap Ka Bazar® will inform you
        through an updated Privacy Policy. But if you activate the 'Do-Not-Track' signal in your browser, we will
        respect it.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>ACCOUNT DEACTIVATION</strong></span>
    </p>
    <p class="p3">
      <span class="s1">
        You have a few options to manage your account. You can log in to update your information or contact us to close
        your account. If you choose to close it, we will delete your account and data from our primary databases.
        However, AKB may retain some data to safeguard your account, prevent fraud, address concerns, aid in
        investigations, enforce our Terms of Use, or meet legal obligations as needed.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>EMAILS AND COMMUNICATIONS</strong></span>
    </p>
    <p class="p3">
      <span class="s1">
        To stop getting emails, messages, or notifications from AKB, you can adjust your preferences during account
        creation or by logging into your account and changing your settings. If you want to stop messages from third
        parties, please contact them directly.
      </span>
    </p>

    <p class="p2">
      <span class="s1"><strong>CALIFORNIA PRIVACY RIGHTS</strong></span>
    </p>
    <p class="p3">
      <span class="s1">
        If you're a California resident, you can ask us once a year, at no cost, about the types of personal information
        we shared with third parties for direct marketing, and get the names and addresses of these third parties in the
        past year. To make this request, contact us in writing using the address and mail on the App/Site.
        If you're under 18, live in California, and have an account with our Site or app, you can ask us to remove data
        you posted publicly. To do this, contact us using the provided details, and include your account's email address
        and a statement confirming your California residency. Aap Ka Bazar® will ensure it's not displayed publicly, but
        some data might not be completely removed from our systems to safeguard your account, prevent fraud, address
        concerns, aid in investigations, enforce our Terms of Use, or meet legal obligations as needed.

      </span>
    </p>


  </section>