import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-skeleton',
  templateUrl: './banner-skeleton.component.html',
  styleUrls: ['./banner-skeleton.component.scss']
})
export class BannerSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
