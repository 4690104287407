<footer class="page-footer font-small unique-color-morningbag">
  <div class="footer-top">
    <div class="container text-md-left pt-4">

      <div class="footerBrandsBox">
        <div class="box1">
          <h4>Online Grocery Store</h4>
          <p>
            aapkabazar.co offer our customers a wide range of products to choose from and we deliver them right to your
            door anywhere in Delhi Ncr City. Rice and Dal, Dairy, Spices and Seasoning to Packaged products, Beverages,
            Personal care products, Edibles and many more Grocery items. – Aap Ka Bazar has it all and at the best
            prices! <br>
            Together, let’s change the style of shopping. Time is limited, let Aap Ka Bazar be your one-stop shop for
            all your grocery needs and enjoy the time with family and loved ones. We’ll take care of the shopping for
            you. Remember to keep an eye out for our upcoming offers. We are sure you would love them.
            Aap Ka Bazar is the best Online Grocery Website in Dwarka, Delhi & the right Online Supermarket in Delhi
            ncr. You can shop online for 28,000+ of products and around 1000+ brands.
          </p>
        </div>
        <div class="box1">
          <h4>Brands Available on Aap Ka Bazar</h4>
          <p class="fBrand">
            <span>Gauri Rice</span> <span>Aashirvaad</span><span>Act II</span><span>Amul</span> <span>Veet</span>
            <span>Axe</span><span>Bambino</span><span>Bingo</span><span>Bisleri</span><span>Boost</span><span>Bournvita</span>
            <span>Britannia</span><span>Brooke Bond</span><span>Bru</span> <span>Cadbury</span> <span>Cinthol</span>
            <span>Closeup</span>
            <span>Coca-Cola</span> <span>Colgate</span> <span>Dabur</span><span>Del Monte</span> <span>Dettol</span>
            <span>Dove</span>
            <span>Everest</span> <span>Fiama</span> <span>Garnier</span> <span>Gatorade</span> <span>Gillette</span>
            <span>Glucon-D</span>
            <span>Head & Shoulders</span> <span>Heinz</span> <span>Himalaya</span> <span>Haldiram's</span> <span>India
              Gate</span>
            <span>Horlicks</span> <span>Kellogg's</span> <span>Kinley</span> <span>Kissan</span> <span>Knorr</span>
            <span>L'Oreal</span>
            <span>Lijjat</span> <span>Limca</span> <span>Lipton</span> <span>Maggi</span> <span>Madhur</span>
            <span>MDH</span>
            <span>Mirinda</span> <span>Mother Dairy</span> <span>Mountain Dew</span> <span>MTR</span>
            <span>Nescafe</span>
            <span>Nestle</span> <span>Nivea</span> <span>Ferero</span> <span>Oral-B</span> <span>Palmolive</span>
            <span>Ayur</span>
            <span>Pantene</span> <span>24 Mantra</span> <span>Tropicana</span> <span>Parachute</span> <span>Parle</span>
            <span>Ahaar</span>
            <span>Patanjali</span> <span>Pears</span> <span>Pepsi</span> <span>Wagh Bakri</span> <span>Whisper</span>
            <span>Pepsodent</span> <span>Pillsbury</span> <span>Rajdhani</span> <span>RedBull</span> <span>Saffola</span>
            <span>Smith & Jones</span>
            <span>Sprite</span> <span>Stayfree</span> <span>Sundrop</span> <span>Sunfeast</span> <span>Sunsilk</span>
            <span>Taj Mahal</span> <span>Tang</span> <span>Tata Sampann</span> <span>Tata tea</span> <span>Tetley</span>
            <span>Baidynath</span> <span>Thums Up</span> <span>Tropicana</span> <span>Twinings</span> <span>Uncle
              Chipps</span>
            <span>Unibic</span> <span>Vaseline</span> <span>Gowardhan</span> <span>Lay's</span> <span>Olay</span>

          </p>
        </div>
      </div>


      <div class="row">
        <div class="col-md-3 mx-auto">
          <div class="footer-social-content">
            <img src="assets/image/logo-main.png" class="footer-img">
            <p>With over 28,000+ Products and 1000+ Brands aapkabazar.co is India's fastest growing online store. </p>
            <br>
            <div class="social-links">
              <ul class="list-style">
                <li><a href="https://www.facebook.com/aapkabazar.co" target="_blank"><i class="fa fa-facebook"
                      aria-hidden="true"></i></a></li>
                <li><a href="https://in.linkedin.com/company/aap-ka-bazar" target="_blank"><i class="fa fa-linkedin"
                      aria-hidden="true"></i></a></li>
                <li><a href="https://twitter.com/aapkabazar_co" target="_blank"><i class="fa fa-twitter"
                      aria-hidden="true"></i></a></li>
                <li><a href="https://www.instagram.com/aapkabazardelhi" target="_blank"><i class="fa fa-instagram"
                      aria-hidden="true"></i></a></li>
                <li><a href="https://www.pinterest.com/aapkabazar_co" target="_blank"><i class="fa fa-pinterest"
                      aria-hidden="true"></i></a></li>
                <li><a href="https://www.youtube.com/@aapkabazar6838" target="_blank"><i class="fa fa-youtube"
                      aria-hidden="true"></i></a></li>
              </ul>
            </div>
            <div class="payment-card">
              <a href="https://t.me/aapkabazardelhi" target="_blank" title="Join Telegram">
                <img src="https://images.aapkabazar.co/telegram.jpeg" style="width: 160px;">
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-3 mx-auto infomation">
          <div class="accordion">
            <input type="checkbox" id="section1" class="accordion__input">
            <label for="section1" class="accordion__label">
              <h5 class="">Information<span class="responsive-2"><i class="fa fa-angle-down"
                    aria-hidden="true"></i></span></h5>
            </label>
            <div class="accordion__content">
              <p>
                <a [routerLink]="['/about-us']">About Us</a>
              </p>
              <p>
                <a [routerLink]="['/contact-us']">Contact Us</a>
              </p>
              <p>
                <a [routerLink]="['/offer']">AKB Offers</a>
              </p>              
              <p>
                <a href="https://blog.aapkabazar.co" target="new">AKB Blog</a>
              </p>              
              <p>
                <a [routerLink]="['/faq']">FAQs</a>
              </p>
              <!-- <p>
                <a href="https://seller.aapkabazar.co/register" target="new">Seller Register</a>
              </p> -->
              <!-- <p>
              <a [routerLink]="['/akboffers']">Weekly Offers</a>
            </p> -->
            </div>
          </div>
        </div>

        <div class="col-md-3 mx-auto">
          <div class="accordion">
            <input type="checkbox" id="section2" class="accordion__input">
            <label for="section2" class="accordion__label">
              <h5 class="">Our Support<span class="responsive-2"><i class="fa fa-angle-down"
                    aria-hidden="true"></i></span></h5>
            </label>

            <div class="accordion__content">
              <p>
                <a [routerLink]="['/refund-policy']">Refund Policy</a>
              </p>
              <p>
                <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
              </p>              
              <p>
                <a [routerLink]="['/disclaimer']">Disclaimer</a>
              </p>
              <p>
                <a [routerLink]="['/billing-desclaimer']">Billing Disclaimer</a>
              </p>
              <p>
                <a [routerLink]="['/terms-conditions']">Terms & Conditions</a>
              </p>            
            </div>
          </div>
        </div>


        <div class="col-md-3 mx-auto">
          <div class="accordion">
            <input type="checkbox" id="section4" class="accordion__input">
            <label for="section4" class="accordion__label">
              <h5 class="">Contacts<span class="responsive-2"><i class="fa fa-angle-down" aria-hidden="true"></i></span>
              </h5>
            </label>


            <div class="accordion__content">

              <ul class="contact-infor">
                <li><a href="tel:+919150030303"><i class="fa fa-phone"
                      aria-hidden="true"></i><span>+91-9150030303</span></a><span> (11AM - 8PM)</span></li>
                <li><a href="mailto:support@aapkabazar.co" class="__cf_email__"><i class="fa fa-envelope-o"
                      aria-hidden="true"></i><strong> </strong><span>support@aapkabazar.co</span></a></li>
                <!-- <li><img src="assets/imgs/theme/icons/icon-clock.svg" alt="" /><strong>Hours : </strong><span>10:00 - 18:00, Mon - Sat</span></li> -->
              </ul>
              <div class="app-store">
                <a href="https://play.google.com/store/apps/details?id=co.aapkabazar.app" target="_blank">
                  <img alt='Get it on Google Play' class="img-fluid mx-auto d-block" src='assets/image/google.png' />
                </a>
                <a href="https://apps.apple.com/be/app/aap-ka-bazar-online-grocery/id1640031723">
                  <img class="d-block mx-auto img-fluid" src="assets/image/apple.png" alt="">
                </a>
              </div>
              <div class="payment-card">
                <img src="assets/image/payment-method.png">
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="footer-copyright  mt-2">
    <div class="container-fluid container text-center text-md-left">
      <div class="row d-flex align-items-center">
        <div class="col-md-12 mx-auto ">
          <div class=" text-center">© 2021-2023 copyright all rights reserved. | Aap Ka Bazar
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>


<!-- Footer -->
<div class="footer-fix">
  <a [routerLink]="['/']"> <img src="assets/image/footer-icon/home.svg">
    <p>Home</p>
  </a>
  <a [routerLink]="['/offer']"> <img src="assets/image/footer-icon/offer.svg">
    <p>Offers</p>
  </a>
  <a [routerLink]="['/all-category']"> <img src="assets/image/footer-icon/category.svg">
    <p>Categories</p>
  </a>
  <a [routerLink]="['/bag']"> <img src="assets/image/footer-icon/cart.svg">
    <p>Cart<span class="cart-count" *ngIf="bagQuantity.value!=0">{{ bagQuantity.value }}</span></p>
  </a>
</div>


<div mdbModal #contactModal="mdbModal" style="overflow-y:scroll" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body py-0 contact-madal">
        <div class="row">
          <div class="col-sm-12 p-3">
            <h4 class="text-left font-weight-bold">Contact us</h4>

            <form>
              <div class="form-group">
                <p class="ml-1 mb-0 black-text">Enter Email</p>
                <input type="email" class="form-control" id="email">
              </div>
              <div class="form-group">
                <p class="ml-1 mb-0 mt-3 black-text">Phone No.</p>
                <input type="number" class="form-control" id="mobile">

              </div>
              <div class="form-group">
                <p class="ml-1 mt-4 mb-0 black-text">Message</p>
                <div class="field span3">
                  <textarea class="form-control" rows="4" cols="200"></textarea>
                </div>
              </div>
              <div class="mt-4 text-center">
                <button type="button" class="btn bag-btn" (click)="cancel()">Cancel</button>
                <button type="submit" class="btn bag-btn mr-0">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
  <button (click)="scrollToTop()">
    <i class="fa fa-arrow-up"></i>
  </button>
</div>