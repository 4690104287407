export * from './about-us/about-us.component';
export * from './faq/faq.component';
export * from './contact-us/contact-us.component';
export * from './advertise-products/advertise-products.component';
export * from './become-an-affiliate/become-an-affiliate.component';
export * from './condition-of-sale/condition-of-sale.component';
export * from './disclaimer/disclaimer.component';
export * from './privacy-policy/privacy-policy.component';
export * from './refund-policy/refund-policy.component';
export * from './billing-desclaimer/billing-desclaimer.component';
export * from './sellwithus/sellwithus.component';
export * from './serving-area/serving-area.component';
export * from './terms-conditions/terms-conditions.component';