<main class="main">
  <section class="staticSection container">
    <span class="mainHeadding">Disclaimer</span>

    <h6 class="head-disclaimer">INTRODUCTION</h6>
    <p>Aap Ka Bazar® is a leading online/offline grocery company that enables you to order groceries, cosmetics, and
      other daily essential products, etc. directly using mobile application, web browser or through offline store.
      <br />
      Aap Ka Bazar® application or website strives to provide accurate information, but all the content and information
      presented here are for general information purpose only and are gathered from various sources, including the
      internet, books, and various companies and other websites. <br />
      The consumer or visitor himself uses his/ her intelligence before consuming such information and Aap ka Bazar® is
      not responsible for express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or
      completeness of any information on the Site or our mobile application. <br />
      The actual product packaging and materials may vary from what is shown on the Aap Ka Bazar® application or
      website. Please read the back/label/warning/expiry date, offered product packing etc., carefully before buying and
      consuming. <br />
      AKB shall not be liable for any loss or damage incurred due to your use of the site or reliance on any information
      provided on the site. Your use of the site and reliance on any information is at your own risk.
    </p>

    <br />

    <h6 class="head-disclaimer">EXTERNAL LINKS DISCLAIMER</h6>
    <p>
      The Site may contain (or you may be sent through the Site links to other websites or content belonging to or
      originating from third parties or links to websites and features in banners or other advertising. Such external
      links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or
      completeness by us. <br />
      We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information
      offered by third-party websites linked through the site or any website or feature linked in any banner or other
      advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and
      third-party providers of products or services.
    </p>

    <br />

    <h6 class="head-disclaimer">PROFESSIONAL DISCLAIMER</h6>
    <p>
      The legal/medical/fitness/health/other information is provided for general informational and educational purposes
      only and is not a substitute for professional advice. <br />
      Accordingly, before taking any actions based on such information, we encourage you to consult with the appropriate
      professionals. The use or reliance of any information contained on this site or our mobile application is solely
      at your own risk. <br />
      There may be a difference in, the photo or product description, and the original product received by the customer.
      In that case, your suggestions are acceptable but no legal obligation will be applied.
    </p>

    <br />

    <h6 class="head-disclaimer">TESTIMONIALS DISCLAIMER</h6>
    <p>
      The Site may contain testimonials by users of our products and/or services. These testimonials reflect the
      real-life experiences and opinions of such users. However, the experiences are personal to those particular users,
      and may not necessarily be representative of all users of our products and/or services. We do not claim, and you
      should not assume, that all users will have the same experiences. Your results may vary. <br />
      The testimonials on the Site are submitted in various forms such as text, audio, and/or video, and are reviewed by
      us before being posted. They appear on the Site verbatim as given by the users, except for the correction of
      grammar or typing errors. Some testimonials may have been shortened for the sake of brevity where the full
      testimonial contained extraneous information not relevant to the general public. <br />
      The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our
      views and opinions. We are not affiliated with users who provide testimonials, and users are not paid or otherwise
      compensated for their testimonials. <br />
      The testimonials on the Site are not intended, nor should they be construed, as claims that our products and/or
      services can be used to diagnose, treat, mitigate, cure, prevent, or otherwise be used for any disease or medical
      condition. No testimonials have been clinically proven or evaluated.
    </p>

    <br />

    <h6 class="head-disclaimer">Eligibility</h6>
    <p>
      Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including minors,
      undischarged insolvents, etc. are not eligible to use/access the Aap Ka Bazar® application or website. <br />
      However, if you are a minor, i.e. under the age of 18 years, you may use/access the Aap Ka Bazar® application or
      website under the supervision of an adult parent or legal guardian who agrees to be bound by these Terms of Use.
      You are however prohibited (even under provision) from purchasing any product(s) which is for adult consumption,
      the sale of which to minors is prohibited.
    </p>

    <br />

    <h6 class="head-disclaimer">Account & registration obligations</h6>
    <p>
      To place orders on the Aap Ka Bazar® application or website, all users must register and log in. It is essential
      to keep your account and registration details up-to-date for purchase-related communications. By agreeing to the
      Terms of Use, you consent to receive promotional or transactional communication and newsletters from the Company
      and its partners. You can opt out of such communication/newsletters by contacting customer services or placing a
      request. <br />
      During the registration process on the Aap Ka Bazar® application or website, the Company may collect personally
      identifiable information about you, including but not limited to your name, email address, age, address, mobile
      phone number, and other contact details. This may also include demographic profile details (like age, gender,
      occupation, education, address, etc.) and information about the pages you visit/access, the links you click, and
      the frequency of accessing specific pages/features. All information collected about you is subject to the
      Company's privacy policy. By registering your mobile number, you consent to be contacted via phone calls, SMS
      notifications, mobile applications, or any other electronic communication mode for order, shipment, or
      delivery-related updates.
    </p>

    <br />

    <h6 class="head-disclaimer">You agree and confirm</h6>
    <p>
      You may choose to provide payment-related financial information (credit card, debit card, bank account details,
      name, billing address, shipping address, phone number, etc.) on the website. Rest assured, we are committed to
      keeping all such sensitive data safe. We ensure that such information is only processed through secure websites of
      approved payment gateways, which use digital encryption and the highest level of technology available for data
      protection. <br />
      To optimize your order experience and provide better service, we may require your device permission to gather
      information about your device, such as OS (operating system) name, mobile network, hardware model, unique device
      identifier, etc.
      If you allow us to access your email id then, it enables us to send various deals and offers directly to your
      email. <br />
      Calls made by you to our telephone or helpline numbers, and calls made by us to you (directly or indirectly
      through a third party) may be recorded for internal training and quality purposes. By using these services, you
      explicitly agree to allow Aap Ka Bazar® and its third-party partners to record and share the information provided
      during the call with authorized personnel and delivery partners.
    </p>

    <br />

    <h6 class="head-disclaimer">GST Effect on Delivery</h6>
    <p>
      Aap Ka Bazar® welcomes the 'One Nation, One Tax' initiative from July 1st, 2017, and complies with GST guidelines.
      You will continue to receive all GST benefits and lower-priced items from Aap Ka Bazar® as always. All the
      products and charges include GST you don’t have to anything extra above the mention price.
    </p>

    <br />

    <h6 class="head-disclaimer">Order Cancellation</h6>
    <p>
      Aap Ka Bazar® may cancel orders in the following cases: (a) suspected fraudulent transactions by a customer, (b)
      transactions not conforming to or violating the Terms of Use/User Agreement, (c) product unavailability, or (d)
      reasons beyond the Company's control, including delivery-related logistical difficulties.
      Thank you for visiting apkabazar.co
    </p>

  </section>
</main>