<main class="main">
  <div class="container faq">
    <h1 class="head-other-page">Delivery</h1>
    <div class="border-line"></div>

    <h5>What payment methods do you accept ?</h5>
    <p>We accept payments via Visa, MasterCard or Net banking. We also accept payment via “cash on Delivery” at the time of the delivery of the product. However this mode of payment has a convenience charge associated with it. For certain categories with high value items, only Debit Card/Credit Card and Net-Banking options are available.

    </p>
    <h5>Is it safe to shop online using my Debit card or Credit Card ?</h5>
    <p>All credit card, Debit Card and net banking transactions are processed over a secure encrypted connection. So no worries here!</p>

    <h5>What is the process for Cash On Delivery (COD) purchase ?</h5>
    <p>When you make a purchase using the COD option, your product will be booked. You will receive a call from us to confirm your Order before it gets dispatched. If you are unreachable or unable to attend the call, please contact us if you are still interested in receiving the product.</p>

    <h5>Is there a charge for Cash on Delivery purchases ?</h5>
    <p>Its Free but Services to limited area..</p>


    <h5>How can I pay if COD option is not available on my address ?</h5>
    <p>If COD option is not available on your address you can always opt for NetBanking or Credit/Debit Card . In case you don’t have a NetBanking or Debit Card option please contact your bank for Debit Card/Credit Card issue.</p>

    <h5>Are there any hidden charges ?</h5>
    <p>There are absolutely no hidden charges. Any octroi or sales tax is actually borne by us or already included in MRP. You pay only the amount that you see in your order summary.</p>



  </div>
</main>
