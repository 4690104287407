import { Component, OnInit } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-offer',
  templateUrl: './akboffers.component.html',
  styleUrls: ['./akboffers.component.scss']
})
export class akboffersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
