
<main class="main">
    <div class="row">
        <div class="AddMonryPay">
            <div  class="card status-card z-depth-1">
                <div class="card-body">
                    <img alt="Success" class="pytDone" *ngIf="(paymentData.isWallet || paymentData.isOrder) && paymentData.success" src="/assets/image/orderSucceful-image.png">
                    <img alt="Failed" *ngIf="paymentData.success==false && paymentData.isWallet" src="/assets/image/cancel.gif">
                    <!-- <img alt="Success" *ngIf="paymentData.isOrder && paymentData.success && orderData.success" src="/assets/image/orderSucceful-image.png"> -->
                    <img alt="In Progress" *ngIf="paymentProgress" src="/assets/image/in-progress.gif">
                    <img alt="Failed" *ngIf="paymentData.isOrder && orderData.success == false" src="/assets/image/cancel.gif">
                    <div class="flex-box">
                        <h6 class="mt-4"> Payment Status</h6>
                        <label class="mt-4 font-weight-bold green-text" *ngIf="paymentData.success"> Success</label>
                        <label class="font-weight-bold orange-text" *ngIf="paymentProgress" > Pending</label>
                        <label class="mt-4 font-weight-bold red-text" *ngIf="paymentData.success==false"> Failed</label>
                    </div> 
                    <!-- <div class="flex-box" *ngIf="paymentData.isOrder">
                        <h6 class="mt-4"> Order Status</h6>
                        <label class="mt-4 font-weight-bold green-text" *ngIf="orderData.success"> Success</label>
                        <label class="font-weight-bold orange-text" *ngIf="paymentProgress" > Pending</label>
                        <label class="mt-4 font-weight-bold red-text"  *ngIf="orderData.success==false "> Failed</label>
                    </div> -->

                    <!-- Event snippet for Purchase (5) conversion page -->
                    

                    <div class="flex-box" *ngIf="paymentData.isOrder && orderData.success && orderData?.orderId">
                        <h6> Order Id </h6>
                        <h6> #{{ orderData?.orderId }} </h6>
                    </div>
                    <div class="flex-box" *ngIf="paymentData.isOrder && orderData.success && orderData?.date">
                        <h6> Order Date</h6>
                        <h6> {{ orderData?.date | date }}</h6>
                    </div>
                    <div class="flex-box" *ngIf="paymentData.isWallet && paymentData?.paymentMode">
                        <h6> Payment Mode </h6>
                        <h6> {{ paymentData?.paymentMode }} </h6>
                    </div>
                    <div class="flex-box" *ngIf="paymentData.isWallet && paymentData.success && paymentData?.transactionId">
                        <h6> Transaction Id </h6>
                        <h6> {{ paymentData?.transactionId }} </h6>
                    </div>                    
                    <div class="flex-box" *ngIf="paymentData.isOrder && orderData.success && orderData?.mobileNo">
                        <h6> Mobile No </h6>
                        <h6> {{ orderData?.mobileNo }} </h6>
                    </div>
                    <div class="flex-box" *ngIf="paymentData.isOrder && orderData.success && orderData?.amount">
                        <h6> Total Paid </h6>
                        <h6><i class=" fa fa-inr"></i> {{ orderData?.amount }} </h6>
                    </div>
                    <div class="flex-box" *ngIf="paymentData.isWallet && paymentData?.amount">
                        <h6> Amount to be Added </h6>
                        <h6><i class=" fa fa-inr"></i> {{ paymentData?.amount }} </h6>
                    </div>

                    <div class="flex-box">
                        <h6> Message  </h6>
                        <h6> {{ message }} </h6>
                    </div>
                    <button  type="button" class="btn-block continue waves-effect" mdbBtn mdbWavesEffect *ngIf="paymentData.isOrder && orderData.success && is_show_orderDetails" [routerLink]="['/order/detail/'+orderData?._id]">Order Details</button>
                    <button  type="button" class="btn-block continue waves-effect" mdbBtn mdbWavesEffect *ngIf="paymentData.isOrder && orderData.success==false" [routerLink]="['']">Continue Shopping</button>
                    <button  type="button" class="btn-block continue waves-effect" mdbBtn mdbWavesEffect *ngIf="paymentData.isWallet" [routerLink]="['']">Continue Shopping</button>
                </div>
            </div>
        </div>
    </div>
</main>