<main class="main">
  <section class="staticSection container">
    <div class="margin-top">
      <span class="mainHeadding">Accuracy of Billing and Account Information</span>
      <div class="wpb_column vc_column_container vc_col-sm-12">
        <div class="vc_column-inner  ">
          <div class="wpb_wrapper">
            <div class="wpb_text_column wpb_content_element ">
              <div class="wpb_wrapper">
                <p><strong>Introduction</strong></p>
                <p>
                  Welcome to Aap Ka Bazar®, where precision and accuracy are at the heart of our commitment to customer
                  satisfaction. AKB understands that accurate billing and account information are vital aspects of your
                  shopping experience, and we take this responsibility seriously. At AKB, we have implemented rigorous
                  systems and dedicated staff to ensure that every transaction is precise, transparent, and trustworthy.
                  In this introduction, we will explore how our store prioritizes the accuracy of billing and account
                  information to make your shopping experience not only convenient but also worry-free.
                </p><br />
                <p><strong>Billing and Account Information Accuracy</strong></p>
                <p>
                  Aap Ka Bazar® (AKB) maintains the right to decline orders placed on our website, via our app, or in
                  our physical stores. AKB may, at its discretion, limit or cancel the quantity of items purchased per
                  person, household, or order. These restrictions may apply to orders linked to the same customer
                  account, credit card, billing, or shipping address. If an order is altered or cancelled, we will try
                  to notify you through the provided email, billing address, or phone number. For information on AKB's
                  order cancellation policies, please refer to our Return/Refund/Exchange Policy. AKB reserves the right
                  to restrict or prohibit orders that, in our judgment, appear to be made by dealers, resellers, or
                  distributors.
                  <br /><br />
                  Users are required to provide accurate and up-to-date purchase and account information for both online
                  and offline purchases at our store. Please promptly update your account details, including email
                  address, credit card information, and expiration dates, to facilitate transactions and communication.
                  For comprehensive details, we encourage you to carefully review our Return/Refund/Exchange Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>