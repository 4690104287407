<main class="main">
  <section class="staticSection container">
    <div class="margin-top">
      <span class="mainHeadding">Return/Refund/Exchange Policy</span>
      <div class="wpb_column vc_column_container vc_col-sm-12">
        <div class="vc_column-inner  ">
          <div class="wpb_wrapper">
            <div class="wpb_text_column wpb_content_element ">
              <div class="wpb_wrapper">
                <p>Before understanding AKB's return/refund/exchange policy, it's important to know about our payment
                  options that simplify shopping, whether on our site, app, visiting our store, or choosing home
                  delivery. On our site or app, you can pay in full before goods are delivered using debit cards, credit
                  cards, and third-party services like Paytm, PhonePe, BHIM, Mobile Payment, e-wallets, etc. If you
                  choose to pay upon delivery, you can use cash (Valid Indian Rupees), debit cards, credit cards, or
                  third-party services and e-Wallet (Paytm, PhonePe, etc.). When shopping at our physical store, we
                  accept cash (Valid Indian Rupees), debit cards, credit cards, third-party services, and e-Wallet
                  (Paytm, PhonePe, etc.). AKB offers multiple payment modes to enhance your grocery shopping experience.
                </p> <br />
                <p><strong>Seamless Returns/Refunds/Exchange - No Questions, Just Solutions!</strong></p>
                <p>
                  AKB offers a "no questions asked return, refund, and exchange policy" for all our customers. If, for
                  any reason, you're not 100% satisfied with the product, you can return or exchange it. While we aim to
                  provide a great shopping experience, you can return your order for a full refund or exchange it for a
                  product of the same quantity, quality, and brand. AKB issues a credit note for the return value, which
                  will be credited back to your same payment method, whether it's through net banking, credit card,
                  other online payment methods, physical cash payment, or cash on delivery.
                </p><br />
                <p><strong>AKB's Swift Solution: 5-7 Days for Returns/Refunds/Exchanges!</strong></p>
                <p>
                  To return or exchange a product, simply reach out to us by calling +91 9150030303/9958609650 (between
                  10:00 a.m. and 06:00 p.m. on working days), sending an email to <a
                    href="mailto:support@aapkabazar.co">support@aapkabazar.co</a> or visiting
                  AKB branch where you made the purchase. Please do this within 4 days from the order date and ensure
                  you have the bill/GST bill. <br />
                  Please be aware that AKB can only accept returns, refunds, or exchanges if the products are in a
                  saleable or minimally used condition, with their tags intact and in their original, unwashed, and
                  undamaged packaging.It's important to note that our "AKB Returns/Refund/Exchange Policy" does not
                  cover innerwear,
                  lingerie, fragrances, select beauty products, CDs, pens, and books. Refunds or replacements are
                  subject to inspection by our team. Damages from misuse or neglect aren't covered, and special rules
                  for promotions/giveaways/contests can override AKB policy Returns/Refund/Exchange.
                </p><br />
                <p><strong>Get Your Refund in Just a Few Clicks!</strong></p>
                <p>After receiving your call or email indicating dissatisfaction with the product for any reason, AKB
                  will review the matter. If your request aligns with our return/refund/exchange policy, the payment
                  will be credited back to the same source of payment, whether it's an online payment method, cash on
                  delivery, or payment made at an AKB store's cash counter. <br>
                  Please note that the convenience charge for Cash on Delivery and shipping charges are non-refundable
                  and won't be included in the refund. <br />
                  For in-store purchases, customers need to visit the store for returns/refunds/exchanges and must
                  provide the bill/GST Bill.
                </p><br />
                <p><strong>Empowering Customers: Return a Portion, Reclaim Satisfaction!</strong></p>
                <p>Yes, you may return/exchange a part of the Order, in case of multiple items in an order. Any product
                  being returned needs to be returned in full including all components as well as any complimentary
                  gifts or products which came along with it. However, if your purchase was made in the period of a
                  special promotional offer, contest or any special scheme then return/refund/exchange policy will not
                  be applied.
                </p><br />
                <p><strong>Customer Command: Cancel Orders with Ease!</strong></p>
                <p>Customers can cancel orders as long as they haven't been picked up by the delivery team for the
                  preferred address. Once an order is out for delivery, it cannot be cancelled. If an order has already
                  reached the customer's preferred address, it cannot be cancelled. If the customer refuses to accept
                  the order, it will be left at the preferred address but the customer can apply for a
                  return/refund/exchange of the purchased order.
                </p><br />
                <p><strong>AKB's Order Cancellation Reasons!</strong></p>
                <p>Aap Ka Bazar® can cancel orders for these reasons: (a) suspected fraud by the customer, (b) orders
                  breaking the rules, (c) products not available, or (d) factors beyond AKB's control, like delivery
                  issues due to weather or government guidelines. In such cases, AKB refunds the payment by reversing it
                  to the same source of payment, whether it's a Credit Card or another online payment method.
                </p><br />
                <p><strong>AKB's Return/Refund/Exchange Service!</strong></p>
                <p>When you request a return/refund/exchange with Customer Service, it usually takes about 72 working
                  hours to arrange for the product to be picked up from your location. If you're returning the product
                  yourself, the delivery time depends on your chosen courier. <br />
                  Once the item reaches our warehouse, it undergoes a 48-hour product inspection. After a successful
                  inspection, refunds are typically initiated and will appear in your account within 5-7 working days.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>