<link rel="canonical" [routerLink]="['https://aapkabazar.co/']" />

<!-- <app-category-header></app-category-header> -->
<div class="mainindex">
  <div class="text-center body-img" *ngIf="!bannerResponse">
    <img src="assets/image/gif.gif">
  </div>
  <div *ngIf="bannerResponse">
    <h1 class="hed-h1">Online Grocery Store and Supermarket in India - Aapkabazar.co</h1>
    <link rel="canonical" href="https://aapkabazar.co/" />
    <section class="top-banner">
      <!---Start--->
      <!-- <div class="delayDelivery">
        <h2>Dear Customer, due to bad weather, our deliveries might get delayed. We regret the inconvenience caused!</h2>
      </div> -->
      <div class="TopOffer">
        <!-- <img src="https://images.aapkabazar.co/Offers/pujaD.jpg" class="TopOfferW">
        <img src="https://images.aapkabazar.co/Offers/pujaM.jpg" class="TopOfferM"> -->
        <div class="TopOfferM">
          <div class="TopOfferMTest">
            Use app for best experience!
          </div>
          <div class="TopOfferMBtn">
            <a href="https://play.google.com/store/apps/details?id=co.aapkabazar.app"> <img
                src="../../../assets/image/GooglePlay.jpg"></a>
            <a href="https://apps.apple.com/be/app/aap-ka-bazar-online-grocery/id1640031723"> <img
                src="../../../assets/image/AppleStore.jpg"></a>
          </div>
        </div>
      </div>

      <section class="MainBanner2">
        <!-- <owl-carousel-o [options]="MainBanner" class="web">
          <ng-container *ngFor="let slide of MainBanner2Web">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o> -->
        <owl-carousel-o [options]="MainBanner" class="app">
          <ng-container *ngFor="let slide of MainBanner2App">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </section>

      <section class="MainBanner1">
        <owl-carousel-o [options]="MainBanner" class="web">
          <ng-container *ngFor="let slide of MainBanner1Web">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <owl-carousel-o [options]="MainBanner" class="app">
          <ng-container *ngFor="let slide of MainBanner1App">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </section>



      <!-- <section class="MainBanner3">
        <owl-carousel-o [options]="MainBanner" class="web">
          <ng-container *ngFor="let slide of MainBanner3Web">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <owl-carousel-o [options]="MainBanner" class="app">
          <ng-container *ngFor="let slide of MainBanner3App">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </section> -->
      <!---End--->
    </section>

    <!-- <section class="MainBanner3">
      <div class="app">
        <img src="https://images.aapkabazar.co/slider/sugar.jpg">
      </div>
    </section> -->

    <section class="offers">
      <div class="SliderOwl1">
        <div class="bg-img"><img src="assets/image/left-head.png" alt="">
          <h2 class="head-2">Latest Offers</h2><img src="assets/image/right-head.png" alt="">
        </div>
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of LatestOffers">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </section>



    <section class="category-section first-section firstCatBox">
      <div class="container head-2 CatHomeBox">
        <div class="bg-img"><img src="assets/image/left-head.png">
          <h2 class="head-2">Shop By Category</h2><img src="assets/image/right-head.png">
        </div>
        <div class="row mainSectionList">
          <div class="col-md--12"></div>
          <ng-container *ngIf="rootCategories?.length>0">
            <div class="categories-top" *ngFor="let category of rootCategories">
              <a [routerLink]="['/'+category.urlKey+'/c/'+category._id]">
                <img src="{{ category.id | image:'cat' }}/{{ category.images[0]}}"
                  onerror="this.src='../../../assets/image/no-category.png';" [alt]="category?.name"
                  [title]="category?.name" *ngIf='category.images?.length>0'>
                <h4 class="HomeCatBages" *ngIf="category.offertext">{{category.offertext}}</h4>
                <h6>{{category.name | titlecase}}</h6>
              </a>
            </div>
          </ng-container>
        </div>
      </div>


      <!-- <section class="ShopByDiscount">
        <div class="container">
          <h2 class="head-2">Shop by Discount</h2>
          <ul class="DiscountCard">
            <li class="DiscountCardList">
              <ng-container>
                <a [routerLink]="['discount_list/40']">
                  <img src="../../../assets/image/sale.png" alt="Up to 40% off" title="Up to 40% off">
                  <h5>Up to 40% off</h5>
                </a>
              </ng-container>
            </li>
            <li class="DiscountCardList">
              <ng-container>
                <a [routerLink]="['discount_list/50']">
                  <img src="../../../assets/image/sale.png" alt="Up to 50% off" title="Up to 50% off">
                  <h5>Up to 50% off</h5>
                </a>
              </ng-container>
            </li>
            <li class="DiscountCardList">
              <ng-container>
                <a [routerLink]="['discount_list/60']">
                  <img src="../../../assets/image/sale.png" alt="Up to 60% off" title="Up to 60% off">
                  <h5>Up to 60% off</h5>
                </a>
              </ng-container>
            </li>
            <li class="DiscountCardList">
              <ng-container>
                <a [routerLink]="['discount_list/70']">
                  <img src="../../../assets/image/sale.png" alt="Up to 50% off" title="Up to 70% off">
                  <h5>Up to 70% off</h5>
                </a>
              </ng-container>
            </li>
            <li class="DiscountCardList">
              <ng-container>
                <a [routerLink]="['discount_list/80']">
                  <img src="../../../assets/image/sale.png" alt="Up to 80% off" title="Up to 80% off">
                  <h5>Up to 80% off</h5>
                </a>
              </ng-container>
            </li>
          </ul>
        </div>
      </section> -->


      <div class="SliderOwl1">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of CategoryBanner">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

      <div class="Sub-CatBanner">
        <div class="BannerWebHome">
          <a [routerLink]="['tags/64a932f1e739d07afb781d4a']"><img
              src="https://images.aapkabazar.co/banners/top_banner/web/cookies.webp" alt=""></a>
        </div>
        <div class="BannerMobHome">
          <a [routerLink]="['tags/64a932f1e739d07afb781d4a']"><img
              src="https://images.aapkabazar.co/banners/top_banner/app/cookies.webp" alt=""></a>
        </div>
      </div>


      <div class="SliderOwl1">
        <owl-carousel-o [options]="customOptions1">
          <ng-container *ngFor="let slide of SliderOwlCat">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

    </section>







    <section class="offers hot-deal">
      <div class="bg-img"><img src="assets/image/left-head.png" alt="">
        <h2 class="head-2">Hot Deal</h2><img src="assets/image/right-head.png" alt="">
      </div>
      <div class="Sub-CatBanner">
        <div class="BannerWebHome">
          <a [routerLink]="['tags/61fb8acbe6a622b6ac995ae3']">
            <img src="https://images.aapkabazar.co/slider/papad-w.webp" alt="">
          </a>
        </div>
        <div class="BannerMobHome">
          <a [routerLink]="['tags/61fb8acbe6a622b6ac995ae3']">
            <img src="https://images.aapkabazar.co/slider/papad-a.webp" alt="">
          </a>
        </div>
      </div>

      <div class="SliderOwl1">
        <owl-carousel-o [options]="customOptions1">
          <ng-container *ngFor="let slide of HotDeals">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

    </section>

    <section class="BgColorSlider1">

      <div class="SliderOwl1">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of HotDeals2">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

    </section>


    <section class="offers">
      <div class="bg-img"><img src="assets/image/left-head.png" alt="">
        <h2 class="head-2">Crazy Deals</h2><img src="assets/image/right-head.png" alt="">
      </div>
      <div class="Sub-CatBanner">
        <div class="BannerWebHome">
          <a [routerLink]="['mannmasti-gulab-sharbat-750-ml-buy-1-get-1-free/p/61eaecae2da73066de6b3032']"><img
              src="https://images.aapkabazar.co/banners/top_banner/web/sharbat750ml.webp" alt=""></a>
        </div>
        <div class="BannerMobHome">
          <a [routerLink]="['mannmasti-gulab-sharbat-750-ml-buy-1-get-1-free/p/61eaecae2da73066de6b3032']"><img
              src="https://images.aapkabazar.co/banners/top_banner/app/sharbat750ml.webp" alt=""></a>
        </div>
      </div>
      <div class="SliderOwl1">
        <owl-carousel-o [options]="customOptions2">
          <ng-container *ngFor="let slide of CrazyDeals">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

    </section>





    <section class="category-section first-category-bg sub-CatSection SecondCatBox">
      <div class="container">
        <div class="bg-img">
          <img src="assets/image/left-head.png" alt="">
          <h2 class="head-2">Masalas, Oils & Dry Fruits</h2><img src="assets/image/right-head.png" alt="">
        </div>

        <!-----Category-Start---->
        <div class="row mainSectionList">
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['masalas-oils--dry-fruits/edible-oils/c/66309ebc8d438b7d0647dfac/66309ed68d438b7d0647dfb6']">
                <img src="../../../assets/image/category/food/edible.png" alt="" title=""
                  class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Edible Oils</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a
                [routerLink]="['masalas-oils--dry-fruits/masalas--spices/c/66309ebc8d438b7d0647dfac/66309eef8d438b7d0647dfc0']">
                <img src="../../../assets/image/category/food/masalas.png" alt=""
                  title="" class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Masalas Spices</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['masalas-oils--dry-fruits/dryfruits/c/66309ebc8d438b7d0647dfac/66309f0a8d438b7d0647dfcb']">
                <img src="../../../assets/image/category/food/Dryfruits.png" alt="" title=""
                  class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Dryfruits</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a
                [routerLink]="['masalas-oils--dry-fruits/ghee/c/66309ebc8d438b7d0647dfac/66309fd58d438b7d0647dfd5']">
                <img src="../../../assets/image/category/food/Ghee.png" alt=""
                  title="" class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Ghee</p>
              </a>
            </div>
          </div>
          <!-- <div class="col-md-2 col-4 p-2 ng-star-inserted">
      <div class="sub-category-img">
        <a [routerLink]="['food-grains-spices/spices-masala/c/61b18e41c5aed278ca11c069/61b19378c5aed278ca11c0f3']">
          <img src="https://api.aapkabazar.co/api/public/cat/83/1653124587876.png" alt="spices &amp; masala" title="spices &amp; masala" class="ng-star-inserted">
          <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
          <p>Spices &amp; Masala</p>
        </a>
      </div>
    </div> -->
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['/masalas-oils--dry-fruits/c/66309ebc8d438b7d0647dfac']">
                <h4>all Category</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-----Category-End---->

      <div class="Sub-CatBanner">
        <div class="BannerWebHome">
          <a [routerLink]="['gauri-premium-basmati-rice-5-kg/p/61eaecb12da73066de6b460e']"><img
              src="https://images.aapkabazar.co/banners/top_banner/web/gauri-pre.webp" alt=""></a>
        </div>
        <div class="BannerMobHome">
          <a [routerLink]="['gauri-premium-basmati-rice-5-kg/p/61eaecb12da73066de6b460e']"><img
              src="https://images.aapkabazar.co/banners/top_banner/app/gauri-pre.webp" alt=""></a>
        </div>
      </div>

      <div class="SliderOwl1">
        <owl-carousel-o [options]="customOptions1">
          <ng-container *ngFor="let slide of FoodGrain">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

    </section>




    <section class="category-section first-category-bg sub-CatSection ThirdCatBox">
      <div class="container">
        <div class="bg-img">
          <img src="assets/image/left-head.png" alt="">
          <h2 class="head-2">Beverages</h2><img src="assets/image/right-head.png" alt="">
        </div>

        <!-----Category-Start---->
        <div class="row mainSectionList">
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['hot--cold-beverages/coffees/c/6631e5c6634af0739a1dfe47/6631e5e6634af0739a1dfe53']">
                <img src="../../../assets/image/category/beverage/coffee.png" alt="" title="" class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Coffee</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['hot--cold-beverages/teas/c/6631e5c6634af0739a1dfe47/6631e5fc634af0739a1dfe5f']">
                <img src="../../../assets/image/category/beverage/tea.png" alt="" title="" class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Tea</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['hot--cold-beverages/energy--soft-drinks/c/6631e5c6634af0739a1dfe47/6631e65f634af0739a1dfe75']">
                <img src="../../../assets/image/category/beverage/soft-drink.png" alt="" title="" class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Energy & Soft Drinks</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['hot--cold-beverages/fruit-juices--drinks/c/6631e5c6634af0739a1dfe47/6631e647634af0739a1dfe6a']">
                <img src="../../../assets/image/category/beverage/juice.png" alt="" title="" class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Fruit Juices & Drinks</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['hot--cold-beverages/c/6631e5c6634af0739a1dfe47']">
                <h4>all Category</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-----Category-End---->

      <div class="Sub-CatBanner">
        <div class="BannerWebHome">
          <a [routerLink]="['cadbury-bournvita-500-gm--pouch/p/61eaecae2da73066de6b31c0']"><img
              src="https://images.aapkabazar.co/banners/top_banner/web/bournvita5.webp"></a>
        </div>
        <div class="BannerMobHome">
          <a [routerLink]="['cadbury-bournvita-500-gm--pouch/p/61eaecae2da73066de6b31c0']"><img
              src="https://images.aapkabazar.co/banners/top_banner/app/bournvita5.webp"></a>
        </div>
      </div>

      <div class="SliderOwl1">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of SliderOwl4">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

    </section>


    <section class="category-section first-category-bg sub-CatSection FourCatBox">
      <div class="container">
        <div class="bg-img">
          <img src="assets/image/left-head.png" alt="">
          <h2 class="head-2">Beauty & Cosmetics</h2>
          <img src="assets/image/right-head.png" alt="">
        </div>

        <!-----Category-Start---->
        <div class="row mainSectionList">
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['beauty--cosmetics/sun-cream--face-wash/c/66334062b016ae8ec620691f/66334081b016ae8ec620692a']">
                <img src="../../../assets/image/category/beauty/SunCream.png" alt="" title=""
                  class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Sun Cream Face Wash</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['beauty--cosmetics/makeups/c/66334062b016ae8ec620691f/6633409db016ae8ec6206936']">
                <img src="../../../assets/image/category/beauty/makeup.png" alt="" title=""
                  class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Makeup</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['beauty--cosmetics/skin-cares/c/66334062b016ae8ec620691f/663340b7b016ae8ec6206942']">
                <img src="../../../assets/image/category/beauty/skincare.png" alt="" title=""
                  class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Skin Care</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['beauty--cosmetics/fragrances--deos/c/66334062b016ae8ec620691f/663340c9b016ae8ec620694d']">
                <img src="../../../assets/image/category/beauty/fragrances.png" alt="" title="" class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Fragrances & Deos</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['beauty--cosmetics/c/66334062b016ae8ec620691f']">
                <h4>all Category</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-----Category-End---->

      <div class="Sub-CatBanner">
        <div class="BannerWebHome">
          <a [routerLink]="['dove-cream-beauty-bathing-bar-4125-gm--125-gm-soap-free/p/61eaecb22da73066de6b4832']"><img
              src="https://images.aapkabazar.co/slider/dovesoapw.webp" title="" alt=""></a>
        </div>
        <div class="BannerMobHome">
          <a [routerLink]="['dove-cream-beauty-bathing-bar-4125-gm--125-gm-soap-free/p/61eaecb22da73066de6b4832']"><img
              src="https://images.aapkabazar.co/slider/dovesoapm.webp" title="" alt=""></a>
        </div>
      </div>

      <div class="SliderOwl1">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of SliderOwl5">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

    </section>





    <section class="category-section first-category-bg sub-CatSection FiveCatBox">
      <div class="container">
        <div class="bg-img">
          <img src="assets/image/left-head.png" alt="">
          <h2 class="head-2">Detergents & Cleaning</h2>
          <img src="assets/image/right-head.png" alt="">
        </div>

        <!-----Category-Start---->
        <div class="row mainSectionList">
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a
                [routerLink]="['detergents--cleaning/detergents--dishwash/c/6633444bb016ae8ec6206a84/663344c2b016ae8ec6206aa5']">
                <img src="../../../assets/image/category/houseHold/Detergents.png" alt="" title=""
                  class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Detergents & Dishwash</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a
                [routerLink]="['detergents--cleaning/bins--bathroom-ware/c/6633444bb016ae8ec6206a84/66334567b016ae8ec6206ab0']">
                <img src="../../../assets/image/category/houseHold/CatHouse2.png" alt="" title=""
                  class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Bins & Bathroom Ware</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a
                [routerLink]="['detergents--cleaning/fresheners--repellents/c/6633444bb016ae8ec6206a84/6633457eb016ae8ec6206abb']">
                <img src="../../../assets/image/category/houseHold/CatHouse3.png" alt="" title=""
                  class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Fresheners & Repellents</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a
                [routerLink]="['detergents--cleaning/car--shoe-care/c/6633444bb016ae8ec6206a84/66334594b016ae8ec6206ac6']">
                <img src="../../../assets/image/category/houseHold/Car.png" alt="" title=""
                  class="ng-star-inserted">
                <h5 class="HomeCatBages ng-star-inserted">Up to 50% off</h5>
                <p>Car & Shoe Care</p>
              </a>
            </div>
          </div>
          <div class="col-md-2 col-4 p-2 ng-star-inserted SecondCatBox11">
            <div class="sub-category-img">
              <a [routerLink]="['detergents--cleaning/c/6633444bb016ae8ec6206a84']">
                <h4>all Category</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-----Category-End---->

      <div class="Sub-CatBanner">
        <div class="BannerWebHome">
          <a [routerLink]="['use2-toilet-cleaner-5-ltr/p/61eaecb02da73066de6b3f32']"><img
              src="https://images.aapkabazar.co/banners/top_banner/web/toilet-W.webp" title="" alt=""></a>
        </div>
        <div class="BannerMobHome">
          <a
            [routerLink]="['cleaning-households/washing-care-detergent/c/61b1912dc5aed278ca11c08d/61b1995fc5aed278ca11c2b3']"><img
              src="https://images.aapkabazar.co/slider/houseHold.jpg" title="" alt=""></a>
        </div>
      </div>

      <!-- <div class="OfferSection1">
             <div class="col-md-5 OfferSectionLeft">
                 <ul class="OfferSectionList">
                   <li>
                     <a [routerLink]="['my-choice-bucket-10-ltr-forsty/p/61eaecaf2da73066de6b34ac']"><img src="../../../assets/image/Offers/cl1.jpg" title="" alt=""></a>
                   </li>
                   <li>
                    <a [routerLink]="['black-polothine-20x26l-40pices-50-off/p/61eaecaf2da73066de6b345d']"><img src="../../../assets/image/Offers/cl2.jpg" title="" alt=""></a>
                  </li>
                  <li>
                    <a [routerLink]="['gala-brushtile-hardy-1pc/p/61eaecaf2da73066de6b3456']"><img src="../../../assets/image/Offers/cl4.jpg" title="" alt=""></a>
                  </li>
                  <li>
                    <a [routerLink]="['rin-detergent-powder-7-kg/p/61ed212c742f0368e149aaaf']"><img src="../../../assets/image/Offers/mbanner1.jpg" title="" alt=""></a>
                  </li>
                 </ul>
             </div>

             <div class="col-md-7 OfferSectionRight">
                <div class="SliderOwl1 bgRed">
                  <owl-carousel-o [options]="customOptions1">  
                    <ng-container *ngFor="let slide of SliderOwl6">
                      <ng-template carouselSlide [id]="slide.id">
                        <a [routerLink]="slide.link">
                          <img [src]="slide.img" [alt]="slide.alt">
                        </a>
                      </ng-template>
                    </ng-container>      
                  </owl-carousel-o>
                </div>
             </div>
        </div> -->

      <div class="SliderOwl1">
        <owl-carousel-o [options]="customOptions1">
          <ng-container *ngFor="let slide of SliderOwl6">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </section>

    <section class="offers">
      <div class="SliderOwl1">
        <div class="bg-img"><img src="assets/image/left-head.png" alt="">
          <h2 class="head-2">AKB Special Offers</h2><img src="assets/image/right-head.png" alt="">
        </div>
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of SliderOwl9">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </section>




    <!-- <section class="r mainSectionListow">
      <h2 class="head-2">Show by Discount</h2>
      <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
        <div class="discount-card ">
          <ng-container>
            <div class="categories-section">
              <a [routerLink]="['discount_list/20']">
                <img src="https://t3.ftcdn.net/jpg/02/95/33/44/360_F_295334437_7PH6byrEfFqqZxzyj84zMGWZuAMILmnI.jpg"
                  [alt]="" [title]="">
                <h5 class="HomeCatBages">UP TO 20% OFF</h5>
                <p>All Categories</p>
              </a>
            </div>
          </ng-container>
        </div>
        <div class="discount-card">
          <ng-container>
            <div class="categories-section">
              <a [routerLink]="['discount_list/30']">
                <img src="https://t3.ftcdn.net/jpg/02/95/33/44/360_F_295334437_7PH6byrEfFqqZxzyj84zMGWZuAMILmnI.jpg"
                  [alt]="" [title]="">
                <h5 class="HomeCatBages">UP TO 30% OFF</h5>
                <p>All Categories</p>
              </a>
            </div>
          </ng-container>
        </div>
        <div class="discount-card">
          <ng-container>
            <div class="categories-section">
              <a [routerLink]="['discount_list/50']">
                <img src="https://t3.ftcdn.net/jpg/02/95/33/44/360_F_295334437_7PH6byrEfFqqZxzyj84zMGWZuAMILmnI.jpg"
                  [alt]="" [title]="">
                <h5 class="HomeCatBages">UP TO 50% OFF</h5>
                <p>All Categories</p>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </section>-->


    <section class="GetApp">
      <div class="container-fluid">
        <img src="https://images.aapkabazar.co/Offers/GetApp.jpg" class="MainGetImg">
        <div class="GetAppBox">
          <div class="GetAppHeading">
            Use app for best experience!
          </div>
          <div class="GetAppIcon">
            <a href="https://play.google.com/store/apps/details?id=co.aapkabazar.app"> <img
                src="../../../assets/image/GooglePlay.jpg"></a>
            <a href="https://apps.apple.com/be/app/aap-ka-bazar-online-grocery/id1640031723"> <img
                src="../../../assets/image/AppleStore.jpg"></a>
          </div>
        </div>
      </div>
    </section>


    <!------ShopByBrand-------->
    <section class="brand">
      <div class="container-fluid">
        <div class="bg-img"><img src="assets/image/left-head.png">
          <h2 class="head-2">Shop by Brands</h2><img src="assets/image/right-head.png" alt="">
        </div>
        <div class="row">
          <div class="BrandWeb">
            <ul class="footerBrandBox" id="BrandBox">
              <li *ngFor="let item of brand | slice:0:40">
                <div class="footer-brand">
                  <a href="javascript:void(0)">
                    <img src="{{apiURL}}public/brand/{{item?.id}}/{{item.image[0]}}" [alt]="item?.name"
                      [title]="item?.name" *ngIf='item?.image?.length>0'>
                    <img src="../../../assets/image/no-category.png" *ngIf="item?.image == null">
                    <p>{{item.name | titlecase}}</p>
                  </a>
                </div>
              </li>
            </ul>
            <button (click)="BrandWeb()" type="checkbox" id="BrandShow">Show More</button>
          </div>

          <div class="BrandMob">
            <ul class="footerBrandBox" id="BrandBox1">
              <li *ngFor="let item of brand | slice:0:50">
                <div class="footer-brand">
                  <a href="javascript:void(0)">
                    <img src="{{apiURL}}public/brand/{{item?.id}}/{{item.image[0]}}" [alt]="item?.name"
                      [title]="item?.name" *ngIf='item?.image?.length>0'>
                    <img src="../../../assets/image/no-category.png" *ngIf="item?.image == null">
                    <p>{{item.name | titlecase}}</p>
                  </a>
                </div>
              </li>
            </ul>
            <button (click)="BrandMob()" type="checkbox" id="BrandShow">Show More</button>
          </div>
        </div>

      </div>
    </section>


    <!----
  <section class="brand ShobyTag">
    <div class="container-fluid">
       <div class="bg-img"><img src="assets/image/left-head.png">
        <h2 class="head-2">Shop By Tags</h2><img src="assets/image/right-head.png" alt=""></div>
        <div class="row">
  
        <div class="col-md-2 col-4 p-2" *ngFor="let item of tags | slice:0:12" >
            <div class="footer-brand">
              <a [routerLink]="['/tags/'+item?._id]">
                  <img src="{{apiURL}}public/brand/{{item?.id}}/{{item.image[0]}}" [alt]="item?.name" [title]="item._name" *ngIf='item.image?.length>0'>
                  <img src="../../../assets/image/no-category.png" *ngIf="item.image == null">
                  <p>{{item.name | titlecase}}</p>
                </a>
            </div>
        </div>
    </div>
    </div>
  </section>
-->

    &nbsp;&nbsp;
    <section class="footer-banner">
      <img src="https://images.aapkabazar.co/banners/fotter-banner.jpg" alt="">
    </section>
  </div>
</div>