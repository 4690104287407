<div class="main">
  <div class="container mainGauri">
      <!-- <div class="bg-img"> <h2 class="GauriHeadding">Gauri Basmati Rice</h2> </div> -->
      <img src="../../../assets/image/gauri.png" class="gauriBrandImg">
      
      <h2 class="gauriText">Buy Gauri Basmati Rice Online!</h2>

      <ul class="GauriMainBox">
        <h3>Download the app! </h3>
        <li><a href="https://play.google.com/store/apps/details?id=co.aapkabazar.app"> 
          <img src="../../../assets/image/GooglePlay.jpg"></a>
        </li>
        <li><a href="https://apps.apple.com/be/app/aap-ka-bazar-online-grocery/id1640031723"> 
          <img src="../../../assets/image/AppleStore.jpg"></a>
        </li>
      </ul>

  </div>
</div>   
