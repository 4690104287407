<br/><br/><br/><br/><br/>
<div class="conflictClass"></div>
<table id="dataSheetGA">
    <tr>
        <td>Amul Milk 1 Ltr</td>
        <td>Best Amul Milk</td>
        <td>895689669668</td>
        <td>www.aapkabazar.co/amul1kg</td>
        <td>AKB256896393</td>
        <td>Rs 29</td>
        <td>INR</td>
    </tr>
    <tr>
        <td>Amul Milk 2 Ltr</td>
        <td>Best Amul Milk</td>
        <td>895689669669</td>
        <td>www.aapkabazar.co/amul2kg</td>
        <td>AKB256896394</td>
        <td>Rs 56</td>
        <td>INR</td>
    </tr>
    <tr>
        <td>Amul Milk 3 Ltr</td>
        <td>Best Amul Milk</td>
        <td>895689669670</td>
        <td>www.aapkabazar.co/amul3kg</td>
        <td>AKB256896395</td>
        <td>Rs 79</td>
        <td>INR</td>
    </tr>
    <tr>
        <td>Amul Milk 1 Ltr</td>
        <td>Best Amul Milk</td>
        <td>895689669668</td>
        <td>www.aapkabazar.co/amul1kg</td>
        <td>AKB256896393</td>
        <td>Rs 29</td>
        <td>INR</td>
    </tr>
    <tr>
        <td>Amul Milk 2 Ltr</td>
        <td>Best Amul Milk</td>
        <td>895689669669</td>
        <td>www.aapkabazar.co/amul2kg</td>
        <td>AKB256896394</td>
        <td>Rs 56</td>
        <td>INR</td>
    </tr>
  </table>
