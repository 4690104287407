<div class="main">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 checkoutRightBox">               
                <div class="card">
                    <div class="card-header py-3" mb-color="second">
                        <h5 class="mb-0">Delivery Address & Time Details</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <!-- add new address -->
                            <div class="col-md-12 mb-2 pointer" (click)=addAddress() *ngIf="this.user._id">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="add-new-address">
                                            <i class="fa fa-plus mr-2" aria-hidden="true"></i>
                                            <h2 class="add-address-heading">Add New Address</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div (click)=login() *ngIf="!this.user._id">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="add-new-address">
                                            <i class="fa fa-plus mr-2" aria-hidden="true"></i>
                                            <h2 class="add-address-heading">Login & Add New Address</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Add new address -->
                            <!-- Address -->
                            <!-- <div class="col-md-12 mb-2" *ngIf="address._id">
                                <div class="card p-3">
                                    <div class="">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h5 mb-text-color="second">{{ address?.name | titlecase }}</h5>
                                            <h5 (click)=updateAddress(address) tooltip="Edit Address"> <i
                                                    mb-text-color="primary" class="fa fa-2x fa-pencil-square-o"
                                                    aria-hidden="true"></i></h5>
                                        </div>
                                        <p class="address-street">Mobile No:{{ address?.mobileNo }}
                                        </p>
                                        <p class="address-street">{{address?.line1 | titlecase}},</p>
                                        <p class="address-street">{{address?.line2 | titlecase}}
                                            {{address?.fullAddress | titlecase}}</p>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-md-12 mb-2" *ngFor="let address of addressLists">
                              <div class="col-sm-12 border address py-2">
                                  <div class="d-flex justify-content-between addressHead">
                                      <h6>{{ address?.type | uppercase}} &nbsp; | &nbsp; <span>Mobile : {{ address?.mobileNo }}</span></h6>
                                      <div class="d-flex align-items-center">
                                          <i class="fa fa-pencil-square-o" aria-hidden="true" mb-text-color="second" tooltip="Edit Address" (click)=updateAddress(address)></i>
                                      </div>
                                  </div>
                                  <div class="CeckAddressBody">
                                    <div class="d-flex ">
                                        <p><strong>Name :</strong> {{ address?.name | titlecase }}</p>
                                    </div>
                                    <p><strong>Address :</strong> {{ address?.line1 | titlecase }} , {{ address?.line2 | titlecase}}, {{ address?.fullAddress | titlecase}}</p>
                                    <button type="button" class="btn ml-0 text-white rounded" mb-color="primary" (click)="setDefaultAddress(address?._id)" *ngIf="!address?.isDefault">Deliver Here</button>
                                    <button type="button" class="btn ml-0 text-white rounded" disabled mb-color="primary" *ngIf="address?.isDefault"><i class="text-success mr-1 fa fa-check" aria-hidden="true"></i> Deliver Here</button>
                                  </div>
                              </div>
                            </div>
                            <!-- /Address -->
                        </div>
                        <!-- delivery date & time -->
                        <div class="SlotDisabledBox" *ngIf='slots?.isDisableSlot'>
                            <b class="mr-2">Note:</b> 
                            <p>{{slots?.disableSlotMsg | titlecase}}</p>
                        </div>
                        <div class="card time-card">
                            <div class="card-header">
                                <ul class="list-unstyled days  mb-0">
                                    <li *ngFor="let day of dates; let i = index;">
                                        <label>
                                            <input type="radio" class="custom-control-input" id="{{ day }}" name="day"
                                                (click)=selectDay(i,day) [checked]="i==indexOfDayToDeliver"
                                                [disabled]="allowedDayIndex>i">
                                            <div class="days-box">
                                                <p>{{i ?(day | date : "E"):"Today"}}</p>
                                                <p>{{day.date()}} {{day | date : "MMM"}}</p>
                                            </div>
                                        </label> 
                                        <!-- <label>
                                            <input type="radio" class="custom-control-input" id="{{ day }}" name="day"
                                                (click)=selectDay(i,day) [checked]="i==indexOfDayToDeliver"
                                                [disabled]="day.date() == 18">
                                                [disabled]="allowedDayIndex>i" 
                                            <div class="days-box">
                                                <p>{{i ?(day | date : "E"):"Today"}}</p>
                                                <p>{{day.date()}} {{day | date : "MMM"}}</p>
                                            </div>
                                        </label> -->
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body p-3">
                                <ul class="SlotTimeList">
                                    <li *ngFor="let slot of slots?.slotList; let i = index">
                                        <button type="button" class="btn" tooltip="" mdbBtn value="{{ slot?.slot }}"
                                            id="{{ slot?.slot }}" name="slot" (click)="selectTime(slot)"
                                            [class.selected-slot-btn]="selectedSlot?.slot==slot?.slot"
                                            [disabled]="slot?.isDisabled && ((slotData?.isNewSlot) || (slotData?.isOldSlot && indexOfDayToDeliver == 0))" class="slot-btn">
                                            {{ slot.slot }}
                                            <span class="SlotAval">Slot available</span>
                                            <span class="SlotUnaval">Time slot full</span>
                                        </button>
                                    </li>
                                </ul>
                                <!-- <div class="col-md-12 padding0">
                                    <ul class="slotAvailablilty">
                                        <li>
                                            <span></span> <p>Unavailable time slots.</p>
                                        </li>
                                        <li>
                                            <span></span> <p>Available time slots.</p>
                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                            <div class="card CustomerNoteBox">
                                <div class="card-header btn-custom-primary">Customer Note.</div>
                                <div class="card-body CustomerNote">
                                    <textarea col='4' class="form-control" [formControl]='customerMessage' maxlength="160"></textarea>
                                    <div class="text-danger mt-2" *ngIf='customerMessage?.value?.length>=160' placeholder="Enter your message bbout order or product.">Max Character Should be 160.</div>
                                </div>
                            </div>
                        </div>
                        <!-- /delivery date & time -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 pLeft">
                <div class="stick">
                    <app-price-detail-table [checkoutPage]="checkoutPage"></app-price-detail-table>
                    <div class="card-body">
                        <div class="row" >
                            <div class="col-xl-12 col-md-12 col-sm-12">
                              <label>
                                <input (click)="selectiagree(true)" value="true" type="checkbox"> I agree terms and conditions
                              </label>
                                    <br>
                              <label style="color: #f90000;" *ngIf="!addressLists" >
                                Please add your address first
                              </label>
                              
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="ProceedToPaymentButton; else elseTemplate">
                        <button class="btn btn-custom-primary btn-block ProceedBtn" *ngIf="this.user._id" (click)=proceedToPayment()
                        [disabled]='isShowSpinner'>Proceed to Payment</button>
                        <button class="btn btn-custom-primary btn-block ProceedBtn" (click)=login() *ngIf="!this.user._id"
                        [disabled]='isShowSpinner'>Proceed to Payment</button>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <button class="btn btn-custom-primary btn-block ProceedBtn" disabled>Proceed to Payment</button>
                    </ng-template>
                    
                    
                    <div class="text-center">
                        <div class="spinner-border text-primary text-center" *ngIf='isShowSpinner'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #productUnavailable>
    <div class="checkPopup">
        <div class="modal-header ChecknoProductFound">
            <h4 class="modal-title pull-left">Products Stock Alert</h4>
            <button type="button" class="close pull-right" (click)="hideProductUnavailableModal()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <p class="warning">Sorry, the products shown below are currently low stock. </p>
        <div class="modal-body checkproductBox">        
            <div class="card">
                <div class="card-body mb-card-body CheckOverflow">
                    <div class="row mb-row" *ngFor="let product of unavailableProducts">
                        <div class="col-sm-2">
                            <div class="mx-auto">
                                <img class="mx-auto pointer py-2 bag-img"
                                    src="{{ product?.id | image}}/{{ product?.images[0] | imageSize:200 }}"
                                    [alt]="product?.name">
                            </div>
                        </div>
                        <div class="col-sm-10">
                            <div class="cart-list">
                                <p class="h5">{{ product?.name }}</p>
                                <h4 class="pointer">₹{{ product?.sellPrice }}  <span class="grey-text"
                                        *ngIf="product?.sellPrice!=product?.price"><del> ₹{{ product?.price }}</del></span>
                                </h4>
                                <!--<p class="h6">{{ product?.brand?.name }}</p>                       
                                <h5 class=" mt-1 h6 grey-text" *ngIf="product.quantity>0; else notStock">In Stock</h5>
                                <button type="button" class=" bag-btn-outline">{{ product?.recommendedAttribute }}</button> -->
                                <strong class="LeftQty">
                                    <p mb-text-color="primary"  *ngIf='product?.isSellerCatActive'>
                                        Please <span>remove {{bag[product?._id]?.itemQuantity - product?.availableQuantity}} qty. </span>  out of
                                        {{bag[product?._id]?.itemQuantity}} qty.
                                    </p>
                                    <p mb-text-color="primary"  *ngIf='!product?.isSellerCatActive'>
                                        Please <span>remove {{bag[product?._id]?.itemQuantity}} qty. </span> out of
                                        {{bag[product?._id]?.itemQuantity}} qty.
                                    </p>
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer p-0">
            <button type="button" class="footer-btn" mdbWavesEffect (click)="removeItemsFromBag()">Update Cart</button>
        </div>
    </div>
</ng-template>
<!-- <ng-template #userInfo>
    <div class="modal-header">
        <h4 class="modal-title pull-left">User Info</h4>
        <button type="button" class="close pull-right" (click)="hideUserInfoModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="userInfoForm">
            <div class="form-group">
                <div class="card">
                    <div class="md-form my-form">
                        <input mdbInput type="text" placeholder="example@gmail.com" class="form-control"
                            formControlName="email" id="email">
                        <label for="email" class="">Please enter your email id</label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="card">
                    <div class="md-form my-form">
                        <input mdbInput type="number" placeholder="999*****71" id="phoneNo" class="form-control"
                            formControlName="phoneNo">
                        <label for="phoneNo" class="">Please enter your phone number</label>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <button mdbBtn type="submit" mdbWavesEffect class="btn btn-custom-clear"
                    (click)="hideUserInfoModal()">Cancel</button>
                <button mdbBtn type="submit" mdbWavesEffect class="btn btn-custom-primary z-depth-1"
                    (click)="updateProfile()" [disabled]="!userInfoForm.valid">Submit</button>
            </div>
        </form>
    </div>
</ng-template> -->
