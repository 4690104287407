<main class="main">
  <section class="staticSection container">
    <div class="term">
      <span class="mainHeadding">Terms & Conditions</span>
      
      <div class="returnPage">
        <p><strong>Introduction</strong></p>
        <p>
          Welcome to Aapkabazar.co, where we provide website, app, and in-store services for an enjoyable shopping
          experience. By using our website, or app, or visiting our stores, you agree to our Terms of Use, which include
          our guidelines and policies. Please read and accept these terms and conditions to use our website/app and shop
          online or in-store. You must agree to these terms to benefit from our services. If you don't agree, you won't
          have full access to our services. Your continued use implies your agreement with our terms.
        </p>

        <p><strong>Intellectual Property Rights</strong></p>
        <p>
          All intellectual property rights on the AKB app/website/stores, including the visual design, content,
          graphics, audio, video, text, and the software/application that runs the website, are owned by Aapkabazar.co.
          These rights are protected by Indian and international intellectual property laws. The trademark 'Aap Ka
          Bazar' and all other product trademarks, as well as the domain name, belong solely to Aapkabazar.co. The
          software used on this website is the property of Aapkabazar.co, its affiliates, or its software suppliers and
          is protected by Indian and international copyright laws. You cannot extract or reuse any part of the website
          content without our written consent. You (users) are not allowed to reverse engineer the website or use data
          mining, bots, or similar tools for data extraction without our written permission. You have no rights to claim
          any part of the intellectual property on the website or its content, and you cannot object to any applications
          made by Aapkabazar.co, its holding company, affiliates, and subsidiaries for trademark, patent, or copyright
          registrations. You also cannot file any claims or seek ownership of trademarks, website components, or content
          owned by Aapkabazar.co, its holding company, affiliates, or subsidiaries, or approach authorities to register
          similar or deceptively similar intellectual property that would infringe on Aapkabazar.co rights.
        </p>

        <p><strong>Restrictions on Use</strong></p>
        <p>
          When you visit the AKB website/app/store, please avoid any actions that could disrupt, damage, or limit access
          to the platform. Ensure that your usage is lawful and transactions follow legal procedures. Do not use false
          credentials for creating an account, and only use authorized payment methods. The Portal/website/store should
          not be used for illegal activities. Refrain from sharing offensive, deceptive, or copyrighted material, as
          well as content that may harm others or promote illegal actions. The platform is not for commercial resale of
          unlisted products, and you can't promote items outside of Aapkabazar.co offerings. In summary, please use the
          Portal/website/etc. responsibly and by the law.
        </p>

        <p><strong>Pricing Terms and Conditions</strong></p>
        <p>
          The product prices listed on the Portal/website/store are the initial prices for sale. However, these prices
          may change, be revised, or discounted by the company's pricing policy, and such changes can occur without
          prior notice before a purchase is finalized. The listed price does not include shipping and delivery charges
          unless stated otherwise. While AKB makes every effort to display correct prices, technical or system errors
          may lead to mispricing. In such cases like if the actual price is less than the listed price, you (the user)
          will be charged the lower, accurate price and if the actual price is higher, we have the discretion to either
          reach out to you for approval to proceed with the purchase at the correct price or cancel the order, informing
          you of the cancellation and the reason. <br />
          If you find that a product was overcharged during shipping/shopping, AKB will refund the extra amount to the
          same debited payment method.
        </p>

        <p><strong>Customer Feedback or Uploads</strong></p>
        <p>
          You (Users) are welcome to share your reviews, comments, and ideas with us on the AKB Portal/Website/Store and
          other platforms like social media, but please keep it legal and respectful. <br />
          Offensive, abusive, defamatory, invasive of privacy or harmful content isn't allowed. Please avoid posting
          content with bugs, viruses, spam, or commercial promotions. <br />
          Provide accurate information, including your contact details, etc., and don't impersonate others. Misleading
          actions may result in content removal or account termination. When you post content, you grant us and our
          affiliates permission to use it worldwide, unless stated otherwise. This permission lasts as long as your
          content's intellectual property rights. You also agree not to claim authorship or object to reasonable
          alterations.
        </p>

        <p><strong>Delivery Terms and Conditions</strong></p>
        <p>
          AKB strives to meet your selected delivery time, but please note that delivery times are estimated and not
          guaranteed due to various factors such as inclement weather, road safety concerns, government guidelines, etc.
          In case of any unforeseen issues, you will be promptly notified and issued a refund. <br />
          If you happen to miss a delivery, the order may either be returned to the store or left at your doorstep after
          notification. In such cases, you can apply for refunds through AKB's return/refund/exchange policy. Details
          about unavailable items can be found on the respective pages of the Portal/Website/Store the available items
          will be delivered, and you will receive a refund for the remaining products. <br />
          If AKB has to cancel your order, we'll let you know and issue a refund. Please keep in mind that you can't
          change the delivery address after 24 hours from order placement. If you want products sent to different
          addresses, you'll need to place separate orders. <br />
          During shipping, you may receive a pickup code (optional) for easier order retrieval. However, please do not
          share this code with others, as we cannot be held responsible for refunds if it is used by someone else.
        </p>

        <p><strong>Delivery Taxes/Charges/GST</strong></p>
        <p>
          You, as the user, are responsible for covering all costs, fees, charges, and taxes (including VAT and GST)
          associated with your purchases from our portal, website, or store. If there are additional taxes, duties, or
          fees imposed on your transactions, like for deliveries to different states, you're responsible for paying
          them. These additional charges may increase the overall cost of your purchase compared to the listed price on
          our Portal, website, or store.
        </p>

        <p><strong>Performance of the Application and Website</strong></p>
        <p>
          AKB does its best to maintain the Portal/Website/Store staff's performance, but we're not responsible for
          malfunctions, glitches, unresponsiveness, or personal behaviour. The Portal/Website might have planned and
          unplanned downtime, for which we're not liable. <br />
          AKB can't be held responsible for any bugs, viruses, ransomware, malware, or similar problems that might
          affect your devices or shopping experience through the Portal/Website/Store, whether from us or third parties.
          <br />
          AKB takes some responsibility for unauthorized access to your personal information and contact details,
          including financial and transaction data stored on the Portal/Website or our servers. If you encounter issues,
          we'll try to help, but we also disclaim responsibility for any data breaches on our servers and any related
          losses.
        </p>

        <p><strong>Injury/Loss Due to Use</strong></p>
        <p>
          AKB tries to offer accurate information about our products, the website, and the portal. However, legal and
          technical details, as well as product descriptions and images, may not always match. Please make sure to
          carefully read all our policies, disclaimers, terms and conditions, as well as product labels, directions,
          expiry dates, and warnings before using or consuming any product. <br />
          If you experience any kind of injury, loss, or health issue due to using the Portal, Website, Store, or
          products related to AKB, we're not responsible, but we'll do our best to assist you.
        </p>

        <p><strong>Governing Law and Jurisdiction</strong></p>
        <p>
          These all terms and conditions of use are governed by Indian laws and regulations. AKB is fully committed to
          complying with all relevant Indian laws, including those related to e-commerce, food safety, data privacy, and
          taxation. <br />
          We are a legally registered entity in India with our GST registration initiated on July 1, 2017, and our GST
          number is 07AEHPG9508E1ZL. We've obtained the FSSAI license number 13315006000132, meeting food safety
          standards. All AKB's social media profiles, website, store addresses, and communication methods are registered
          with valid legal documents. Currently, we operate as a Sole Proprietorship, but please note that government
          guidelines or legal changes may affect these details.
        </p>
      </div>
    </div>

  </section>
</main>