<main class="main">
    <!-- <div class="delayDelivery">
        <h2>Because of the sudden spike in orders, our deliveries might get delayed. We regret the inconvenience caused!</h2>
      </div> -->
    <div class="container paymentBox">
        <div class="PaymentInnerBox">
            <div class="card z-depth-1 mb-3">
                <div class="card-body wallet-body">
                    <div class="payment-header" *ngIf="isWallet">
                        <h5>Add Amount</h5>
                        <p> <i class="mr-1 fa fa-inr"></i>{{ addAmount }}</p>
                    </div>
                    <div class="payment-header" *ngIf="isOrder">
                        <h5>Select Payment Method</h5>
                    </div>
                </div>
            </div>
            <form [formGroup]="paymentModeForm">
                <div class="PayInnerLeft">
                    <!-- <div class="card z-depth-0 " *ngIf="isOrder">
                            <div class="card-body pt-2 pb-0">
                                <div class="option-box">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" value="wallet" id="wallet"
                                            name="mode"
                                            [checked]="isWalletPayment && paymentModeForm.value.mode !='cod'"
                                            [disabled]="balance<=0 || paymentModeForm.value.mode ==='cod'"
                                            (change)="checkMBWallet()">
                                        <label class="custom-control-label custom-control-my-label "
                                            for="wallet">Wallet</label>
                                        <label class="wallet-balance">Wallet Balance: <i class="mr-1 fa fa-inr"></i>{{
                                            balance }} </label>
                                    </div>
                                    <button class="btn btn-custom-primary"
                                        *ngIf="!isWallet && balance>=payableAmount && isWalletPayment"
                                        (click)="payNow('mbwallet')">PAY <i class="mr-1 fa fa-inr"></i>{{ addAmount
                                        }}</button>
                                </div>
                            </div>
                        </div>-->
                    <!-- <div class="card z-depth-0">
                            <div class="card-body pt-2 pb-0">
                                <div class="option-box">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" class="custom-control-input" value="paytm" id="paytm"
                                            name="mode" checked formControlName="mode">
                                        <label class="custom-control-label custom-control-my-label" for="paytm">Paytm
                                            Wallet</label>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    <div class="card z-depth-0 PayModeBox">
                        <div class="card-body">
                            <div class="option-box">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" value="otherWallet"
                                        id="otherWallet" name="mode" formControlName="mode">
                                    <label class="custom-control-label custom-control-my-label " for="otherWallet">Pay
                                        via Wallet</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card z-depth-0 PayModeBox">
                        <div class="card-body">
                            <div class="option-box">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" value="card" id="card" name="mode"
                                        formControlName="mode">
                                    <label class="custom-control-label custom-control-my-label " for="card">Pay via
                                        Credit/Debit Card</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card z-depth-0 PayModeBox">
                        <div class="card-body">
                            <div class="option-box">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" value="netbanking" id="netbanking"
                                        name="mode" formControlName="mode">
                                    <label class="custom-control-label custom-control-my-label " for="netbanking">Pay
                                        via Netbanking </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card z-depth-0 PayModeBox">
                        <div class="card-body">
                            <div class="option-box">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" value="upi" id="bhim" name="mode"
                                        formControlName="mode">
                                    <label class="custom-control-label custom-control-my-label " for="bhim">Pay via
                                        UPI</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card z-depth-0 PayModeBox" *ngIf="isOrder && isCod">
                        <div class="card-body">
                            <div class="option-box">
                                <div class="custom-control custom-radio">
                                    <input type="radio" class="custom-control-input" value="cod" id="cod" name="mode"
                                        formControlName="mode" (change)='changeMbWalletPayment()'>
                                    <label class="custom-control-label custom-control-my-label " for="cod">Cash</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="paymentImg">
                    <img src="../../../../assets/image/paymentImgW.jpg" alt="Razorpay Payment" class="PaymentImgWeb">
                    <img src="../../../../assets/image/paymentImg.jpg" alt="Razorpay Payment" class="PaymentImgApp">
                </div> -->
                </div>
                <div class="PayInnerRight">
                    <p class="totalPayText">Total Amount </p>
                    <p class="totalPayAmt">{{'₹'+ payableAmount }}</p>
                    <div class="PayBtns">
                        <!-- <button class="payNowBtn" *ngIf="paymentModeForm.value.mode==='paytm'"
                            (click)="payNow('paytm')">Pay Now</button> -->
                        <button class="payNowBtn" *ngIf="paymentModeForm.value.mode==='otherWallet'"
                            (click)="payNow('otherWallet')">Pay Now</button>
                        <button class="payNowBtn" *ngIf="paymentModeForm.value.mode==='card'"
                            (click)="payNow('card')">Pay Now</button>
                        <button class="payNowBtn" *ngIf="paymentModeForm.value.mode==='netbanking'"
                            (click)="payNow('netbanking')">Pay Now</button>
                        <button class="payNowBtn" *ngIf="paymentModeForm.value.mode==='upi'" (click)="payNow('upi')">Pay
                            Now</button>
                        <button class="payNowBtn" *ngIf="paymentModeForm.value.mode==='cod'"
                            (click)="payNow('cod')">Place Order</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</main>