<div class="container-fluid product-page">
    <div class="cattegory-slide responsive-page">
      <!-- <app-category-header></app-category-header> -->
      <owl-carousel-o [options]="customOptions">

            <a [routerLink]="[]">
              <!-- <img src="{{apiURL}}public/brand/{{item?.id}}/{{item.image[0]}}" [alt]="item?.name" [title]="item._name" *ngIf='item.image?.length>0'>
                          <img src="../../../assets/image/no-category.png" *ngIf="item.image == null"> -->
              <p>20% Discount</p>
            </a>
       
      </owl-carousel-o>
    </div>
    <div class="row">
        <div class="col-lg-3 fadeIn" data-wow-delay="0.2s">
           <div class="card category side-bar-category">
                <div class="category-header">
                    <!-- <h1 class="pointer">{{branddetails?.name}}</h1> -->
                    <h1 class="pointer">Discount List</h1>
                    <!-- <div class="border-line"></div> -->
                </div>
                <ul class="root-category" >
                  <li (click)="getTagProducts(20);products=[];" class="root-list pointer" >
                    <a routerLink= "/discount_list/20">
                        <span>20% Discount</span>
                    </a>
                  </li>
                </ul>
                <ul class="root-category" >
                    <li (click)="getTagProducts(30);products=[]; " class="root-list pointer" >
                      <a routerLink= "/discount_list/30">
                          <span>30% Discount</span>
                      </a>
                    </li>
                  </ul>
                  <ul class="root-category" >
                    <li (click)="getTagProducts(50);products=[];" class="root-list pointer" >
                      <a routerLink= "/discount_list/50">
                          <span>50% Discount</span>
                      </a>
                    </li>
                  </ul>
            </div>
        </div>
        <div class="col-lg-9" infinite-scroll [infiniteScrollDistance]="scrollDistance"
        [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown()">
            <div class="card category-product">
                <div class="card-header">
                    <div class="row">
                        <div class="col-sm-4 col-5">
                            <!-- <h4 class="card-title"><a>We Found {{products?.length}} items!</a></h4> -->
                        </div>
                        <div class="col-sm-8 col-12 px-sm-3 text-sm-right">
                            <label class="mr-2">Sort By </label>
                            <div class="btn-group" mdbDropdown>
                                <button mdbDropdownToggle type="button" mdbBtn color="white" class="dropdown-toggle custum-dropdown waves-light" mdbWavesEffect>{{ sortArg ? sortSearch+" (" + sortArg+")" :"All products" | capitalize}}</button>
                                <div class="dropdown-menu dropdown-primary custum-dropdown-menu w-100">
                                    <a class="dropdown-item" (click)="sortBy('','')">All</a>
                                    <div class="divider dropdown-divider"></div>
                                    <a class="dropdown-item" (click)="sortBy('low to high','price')">Price (Low to High)</a>
                                    <div class="divider dropdown-divider"></div>
                                    <a class="dropdown-item" (click)="sortBy('high to low','price')">Price (High to Low)</a>
                                    <div class="divider dropdown-divider"></div>
                                    <a class="dropdown-item" (click)="sortBy('low to high','discount')">Discount (Low to high)</a>
                                    <div class="divider dropdown-divider"></div>
                                    <a class="dropdown-item" (click)="sortBy('high to low','discount')">Discount (High to Low)</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body main-categories">
                    <div class="row" *ngIf="products?.length>0">
                        <div class="col-xl-3 col-md-3 col-sm-6 col-6 p-2 productList" *ngFor="let product of products | sort : sortArg : sortSearch">
                            <app-product-card [product]="product"></app-product-card>
                        </div>
                    </div>
                    <div class="row" *ngIf="products?.length==0">
                        <div class="col-xl-3 col-md-3 col-sm-6 col-6"  *ngFor="let sp of skeletonProducts">
                            <app-product-card-skeleton *ngIf="!isProductListAvailable"></app-product-card-skeleton>
                        </div>
                        <div class="col-xl-12 text-center">
                            <img src="../../../assets/image/noProductFound.png" *ngIf="isProductListAvailable">
                            <p style="color: grey;">Sorry, no product found!</p>
                            <a [routerLink]="['/']" class="continue">Shop Now</a>
                        </div>
                    </div>
                    <div class="text-center" *ngIf='isShowLoader'>
                    <!--  <div class="spinner-border text-primary"></div>
                      <h6 class="text-primary">Products Loading.....</h6> -->
                      <img style="width: 200px;" src="../../../assets/image/load.gif">
                    </div>
                    <div class="row" *ngIf='isAllProductEnd'>
                      <div class="card-body text-center mt-4">
                        <div class="noProduct">In your service since - 2005</div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  