<main class="main">
  <script>
    // Function to get UTM parameters
    function getUtmParams() {
      return {
        utm_source: localStorage.getItem("utm_source") || "",
        utm_medium: localStorage.getItem("utm_medium") || "",
        utm_campaign: localStorage.getItem("utm_campaign") || "",
        utm: localStorage.getItem("utm") || "",
      };
    }

    // Retrieve UTM parameters
    var utmParams = getUtmParams();

    // Send the conversion event with UTM parameters
    gtag("event", "conversion", {
      send_to: "AW-938249020/WlKLCLyWsr8DEMmgupkB",
      value: "{{ orderData?.amount }}",
      currency: "INR",
      transaction_id: "{{ orderData?.orderId }}",
      utm_source: utmParams.utm_source,
      utm_medium: utmParams.utm_medium,
      utm_campaign: utmParams.utm_campaign,
      utm: utmParams.utm,
    });
  </script>

  <div>
    <div class="container success margin-top sucessBox">
      <div class="row">
        <div class="col-md-8 offset-md-2" *ngIf="orderData.success">
          <div class="card">
            <div class="card-body pb-5">
              <div class="verify mx-auto">
                <!-- <label class="custom-control overflow-checkbox">
                                  <input type="checkbox" class="overflow-control-input" checked disabled>
                                  <span class="overflow-control-indicator"></span>
                                </label> -->
                <img src="../../../assets/image/sucess.gif" alt="" />
              </div>
              <div class="payment-success">
                <h1
                  class="text-center custom-primary-color h1-responsive text-capitalize"
                >
                  Dear {{ orderData?.name }}, Congratulations... Your order has
                  been Successfully Placed.
                </h1>
              </div>
              <div class="col-md-12 offset-md-3">
                <div *ngIf="orderData.success">
                  <label class="col-6 grey-text"> Order id</label>
                  <label class="col-6">#{{ orderData?.orderId }}</label>
                </div>
                <div *ngIf="orderData.success">
                  <label class="col-6 grey-text">Time Slot</label>
                  <label class="col-6"
                    >{{ orderData?.deliveryTime.slot }}
                  </label>
                </div>
                <div *ngIf="orderData.success">
                  <label class="col-6 grey-text">Order Delivery Date</label>
                  <label class="col-6 grey-text">{{
                    orderData?.deliveryDate | date
                  }}</label>
                </div>
                <div *ngIf="orderData.success">
                  <label class="col-6 grey-text">Order Date</label>
                  <label class="col-6 grey-text">{{
                    orderData?.date | date
                  }}</label>
                </div>
                <div *ngIf="orderData.success">
                  <label class="col-6 grey-text">Payment Mode</label>
                  <label class="col-6 grey-text text-capitalize">{{
                    orderData?.paymentMode
                  }}</label>
                </div>

                <div *ngIf="orderData.success">
                  <label class="col-6 grey-text">Total Order</label>
                  <label class="col-6">₹{{ orderData?.amount }}</label>
                </div>
              </div>
              <div class="read-more-box">
                <button
                  type="button"
                  class="btn read-more-btn btn-rounded waves-effect waves-light"
                  *ngIf="orderData.success && orderData.paymentMode != 'cod'"
                  [routerLink]="['/order/detail/' + orderData?._id]"
                >
                  Order Details
                </button>
                <button
                  type="button"
                  class="btn read-more-btn btn-rounded waves-effect waves-light"
                  [routerLink]="['/order']"
                >
                  Check order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<div class="text-center" *ngIf="isShowInProgress">
  <img src="../../../assets/image/in-progress.gif" alt="" />
</div>
