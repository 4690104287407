import { Component, OnInit, OnDestroy } from '@angular/core';
import { BannersService, ProductService, CategoryService, LocationService, SeoService, DeliverySlotService } from 'src/app/_service/index';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { title } from 'process';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  //******Top-Banner*Start*****/

  MainBanner: CarouselModule = {
    loop: true,
    autoplay:true,
    autoplayTimeout:7000,
    autoplayHoverPause:false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText:["<div class='prev-slide'><i class='fa fa-angle-left'></i></div>","<div class='next-slide'><i class='fa fa-angle-right'></i></div>"],
    responsive: {
      0: {
        items: 1,
      }
    },
    nav: true
  }  


  MainBanner1Web = [
    //{id: 9, link:"tags/66bb34f2ebfb5a647bd27686", img: "https://images.aapkabazar.co/banners/top_banner/web/rakshaBandhan.jpeg"},
    //{id: 10, link:"tags/66bb38b8ebfb5a647bd2769f", img: "https://images.aapkabazar.co/banners/top_banner/web/IndependenceDay.jpeg"},
    {id: 7, link:"745/pd/kissan-mixed-fruit-jam-700-gm", img: "https://images.aapkabazar.co/banners/top_banner/web/redbullW.webp"},
    {id: 8, link:"badamalmond-500-gm-popular-quality/p/61eaecaf2da73066de6b3433", img: "https://images.aapkabazar.co/banners/top_banner/web/badam500.webp"},
    {id: 2, link:"haldi-powder-200-gm-/p/61eaecae2da73066de6b2fd2", img: "https://images.aapkabazar.co/banners/top_banner/web/Haldi1kg.jpg", alt:""},
    //{id: 1, link:"badamalmond-1-kg-popular-quality/p/63c632a2ee406b9ad568ed73", img: "https://images.aapkabazar.co/banners/top_banner/web/badamP.jpg", alt:""},
    {id: 7, link:"7255/pd/cremica-pizza-pasta-sauce-1-kg-pouch", img: "https://images.aapkabazar.co/banners/top_banner/web/pps.jpg"},
    //{id: 22, link:"tags/66966be486420228f4c24412", img: "https://images.aapkabazar.co/banners/top_banner/web/cornitos.jpg"},
    {id: 3, link:"3733/pd/dabur-anmol-gold-pure-coconut-oil-600-ml", img: "https://images.aapkabazar.co/banners/top_banner/web/coconutOils.jpg"}, 
    // {id: 5, link:"india-gate-feast-rozana-basmati-rice-5-kg/p/61eaecae2da73066de6b324a", img: "https://images.aapkabazar.co/banners/top_banner/web/wbt.jpg"},
    {id: 11, link:"2177/pd/cremica-chocolate-syrup-625-gm", img: "https://images.aapkabazar.co/banners/top_banner/web/crem.jpg"},
    // {id: 16, link:"m-p-radhika-raman-atta-10-kg-/p/61eaecaf2da73066de6b36c4", img: "https://images.aapkabazar.co/banners/top_banner/web/cornitos.jpg"},
    // {id: 8, link:"mr-white-washing-powder-10-kg/p/6367c208bceb99c13221fddd", img: "https://images.aapkabazar.co/banners/top_banner/web/MrWhite.jpg", alt:""},
    //{id: 4, link:"kissan-fresh-tomato-ketchup-850-gm/p/61ed212c742f0368e149a9e9", img: "https://images.aapkabazar.co/banners/top_banner/web/Kissan.jpg", alt:""},
    // {id: 9, link:"kajucashew-1-kg-popular-quality/p/61eaecb02da73066de6b39e5", img: "https://images.aapkabazar.co/banners/top_banner/web/kajuP.jpg", alt:""},
    // {id: 11, link:"tata-tea-gold-500-gm/p/61ed212c742f0368e149aa86", img: "https://images.aapkabazar.co/banners/top_banner/web/TataTeaGold500.jpg", alt:""},
    // {id: 14, link:"nestle-everyday-ghee-1-ltr/p/61ed212c742f0368e149aace", img: "https://images.aapkabazar.co/banners/top_banner/web/everyday.jpg"},
    // {id: 15, link:"keya-penne-pasta-400-gm-/p/61f4db1c742f0368e14a5827", img: "https://images.aapkabazar.co/banners/top_banner/web/pasta500gm-W.jpg"},
    // {id: 17, link:"gaia-extra-light-olive-oil-1-ltr-buy-1-get-1-free/p/61eaecaf2da73066de6b35fc", img: "https://images.aapkabazar.co/banners/top_banner/web/OliveOil.jpg"},
    // {id: 18, link:"chacha-ji-banana-chips-500-gm/p/61eaecaf2da73066de6b3508", img: "https://images.aapkabazar.co/banners/top_banner/web/banachips.jpg"},
    // {id: 19, link:"soya-badichunks-200gm--50-off/p/61eaecaf2da73066de6b330b", img: "https://images.aapkabazar.co/banners/top_banner/web/SoyaBadi.jpg", alt:""},
    // {id: 20, link:"trust-sugar-5-kg--1-kg-free-/p/61eaecaf2da73066de6b388f", img: "https://images.aapkabazar.co/banners/top_banner/web/trustSugar.jpg"},
    //{id: 21, link:"walnutakhrot-giri-500-gm-popular-quality/p/61eaecb12da73066de6b46fc", img: "https://images.aapkabazar.co/banners/top_banner/web/walnut500.webp"},
    // {id: 21, link:"anjeerfigs-1-kg-premium-quality/p/61eaecb12da73066de6b4628", img: "https://images.aapkabazar.co/banners/top_banner/web/anjeer.jpg"},
    
  ];
    
  MainBanner1App = [ 
    {id: 7, link:"red-bull-energy-drink-350-ml/p/65d6e3e955e9ac3e1a583e6d", img: "https://images.aapkabazar.co/banners/top_banner/app/redbullM.webp"},
    {id: 7, link:"pepsodent-gumcare-expert-protection-tooth-paste-260-gm/p/61eaecae2da73066de6b318f", img: "https://images.aapkabazar.co/banners/top_banner/app/pepsodant260.webp"},
    {id: 22, link:"tags/65b39f4a7f5015580e35df3b", img: "https://images.aapkabazar.co/banners/top_banner/app/TataS.webp"},
    //{id: 1, link:"badamalmond-1-kg-popular-quality/p/63c632a2ee406b9ad568ed73", img: "https://images.aapkabazar.co/banners/top_banner/app/badamP.jpg", alt:""},  
    {id: 2, link:"haldi-powder-200-gm-/p/61eaecae2da73066de6b2fd2", img: "https://images.aapkabazar.co/banners/top_banner/app/Haldi1kg.jpg", alt:""},
    {id: 3, link:"ramdev-super-hing-100-gm/p/61f4db1c742f0368e14a5829", img: "https://images.aapkabazar.co/banners/top_banner/app/ramdev.jpg"},  
    //{id: 4, link:"kissan-fresh-tomato-ketchup-850-gm/p/61ed212c742f0368e149a9e9", img: "https://images.aapkabazar.co/banners/top_banner/app/Kissan.webp", alt:""},
    {id: 5, link:"india-gate-feast-rozana-basmati-rice-5-kg/p/61eaecae2da73066de6b324a", img: "https://images.aapkabazar.co/banners/top_banner/app/IndiaGateRozana.webp"},
    {id: 6, link:"hindustan-aluminium-foil-1-kg/p/61eaecaf2da73066de6b36cf", img: "https://images.aapkabazar.co/banners/top_banner/app/foil-m.jpg"},
    // {id: 7, link:"more-light-power-stain-removal-4-kg/p/61eaecb22da73066de6b4964", img: "https://images.aapkabazar.co/banners/top_banner/app/MoreLight.jpg", alt:""},
    // {id: 8, link:"mr-white-washing-powder-10-kg/p/6367c208bceb99c13221fddd", img: "https://images.aapkabazar.co/banners/top_banner/app/MrWhite.jpg", alt:""},
    // {id: 9, link:"kajucashew-1-kg-popular-quality/p/61eaecb02da73066de6b39e5", img: "https://images.aapkabazar.co/banners/top_banner/app/kajuP.jpg", alt:""},
    // {id: 11, link:"tata-tea-gold-500-gm/p/61ed212c742f0368e149aa86", img: "https://images.aapkabazar.co/banners/top_banner/app/TataTeaGold500.jpg", alt:""},       
    // {id: 14, link:"nestle-everyday-ghee-1-ltr/p/61ed212c742f0368e149aace", img: "https://images.aapkabazar.co/banners/top_banner/app/everyday.jpg"},   
    // {id: 15, link:"keya-penne-pasta-400-gm-/p/61f4db1c742f0368e14a5827", img: "https://images.aapkabazar.co/banners/top_banner/app/pasta500gm-M.jpg"},
    {id: 2, link:"aashirvaad-svasti-pure-cow-ghee-1-ltr/p/61eaecaf2da73066de6b3833", img: "https://images.aapkabazar.co/banners/top_banner/app/AashirvaadGhee.webp", alt:""},
    {id: 16, link:"m-p-radhika-raman-atta-10-kg-/p/61eaecaf2da73066de6b36c4", img: "https://images.aapkabazar.co/banners/top_banner/app/atta.webp"},
    {id: 8, link:"badamalmond-500-gm-popular-quality/p/61eaecaf2da73066de6b3433", img: "https://images.aapkabazar.co/banners/top_banner/app/badam500.webp"},
    // {id: 18, link:"chacha-ji-banana-chips-500-gm/p/61eaecaf2da73066de6b3508", img: "https://images.aapkabazar.co/banners/top_banner/app/banachips.jpg"},
    {id: 19, link:"soya-badichunks-200gm--50-off/p/61eaecaf2da73066de6b330b", img: "https://images.aapkabazar.co/banners/top_banner/app/SoyaBadi.webp", alt:""},
    // {id: 20, link:"trust-sugar-5-kg--1-kg-free-/p/61eaecaf2da73066de6b388f", img: "https://images.aapkabazar.co/banners/top_banner/app/trustSugar.jpg"},
    //{id: 21, link:"walnutakhrot-giri-500-gm-popular-quality/p/61eaecb12da73066de6b46fc", img: "https://images.aapkabazar.co/banners/top_banner/app/walnut500.webp"},
    // {id: 22, link:"anjeerfigs-1-kg-premium-quality/p/61eaecb12da73066de6b4628", img: "https://images.aapkabazar.co/banners/top_banner/app/anjeer.jpg"},
    // {id: 23, link:"tags/64a932f1e739d07afb781d4a", img: "https://images.aapkabazar.co/banners/top_banner/app/akb-cookies.jpg"},
  ];
   
  MainBanner2Web = [
    //{id: 1, link:"mor-pankhi-herbal-green-gulal-80-gm/p/65eff0561e8d45720977f017", img: "https://images.aapkabazar.co/banners/top_banner/web/holi.jpg", alt:""}, 
  ];

  MainBanner2App = [     
    //{id: 1, link:"dhampure-sugar-1-kg/p/61eaecae2da73066de6b3087", img: "https://images.aapkabazar.co/slider/dhamour1kg.webp", alt:""}, 
    {id: 1, link:"the-choice-home-double-bedsheet-230250-cm-with-2-pillow-cover-4669-cm/p/6622619e658b6ddfa2af1880", img: "https://images.aapkabazar.co/slider/bedsheet.webp", alt:""}, 
    {id: 2, link:"hamdard-roohafza-750-ml/p/61eaecae2da73066de6b315b", img: "https://images.aapkabazar.co/slider/roohafza.webp", alt:""},     
  ];
 




//******Top-Banner*End*****/


  customOptions: CarouselModule = {
    loop: true,
    autoplay:true,
    autoplayTimeout:7000,
    autoplayHoverPause:false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    margin:20,
    stagePadding: 40,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
        margin:10
      },
      767: {items: 1},
      940: {items: 3}
    },
    nav: true
  }  
  CategoryBanner = [
    {id: 1, link:"badam-roasted-salted-2-pc250-gm-/p/61eaecb12da73066de6b4328", img: "https://images.aapkabazar.co/banners/top_banner/app/RostedBadam.webp"},
    {id: 1, link:"soya-badichunks-200-gm-/p/61eaecaf2da73066de6b330b", img: "https://images.aapkabazar.co/slider/soyaBadi.webp"},
    //{id: 1, link:"haldi-powder-200-gm-super-quality/p/61eaecae2da73066de6b2fd2", img: "https://images.aapkabazar.co/slider/haldi.webp"},
    {id: 2, link:"dhania-powder-200-gm-popular-quality/p/61eaecae2da73066de6b319b", img: "https://images.aapkabazar.co/slider/dhaniya.webp"},
    {id: 12, link:"rajma-chitra-1-kg-super-quality-/p/61eaecaf2da73066de6b3530", img: "https://images.aapkabazar.co/slider/RajmaChitra.webp"},
    {id: 2, link:"del-monte-pomace-olive-oil-1-ltr/p/61eaecb12da73066de6b4647", img: "https://images.aapkabazar.co/slider/DelMonte1L.webp"},
    {id: 2, link:"16144/pd/leonardo-pomace-olive-oil-1-ltr", img: "https://images.aapkabazar.co/slider/leonardo.webp", alt:""},
    {id: 3, link:"pumpkin-seeds-200-gm/p/61eaecb12da73066de6b462c", img: "https://images.aapkabazar.co/banners/top_banner/app/pumpkin200-M.webp", alt:""},
    {id: 5, link:"elaichi-greencardamom-40-gm/p/61eaecae2da73066de6b3288", img: "https://images.aapkabazar.co/slider/GreenElaichii40.webp"},
    {id: 6, link:"kajucashew-500-gm-popular-quality/p/61eaecae2da73066de6b326e", img: "https://images.aapkabazar.co/Offers/Kaju1.webp", alt:""},
    {id: 4, link:"gauri-mogra-basmati-rice-10-kg/p/61eaecb12da73066de6b461e", img: "https://images.aapkabazar.co/slider/gauriMogra.webp", alt:""},
    {id: 5, link:"gauri-mini-mogra-basmati-rice-10-kg/p/61eaecaf2da73066de6b349d", img: "https://images.aapkabazar.co/slider/mini-mogra.webp", alt:""},
  ];
  SliderOwl4 = [ 
    {id: 1, link:"tetley-tea-bag-100-pcs-200-gm/p/61eaecae2da73066de6b325c", img: "https://images.aapkabazar.co/slider/teabag.webp", alt:""},
    {id: 1, link:"hersheys-syrup--chocolate-623-gm-bottle/p/61eaecae2da73066de6b30e9", img: "https://images.aapkabazar.co/slider/syrep.webp", alt:""},
    {id: 2, link:"nescafe-classic-coffee-pouch-200-gm/p/61eaecb12da73066de6b440f", img: "https://images.aapkabazar.co/slider/nescafe200.webp"},
    //{id: 3, link:"pure-tender-coconut-water-200-ml/p/6575544046a6421ec341d67d", img: "https://images.aapkabazar.co/slider/PureTender.webp", alt:""},
    {id: 13, link:"bournvita-1-kg-jar-pack/p/61eaecaf2da73066de6b37f9", img: "https://images.aapkabazar.co/slider/bournvita-m.webp"},
    {id: 10, link:"premium-tea-1-kg--akb/p/61ed212c742f0368e149aa8c", img: "https://images.aapkabazar.co/slider/AKBTea.webp", alt:""},
    {id: 4, link:"nescafe-gold-blend-coffee-200-gm-/p/61eaecb02da73066de6b3fb8", img: "https://images.aapkabazar.co/slider/nescafe_Gold_200.webp", alt:""},
    {id: 5, link:"tea-coffee-cup-100-pcs/p/61eaecb12da73066de6b40cd", img: "https://images.aapkabazar.co/slider/Cup.webp", alt:""},
  ];
  SliderOwl5 = [
    {id: 1, link:"wow-apple-cider-vinegar-shampoo-1-ltr-/p/621b08d6894e9d20c0643485", img: "https://images.aapkabazar.co/Offers/wowSkin.webp", alt:""},
    {id: 1, link:"clinic-plus-strong--long-shampoo-355-ml/p/61eaecb12da73066de6b410a", img: "https://images.aapkabazar.co/slider/ClinicShampoo.webp", alt:""},
    {id: 4, link:"gillette-shaving-foam-regular-418-gm/p/61eaecae2da73066de6b302f", img: "https://images.aapkabazar.co/Offers/gillateShaving.webp", alt:""},
    {id: 3, link:"scuderia-ferrari-black-men-perfume-125-ml/p/62b006ea6fa1082d5d91b1ad", img: "https://images.aapkabazar.co/slider/MenPerfume.webp", alt:""},
    //{id: 3, link:"nivea-fresh-natural-deodorant-150-ml/p/61ed212c742f0368e149aa4e", img: "https://images.aapkabazar.co/slider/MenDeodorant.webp", alt:""},
    // {id: 4, link:"sebamed-everyday-shampoo-200-ml/p/61eaecae2da73066de6b31f1", img: "https://images.aapkabazar.co/slider/sebaMad.webp", alt:""},
    {id: 4, link:"pears-pure-and-gentle-soap-set-4125-gm/p/61eaecaf2da73066de6b377d", img: "https://images.aapkabazar.co/slider/pears.webp", alt:""},
    {id: 4, link:"himalaya-purifying-neem-face-wash-200-ml-buy-1-get-1-free-/p/61eaecb02da73066de6b3f2c", img: "https://images.aapkabazar.co/slider/HimalyaFaceWash.webp", alt:""},
    {id: 10, link:"stayfree-secure-xl-40-pads-/p/636f444313b5857e4ed75b67", img: "https://images.aapkabazar.co/slider/stayfree.webp"},
  ];
  LatestOffers = [  
    {id: 1, link:"chana-dal-1-kg-premium-quality/p/61eaecb12da73066de6b4612", img: "https://images.aapkabazar.co/slider/ChanaDalPre1.webp"},
    //{id: 1, link:"fortune-kachi-ghani-mustard-oil-5-ltr/p/61eaecae2da73066de6b3257", img: "https://images.aapkabazar.co/slider/FortuneMustard.webp"},
    //{id: 1, link:"fruit-jump-apple-drink-1-ltr/p/626cd03460964e0e35227fa4", img: "https://images.aapkabazar.co/slider/fruit-jump.jpg"},
    {id: 1, link:"badamalmond-mamra-giri-250-gm/p/61eaecae2da73066de6b3272", img: "https://images.aapkabazar.co/slider/mamra.webp"},
    {id: 2, link:"daawat-rozana-super-rice-5-kg/p/61eaecaf2da73066de6b36fd", img: "https://images.aapkabazar.co/slider/dawatroz5.webp"},
    //{id: 3, link:"cadbury-celebrations-gift-pack-1788-gm/p/61eaecb12da73066de6b42e9", img: "https://images.aapkabazar.co/slider/celebration.webp", alt:""},
    {id: 4, link:"rusk-dehraduni-special-1-kg/p/61eaecb12da73066de6b4644", img: "https://images.aapkabazar.co/Offers/mediumBanner6.webp", alt:""},  
    {id: 5, link:"mcvities-butter-cookies-1752-gm-buy-1-get-1-free/p/64fd5d8db1503b1984eba721", img: "https://images.aapkabazar.co/banners/top_banner/app/butterMC.webp", alt:""},
    {id: 6, link:"mcvities-cashew-cookies-1752-gm-buy-1-get-1-free/p/64fd5d8db1503b1984eba720", img: "https://images.aapkabazar.co/banners/top_banner/app/cashewMC.webp", alt:""},
    {id: 4, link:"chana-white-super-quality-1kg/p/61eaecaf2da73066de6b34a9", img: "https://images.aapkabazar.co/slider/ChanaWhite.webp", alt:""},
    {id: 7, link:"gauri-premium-basmati-rice-10-kg/p/61eaecb12da73066de6b4620", img: "https://images.aapkabazar.co/slider/gaurip10.webp", alt:""},
    // //{id: 9, link:"borges-indian-cooking-olive-oil-2-ltr/p/61eaecaf2da73066de6b36ec", img: "https://images.aapkabazar.co/slider/borges.jpg", alt:""},
    // //{id: 10, link:"tata-soulfull-millet-muesli-crunchy-500-gm-almonds--raisins/p/63ca3255ee406b9ad568f2d4", img: "https://images.aapkabazar.co/slider/museliCur.jpg"}, 
    // //{id: 11, link:"-chyawanprash-special-500-gm-buy-1-get-4-free/p/63933cc52138ee78c751b446", img: "https://images.aapkabazar.co/slider/chanwnprash.jpeg"}, 
    {id: 11, link:"kishmishraisins-500-gm-popular-quality/p/61eaecb12da73066de6b46f6", img: "https://images.aapkabazar.co/banners/top_banner/app/kismis.webp"}, 
  ];
  SliderOwl9 = [
    {id: 1, link:"sagar-phool-jhadu--broom-1-pc/p/61ed212c742f0368e149a9f2", img: "https://images.aapkabazar.co/slider/jhadu.webp", alt:""},
    {id: 2, link:"spotzero-smart-spin-mop-1-unit/p/61eaecb02da73066de6b3c94", img: "https://images.aapkabazar.co/slider/slider4.webp", alt:""},
    {id: 3, link:"use2-hand-wash-5-ltr/p/61eaecb02da73066de6b3f30", img: "https://images.aapkabazar.co/slider/handwash5ltr.webp", alt:""},
    {id: 3, link:"mylo-care-gentle-baby-80-wipes/p/65bb37696a78748b5acb9317", img: "https://images.aapkabazar.co/slider/babyWipes.webp", alt:""},
    //{id: 4, link:"lifebuoy-total-hand-wash-750-ml-refill-buy-1-get-1-free/p/61eaecae2da73066de6b3042", img: "https://images.aapkabazar.co/slider/lifebouy.webp", alt:""},
    {id: 5, link:"yoga-mat-1-pc/p/61eaecaf2da73066de6b365d", img: "https://images.aapkabazar.co/slider/yogamat.webp", alt:""},
    {id: 6, link:"mosquito-racket-1pcs/p/61ed212c742f0368e149aa56", img: "https://images.aapkabazar.co/slider/rachet.webp", alt:""},
    {id: 7, link:"potato-chips-225-gm-/p/61fe2575e6a622b6ac99616a", img: "https://images.aapkabazar.co/banners/top_banner/app/potatochips.webp", alt:""},
    {id: 8, link:"blueberries-200-gm/p/61eaecb12da73066de6b4632", img: "https://images.aapkabazar.co/slider/bluebari.webp", alt:""},
    // {id: 9, link:"gopal-ji-thandai-500gm/p/61eaecaf2da73066de6b365e", img: "https://images.aapkabazar.co/slider/thandai.jpg", alt:""},
    //{id: 10, link:"glass-bottle-750-ml-/p/61f0dee1742f0368e14a3b0c", img: "https://images.aapkabazar.co/slider/GlassBottel.webp", alt:""},
    // {id: 11, link:"sesa-moisturisig-hand-wash-750-ml/p/61ed212c742f0368e149a912", img: "https://images.aapkabazar.co/slider/sesa.jpg", alt:""},
  ];
  HotDeals2 = [
    {id: 1, link:"tops-tomato-ketchup-950-gm-50-off-/p/61ed212c742f0368e149a940", img: "https://images.aapkabazar.co/slider/tomatoketchup.webp", alt:""},
    //{id: 2, link:"kelloggs-chocos-120-kg-pouch/p/61eaecaf2da73066de6b35f0", img: "https://images.aapkabazar.co/slider/chocos.webp", alt:""},
    {id: 7, link:"anand-rajasthani-chana-masala-papad-200-gm/p/61eaecb12da73066de6b41a2", img: "https://images.aapkabazar.co/banners/top_banner/app/masalaPapad.webp"},
    {id: 4, link:"kanodia-kolhu-brand-mustard-oil-5-ltr/p/61eaecb02da73066de6b3cb4", img: "https://images.aapkabazar.co/slider/kanodia.webp", alt:""},
    {id: 3, link:"lal-mirch-powder-200-gm-super-quality/p/61eaecaf2da73066de6b352b", img: "https://images.aapkabazar.co/slider/lalMirch.webp", alt:""},
    // //{id: 5, link:"india-gate-basmati-tibar-rice-5-kg/p/61eaecb12da73066de6b4613", img: "https://images.aapkabazar.co/Offers/indiarice2.jpg", alt:""},
    {id: 6, link:"khadi-sweet-almond-oil-210-ml/p/61eaecaf2da73066de6b342c", img: "https://images.aapkabazar.co/slider/KhadiAlmondOil.webp", alt:""},
   // {id: 7, link:"jasmine--rose-washing-powder-7-kg--3-kg/p/61eaecaf2da73066de6b35e8", img: "https://images.aapkabazar.co/slider/tide10.webp"},
    {id: 8, link:"fortune-kacchi-ghani-mustard-oil-5-ltr/p/61eaecae2da73066de6b3257", img: "https://images.aapkabazar.co/slider/fortMusterd5.webp", alt:""},    
    {id: 9, link:"macroni-500-gm--buy-1-get-1-free/p/61eaecaf2da73066de6b34f7", img: "https://images.aapkabazar.co/slider/macroni.webp"},
    // {id: 10, link:"lotte-choco-pie-12-pcs-buy-2-get-1-free/p/61eaecaf2da73066de6b3656", img: "https://images.aapkabazar.co/slider/chocoPie.jpg", alt:""},
  ];


  customOptions1: CarouselModule = {
    loop: true,
    autoplay:true,
    autoplayTimeout:7000,
    autoplayHoverPause:false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    margin:20,
    stagePadding: 50,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2,
        margin:8,
        stagePadding: 20,
      },
      767: {items: 2},
      940: {items: 4}
    },
    nav: true
  }  
  SliderOwlCat = [    
    //{id: 1, link:"tata-tea-agni-500gm-x-2/p/61eaecae2da73066de6b3210", img: "https://images.aapkabazar.co/Offers/tatatea.webp", alt:""},
    //{id: 2, link:"glucond-pure-glucose--tangy-orange-200-gm/p/61eaecae2da73066de6b31a6", img: "https://images.aapkabazar.co/Offers/Glucon-d.webp", alt:""},
    {id: 2, link:"tags/6274f81160964e0e35228c85", img: "https://images.aapkabazar.co/Offers/fresca.webp", alt:""},
    {id: 2, link:"bagrrys-nutty-fruity-with-seeds--berries-muesli-425-gm-buy-1-get-1-free/p/65054171b1503b1984ebbba6", img: "https://images.aapkabazar.co/Offers/bagrry.webp", alt:""},
    //{id: 2, link:"minute-maid-vita-punch-mixed-fruit-juice-1-ltr-buy-1-get-1-free/p/65c716a54ae225c569d127c5", img: "https://images.aapkabazar.co/Offers/minuteMaid.webp", alt:""},
    {id: 2, link:"del-monte-classic-blend-tomato-ketchup-900-gm/p/628f10e560964e0e3522efc3", img: "https://images.aapkabazar.co/Offers/DelMonteTomato.webp", alt:""},
    {id: 3, link:"india-gate-tibar-rice-1-kg/p/61eaecae2da73066de6b3298", img: "https://images.aapkabazar.co/Offers/tibarrice.webp", alt:""},
    {id: 4, link:"hamdard-roghan-badam-shirin-oil-100-ml/p/61eaecb12da73066de6b461c", img: "https://images.aapkabazar.co/Offers/roganBadam.webp", alt:""},
  ];
  HotDeals = [
    {id: 1, link:"phool-makhanefox-nut-100-gm-/p/61eaecb12da73066de6b462d", img: "https://images.aapkabazar.co/Offers/makhane.webp", alt:""},
    {id: 2, link:"saffola-oats-1-kg-/p/61eaecae2da73066de6b32cc", img: "https://images.aapkabazar.co/Offers/SaffolaOats.webp", alt:""},
    {id: 3, link:"bagrrys-original--healthier-corn-flakes-800-gm-buy-1-get-1-free/p/62298668f8dbd3675c44b8e5", img: "https://images.aapkabazar.co/Offers/bagrrys.webp", alt:""},
    {id: 2, link:"borges-indian-cooking-olive-oil-5-ltr/p/620dd730b412a1629988619a", img: "https://images.aapkabazar.co/Offers/borges.webp", alt:""},
    {id: 2, link:"jivo-canola-oil-5-ltr--1-ltr-jivo-extra-light-olive-oil-free/p/61eaecaf2da73066de6b34f6", img: "https://images.aapkabazar.co/Offers/jivo5l.webp", alt:""},
    {id: 2, link:"dalda-groundnut-oil-5-ltr/p/61eaecb12da73066de6b4300", img: "https://images.aapkabazar.co/Offers/daldaOil5l.webp", alt:""},
    //{id: 2, link:"cheetos-flamin-hot-crunchy-cheese-flavoured-sancks-2268-gm/p/65ab628e5e271d8225538693", img: "https://images.aapkabazar.co/Offers/cheetos.webp", alt:""},
    //{id: 2, link:"comfort-rose-fresh-fabric-conditioner-imported-2-ltr/p/65aa78a1a9d51ad69cba1e30", img: "https://images.aapkabazar.co/Offers/comfort.webp", alt:""},
    {id: 4, link:"gee-ess-besan-500-gm/p/61eaecaf2da73066de6b36b0", img: "https://images.aapkabazar.co/Offers/geebesan.webp", alt:""},
  ];
  FoodGrain = [
    {id: 1, link:"gauri-delight-basmati-rice-5-kg/p/61eaecaf2da73066de6b36c3", img: "https://images.aapkabazar.co/Offers/offerbn12.webp", alt:""},
    {id: 2, link:"khadi-india-pure-honey-1-kg/p/61ed30e9742f0368e149bbd1", img: "https://images.aapkabazar.co/Offers/khadiPure.webp", alt:""},
    {id: 3, link:"malka-kali-super-quality-1kg-pack/p/61eaecaf2da73066de6b34bf", img: "https://images.aapkabazar.co/Offers/fg1.webp", alt:""},
    //{id: 4, link:"patanjali-cow-ghee-1-ltr/p/61eaecb02da73066de6b3e50", img: "https://images.aapkabazar.co/Offers/cowghee.webp", alt:""},
    {id: 17, link:"gaia-extra-light-olive-oil-1-ltr-buy-1-get-1-free/p/61eaecaf2da73066de6b35fc", img: "https://images.aapkabazar.co/Offers/OliveOil.webp"},
    {id: 6, link:"maa-kansal-fresh-poha-500-gm/p/6520112c2c4963d29b1af1a6", img: "https://images.aapkabazar.co/Offers/kansalPoha.webp", alt:""},
  ];
  SliderOwl6 = [
    {id: 1, link:"pril-lime-grease-fighter-dishwash-liquid-2-ltr/p/61eaecaf2da73066de6b33db", img: "https://images.aapkabazar.co/Offers/offerbn6.webp", alt:""},
    {id: 2, link:"gainda-white-phenyle-5-ltr/p/61ed212c742f0368e149aa60", img: "https://images.aapkabazar.co/Offers/offerbn7.webp", alt:""},
    {id: 3, link:"black-polythene-17x23-m-40-pcs-/p/61eaecaf2da73066de6b345e", img: "https://images.aapkabazar.co/Offers/cl2.webp", alt:""},
    {id: 4, link:"use2-dishwasher-5-ltr/p/61eaecaf2da73066de6b33dc", img: "https://images.aapkabazar.co/Offers/use2.webp", alt:""},
    {id: 4, link:"rin-detergent-powder-7-kg/p/61ed212c742f0368e149aaaf", img: "https://images.aapkabazar.co/Offers/RinSurf.webp", alt:""}
  ];



  customOptions2: CarouselModule = {
    loop: true,
    autoplay:true,
    autoplayTimeout:7000,
    autoplayHoverPause:false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    margin:20,
    stagePadding: 50,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2,
        margin:8,
        stagePadding: 20,
      },
      767: {items: 2},
      940: {items: 4}
    },
    nav: true
  }  
  CrazyDeals = [   
    {id: 1, link:"unibic-cashew-badam-cookies-500-gm-/p/61eaecaf2da73066de6b3944", img: "https://images.aapkabazar.co/Offers/UnibicCash.webp", alt:""},
    {id: 2, link:"vim-liquid-lemons-dishwash-gel-750-ml-bottle/p/61ed212c742f0368e149aa36", img: "https://images.aapkabazar.co/Offers/offerbn8.webp", alt:""},
    {id: 3, link:"lizol-floral-floor-cleaner-2-ltr/p/61eaecb12da73066de6b451d", img: "https://images.aapkabazar.co/Offers/offerbn9.webp", alt:""},   
    {id: 4, link:"kissan-fresh-tomato-ketchup-850-gm/p/61ed212c742f0368e149a9e9", img: "https://images.aapkabazar.co/Offers/ketchup.webp", alt:""},   
    {id: 5, link:"mcvities-digestive-biscuits-9591-gm/p/61ed30e9742f0368e149bbd9", img: "https://images.aapkabazar.co/Offers/McVities.webp", alt:""},
    
  ];

 




  cityDataSubscription: Subscription;
  categoryDataSubscription: Subscription;
  mostViewProduct: any = [];
  mostBuyProduct: any = [];
  latestProduct: any = [];
  city: any = {};
  rootCategories: any = [];
  banners: any = [];
  bannerResponse: boolean = false;
  bannerResponses: boolean = false;
  productResponse: boolean = false;
  footerCategorySkeleton: any = [{}, {}, {}, {}, {}, {}, {}, {}];
  deliveryCharges: any = {}
  param:any={}
  seoData: any = {
    metaKeywords: ['Grocery store, Groceries, Grocery Shopping, Indian grocery store, online grocery store, grocery store in Dwarka, grocery store near me home delivery, grocery store Delhi, best online largest supermarket in New Delhi, grocery supermarket in Delhi, grocery home delivery near me, aap ka bazar ,AKB online grocery store,  order grocery online, best online grocery store, online grocery India, Premium Gauri Basmati Rice, online grocery, online grocery shopping, online grocery store, online supermarket, online grocery shopping India, buy groceries online, food shopping online, Online Supermarket, Free Delivery, Great Offers, Best Prices'],
    metaDescription: 'Aap Ka Bazar is the best online grocery store and supermarket for all your daily needs in Delhi. Online shopping for groceries and household items is now simple.',
    metaTitle: 'Online Grocery Store and Supermarket in Delhi - Aapkabazar.co'
  };
  brand: any = [];
  apiURL=environment.apiUrl;
  constructor(
    private _PS: ProductService,
    private _CS: CategoryService,
    private _LS: LocationService,
    private _BS: BannersService,
    private _SEO: SeoService,
    public _DSS: DeliverySlotService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getTags();
    this.getBrand();
    this.getBanners();
    this._SEO.updateSeo(this.seoData);

    this.cityDataSubscription = this._LS.cityDataSubject.subscribe(data => {
      this.city = data;
      // if (this.city.id != null && data != null) {
      // this.productAvailableByCityId();
      // }
      // this.getBanners()
    });
    this.categoryDataSubscription = this._CS.rootCategoryDataSubject.subscribe(data => {
      this.rootCategories = data;
    })
    //this.foodGrainss();
    //this.Beveragesss();
    //this.Hygieness();
    //this.Snacksss();
    //this.Exoticss();
    //this.Babycaress();
    //this.Frozenss();
    //this.Cleaningss();
  }



  BrandWeb() {
    var x = document.getElementById("BrandBox");
    if (x.style.height === "auto") {
      x.style.height = "266px";
    } else {
      x.style.height = "auto";
    }
  }

  BrandMob() {
    var x = document.getElementById("BrandBox1");
    if (x.style.height === "auto") {
      x.style.height = "288px";
    } else {
      x.style.height = "auto";
    }
  }
  


  getBrand() {
    let responseData;
    this._PS.getBrand().subscribe(data => {
      responseData = data;
      this.brand= responseData.brand;
      //console.log(this.brand);
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message);
    })
  }
  tags:any
  getTags() {
    let responseData;
    this._PS.getTeg().subscribe(data => {
      responseData = data;
      this.tags= responseData.data;
      //console.log(data.data);
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message);
    })
  }


  getBanners() {
    let responseData;
    if(this._LS.city.id){
      this.param.cityId=this._LS.city.id
    }else{
      this.param.cityId="619f219d26d9ad0f34102dd2";
    }
    this._BS.getBanners(this.param).subscribe(data => {
      responseData = data;
      this._BS.banners = responseData.banners ? responseData.banners : [];
      this.banners = this._BS.banners;
      setTimeout( () =>
        this.bannerResponse = true
      , 500 );

    }, (error) => {
      this.bannerResponse = true;
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message);
    })
  }

  productAvailableByCityId() {
    let responseData;
    let params = {};
    if(this.city.id){
      params["cityId"] = this.city.id
    }
    this._PS.productAvailableByCityId(params).subscribe(data => {
      responseData = data;
      this.productResponse = true;
      this.latestProduct = responseData.products[0].latestProduct ? responseData.products[0].latestProduct : [];
      this.mostViewProduct = responseData.products[0].mostViewProduct ? responseData.products[0].mostViewProduct : [];
      this.mostBuyProduct = responseData.mostBuyProduct ? responseData.mostBuyProduct : [];
    }, (error) => {
      this.productResponse = true;
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message);
    })
  }

  ngOnDestroy() {
    this.cityDataSubscription.unsubscribe();
    this.categoryDataSubscription.unsubscribe();
  }

  limit=environment.pagainationLimit;
  start:any=0;
  params:any={}







  foodGrains:any
  foodGrain:any
  foodGrainproducts:any
  foodGraincategoryDetails:any
  foodGrainss() {
    this.foodGrain='61b18e41c5aed278ca11c069';
    if(this._LS.city.id){
      this.params.cityId=this._LS.city.id
    }
    let responseData;
    this._CS.getSubCategory(this.foodGrain,this.params).subscribe(data => {
      responseData = data;
      //console.log(responseData);

      this.foodGrains = responseData.category;
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })

    let responseDataa;
    let params = {
      categoryId: this.foodGrain
    }
    if (this.city.id) {
      params["cityId"] = this.city.id;
    }
    params["start"] = this.start;
    params["limit"] = this.limit;
    this._PS.getCategoryProducts(params).subscribe(data => {
      responseDataa = data;
      this.productResponse = true;
       this.foodGrainproducts = responseDataa.products ? responseDataa.products : [];
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
    this._CS.getCategoryDetails(this.foodGrain,this.params).subscribe(data => {
       this.foodGraincategoryDetails= data;
      //console.log(this.foodGraincategoryDetails.category.images);
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
  }




  Beveragess:any
  Beverages:any
  Beveragesproducts:any
  BeveragescategoryDetails:any
  Beveragesss() {
    this.Beverages='61b19199c5aed278ca11c0a8';
    if(this._LS.city.id){
      this.params.cityId=this._LS.city.id
    }
    let responseData;
    this._CS.getSubCategory(this.Beverages,this.params).subscribe(data => {
      responseData = data;
      //console.log(responseData);

      this.Beverages = responseData.category;
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })

    let responseDataa;
    let params = {
      categoryId: this.Beverages
    }
    if (this.city.id) {
      params["cityId"] = this.city.id;
    }
    params["start"] = this.start;
    params["limit"] = this.limit;
    this._PS.getCategoryProducts(params).subscribe(data => {
      responseDataa = data;
       this.Beveragesproducts = responseDataa.products ? responseDataa.products : [];
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
    this._CS.getCategoryDetails(this.Beverages,this.params).subscribe(data => {
       this.BeveragescategoryDetails= data;
      //console.log(this.BeveragescategoryDetails.category.images);
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
  }



  Hygienes:any
  Hygiene:any
  Hygieneproducts:any
  HygienecategoryDetails:any
  Hygieness() {
    this.Hygiene='61b19150c5aed278ca11c096';
    if(this._LS.city.id){
      this.params.cityId=this._LS.city.id
    }
    let responseData;
    this._CS.getSubCategory(this.Hygiene,this.params).subscribe(data => {
      responseData = data;
      //console.log(responseData);

      this.Hygiene = responseData.category;
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })

    let responseDataa;
    let params = {
      categoryId: this.Hygiene
    }
    if (this.city.id) {
      params["cityId"] = this.city.id;
    }
    params["start"] = this.start;
    params["limit"] = this.limit;
    this._PS.getCategoryProducts(params).subscribe(data => {
      responseDataa = data;
       this.Hygieneproducts = responseDataa.products ? responseDataa.products : [];
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
    this._CS.getCategoryDetails(this.Hygiene,this.params).subscribe(data => {
       this.HygienecategoryDetails= data;
       //console.log(this.HygienecategoryDetails.category.images);
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
  }



  Snackss:any
  Snacks:any
  Snacksproducts:any
  SnackscategoryDetails:any
  Snacksss() {
    this.Snacks='61b19188c5aed278ca11c09f';
    if(this._LS.city.id){
      this.params.cityId=this._LS.city.id
    }
    let responseData;
    this._CS.getSubCategory(this.Snacks,this.params).subscribe(data => {
      responseData = data;
      //console.log(responseData);

      this.Snacks = responseData.category;
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })

    let responseDataa;
    let params = {
      categoryId: this.Snacks
    }
    if (this.city.id) {
      params["cityId"] = this.city.id;
    }
    params["start"] = this.start;
    params["limit"] = this.limit;
    this._PS.getCategoryProducts(params).subscribe(data => {
      responseDataa = data;
       this.Snacksproducts = responseDataa.products ? responseDataa.products : [];
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
    this._CS.getCategoryDetails(this.Snacks,this.params).subscribe(data => {
       this.SnackscategoryDetails= data;
       //console.log(this.SnackscategoryDetails.category.images);
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
  }



  Exotics:any
  Exotic:any
  Exoticproducts:any
  ExoticcategoryDetails:any
  Exoticss() {
    this.Exotic='61b191bac5aed278ca11c0b1';
    if(this._LS.city.id){
      this.params.cityId=this._LS.city.id
    }
    let responseData;
    this._CS.getSubCategory(this.Exotic,this.params).subscribe(data => {
      responseData = data;
      //console.log(responseData);

      this.Exotics = responseData.category;
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })

    let responseDataa;
    let params = {
      categoryId: this.Exotic
    }
    if (this.city.id) {
      params["cityId"] = this.city.id;
    }
    params["start"] = this.start;
    params["limit"] = this.limit;
    this._PS.getCategoryProducts(params).subscribe(data => {
      responseDataa = data;
       this.Exoticproducts = responseDataa.products ? responseDataa.products : [];
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
    this._CS.getCategoryDetails(this.Exotic,this.params).subscribe(data => {
       this.ExoticcategoryDetails= data;
       //console.log(this.ExoticcategoryDetails.category.images);
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
  }






  Babycares:any
  Babycare:any
  Babycareproducts:any
  BabycarecategoryDetails:any
  Babycaress() {
    this.Babycare='61b191d3c5aed278ca11c0ba';
    if(this._LS.city.id){
      this.params.cityId=this._LS.city.id
    }
    let responseData;
    this._CS.getSubCategory(this.Babycare,this.params).subscribe(data => {
      responseData = data;
      //console.log(responseData);

      this.Babycares = responseData.category;
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })

    let responseDataa;
    let params = {
      categoryId: this.Babycare
    }
    if (this.city.id) {
      params["cityId"] = this.city.id;
    }
    params["start"] = this.start;
    params["limit"] = this.limit;
    this._PS.getCategoryProducts(params).subscribe(data => {
      responseDataa = data;
       this.Babycareproducts = responseDataa.products ? responseDataa.products : [];
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
    this._CS.getCategoryDetails(this.Babycare,this.params).subscribe(data => {
       this.BabycarecategoryDetails= data;
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
  }


  Frozens:any
  Frozen:any
  Frozenproducts:any
  FrozencategoryDetails:any
  Frozenss() {
    this.Frozen='61b18e68c5aed278ca11c072';
    if(this._LS.city.id){
      this.params.cityId=this._LS.city.id
    }
    let responseData;
    this._CS.getSubCategory(this.Frozen,this.params).subscribe(data => {
      responseData = data;
      //console.log(responseData);

      this.Frozens = responseData.category;
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })

    let responseDataa;
    let params = {
      categoryId: this.Frozen
    }
    if (this.city.id) {
      params["cityId"] = this.city.id;
    }
    params["start"] = this.start;
    params["limit"] = this.limit;
    this._PS.getCategoryProducts(params).subscribe(data => {
      responseDataa = data;
       this.Frozenproducts = responseDataa.products ? responseDataa.products : [];
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
    this._CS.getCategoryDetails(this.Frozen,this.params).subscribe(data => {
       this.FrozencategoryDetails= data;
       //console.log(this.FrozencategoryDetails.category.images);
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
  }




  Cleanings:any
  Cleaning:any
  Cleaningproducts:any
  CleaningcategoryDetails:any
  Cleaningss() {
    this.Cleaning='61b1912dc5aed278ca11c08d';
    if(this._LS.city.id){
      this.params.cityId=this._LS.city.id
    }
    let responseData;
    this._CS.getSubCategory(this.Cleaning,this.params).subscribe(data => {
      responseData = data;
      //console.log(responseData);

      this.Cleanings = responseData.category;
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })

    let responseDataa;
    let params = {
      categoryId: this.Cleaning
    }
    if (this.city.id) {
      params["cityId"] = this.city.id;
    }
    params["start"] = this.start;
    params["limit"] = this.limit;
    this._PS.getCategoryProducts(params).subscribe(data => {
      responseDataa = data;
       this.Cleaningproducts = responseDataa.products ? responseDataa.products : [];
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
    this._CS.getCategoryDetails(this.Cleaning,this.params).subscribe(data => {
       this.CleaningcategoryDetails= data;
       //console.log(this.CleaningcategoryDetails.category.images);
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
    })
  }


  
}
