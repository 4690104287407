
    <div class="card pointer categorie ">
        <div class="card-body categories-body p-1 ">
            <div class="row align-items-center ">
                <div class="col-4 col-sm-3 ">
                    <img class="img-fluid ml-2">
                </div>
                <div class="col-8 ">
                    <div class="my-4 mx-3 ">
                        <h5></h5>
                        <p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
