<div class="container main">
  <h1 class="head-other-page">Conditions of Sale</h1>
  <div class="border-line"></div>

    <p class="p1"><span class="s1" style="color: #5f5b5b;"><strong>Please read these conditions carefully before placing an order or subscription for any products with the Sellers on the aapkabazar.co. These conditions signify your agreement to be bound by these conditions.</strong></span></p>
    <p class="p1"><span class="s1" style="color: #5f5b5b;">In addition, when you use any current or future aapkabazar.co service (eg : Wishlist or Marketplace ), you will also be subject to the terms, guidelines and conditions applicable to that service ("Terms"). If these Conditions of Sale are inconsistent with such Terms, the Terms will control.</span></p>
    <h3 class="p2"><span class="s1" style="color: #5f5b5b;">Conditions Relating to the Sale of Products, subscription and one-time order&nbsp;</span></h3>
    <p class="p3"><span class="s1" style="color: #5f5b5b;">This section deals with conditions relating to the sale of products on the website by us to you.</span></p>
    <p class="p3"><span class="s1" style="color: #5f5b5b;">In one-time order User or customer can see the date and time in the Aapkabazar portal and the delivery timing will within two hours, the schedule delivery will be Monday to Saturday and on the other hand in subscription-based customer can see the calendar. The user can minimum subscribe for 15 days only.</span></p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Our Contract</span></h3>
    <p class="p1"><span class="s1" style="color: #5f5b5b;">When the order is being placed by you to purchase a product from us, you will receive an e-mail confirmation receipt of your order and containing the details of your order (the "Order Confirmation E-mail"). The Order Confirmation E-mail is an acknowledgement that we have received your order, and does not confirm acceptance of your offer to buy the product(s) ordered. We only accept your offer, and conclude the contract of sale for the product ordered by you, when the product is ready to get it dispatched to you and a notification and message confirmation is sent to you that the product has been dispatched to you (the "Dispatch Confirmation message).</span></p>
    <p class="p1"><span class="s1" style="color: #5f5b5b;">Your contract is with aapkabazar.co and you confirm that the product(s) ordered by you are purchased for your internal / personal purpose and not for re-sale or business purpose. You authorize us to declare and provide declaration to any governmental authority on your behalf stating the aforesaid purpose of the products ordered by you on the website.</span></p>
    <p class="p1"><span class="s1" style="color: #5f5b5b;">You can cancel your order for a product at no cost any time before we send the Dispatch Confirmation E-mail relating to that product.</span></p>
    <p class="p2"><span class="s1" style="color: #5f5b5b;">Please note that we sell products only in quantities which correspond to the typical needs of an average household. This applies both to the number of products ordered within a single order and the placing of several orders for the same product where the individual orders comprise a quantity typical for a normal household.</span></p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Return And Refund Policy</span></h3>
    <p class="p1"><span class="s1" style="color: #5f5b5b;">Please review our Returns Policy, which applies to products sold by us.</span></p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Pricing and Availability Of Products</span></h3>
    <p class="p1"><span class="s1" style="color: #5f5b5b;">We list information of availability of the products sold by us on Aapkabazar.co.com, including on each product information page. Beyond what we say on that page or otherwise on the website, we cannot be more specific about availability. Please note that dispatch estimates are just that. They are not guaranteed dispatch times and should not be relied upon as such. As we process your order, you will be informed by e-mail if any products you order turn out to be unavailable.</span></p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Health &amp; Safety</span></h3>
    <p class="p1"><span class="s1" style="color: #5f5b5b;">By purchasing any product from our Aapkabazar.co Website, you acknowledge that you have read and understood our privacy policy Guidelines. For more details, please review our Pricing policy and our Availability Guide, both of which apply to products ordered from us.</span></p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Taxes</span></h3>
    <p class="p2"><span class="s1" style="color: #5f5b5b;">You shall be responsible for payment of all fees/costs/charges associated with the purchase of products from us and you agree to bear any and all applicable taxes including but not limited to VAT/CST, service tax, GST, duties, and cesses etc.</span></p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Children</span></h3>
    <p class="p2"><span class="s1" style="color: #5f5b5b;">Use of aapkabazar.co is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you may purchase only with the involvement of a parent or guardian.</span></p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Communication With Us</span></h3>
    <p class="p2"><span class="s1" style="color: #5f5b5b;">When you send e-mails to us, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the website or by any other mode of communication. For contractual purposes, you consent to receive communications including SMS, e-mails or phone calls from us with respect to your order.</span></p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Losses</span></h3>
    <p class="p2"><span class="s1" style="color: #5f5b5b;">We will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is not reasonably foreseeable to both you and us when a contract for the sale of goods by us to you was formed.</span></p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Alteration or Amendments to the Conditions</span></h3>
    <p class="p3"><span class="s1" style="color: #5f5b5b;">We reserve the right to make changes to our policies, and these Conditions of Sale at any time. You will be subject to the policies and Conditions of Sale in force at the time you order goods from us, unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.</span></p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Damage and Other issues</span></h3>
    <p class="p2"><span class="s1" style="color: #5f5b5b;">Aapkabazar.co will try to satisfy our customer but in any case if the user face any issue related to the product damage or mismanaged of the product or missing of order, if user receive the different item except he ordered then vendor will take responsibility of this or if user<span class="Apple-converted-space">&nbsp; </span>found any differences in<span class="Apple-converted-space">&nbsp; </span>prices then company will take legal action against vendor.</span></p>
    <p class="p3">&nbsp;</p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Help and Support</span></h3>
    <p class="p4"><span class="s1" style="color: #5f5b5b;">If the user face any issues in product like price differences, expired, damage , missing and wrong item etc so, in case of one time order then user have to follow the process of first selecting the item and he will report an<span class="Apple-converted-space">&nbsp; </span>issue then user would have to select the item of that particular date, he would have to fill the mail details, next for further he will do the message what the problem he has faced. For subscription customer will select the relevant subscription and he will select the date then will choose the store name/subscription ID and he would have to fill the mail information and finally he will send the message related to the problem.</span></p>
    <h3 class="p5"><span class="s1" style="color: #5f5b5b;">Events beyond our reasonable control</span></h3>
    <p class="p4"><span class="s1" style="color: #5f5b5b;">We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control. This condition does not affect your statutory rights.</span></p>
    <h3 class="p5"><span class="s1" style="color: #5f5b5b;">Waiver</span></h3>
    <p class="p2"><span class="s1" style="color: #5f5b5b;">If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.</span></p>
    <h3 class="p1"><span class="s1" style="color: #5f5b5b;">Pricing</span></h3>
    <p class="p2"><span class="s1" style="color: #5f5b5b;">All the products listed on the Site will be sold at MRP unless otherwise specified. The prices mentioned at the time of ordering will be the prices charged on the date of the delivery. Although prices of most of the products do not fluctuate on a daily basis but some of the commodities and fresh food prices do change on a daily basis. In case the prices are higher or lower on the date of delivery not additional charges will be collected or refunded as the case may be at the time of the delivery of the order.</span></p>
</div>
