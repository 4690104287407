import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-offer',
  templateUrl: './gauri.component.html',
  styleUrls: ['./gauri.component.scss']
})
export class gauriComponent implements OnInit {



  constructor() { }

  ngOnInit(): void {
  }

}
