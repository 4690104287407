<div class="container margin-top">
    <div class="seller-banner">
      <img class="bg-white d-block mx-auto" src="/assets/image/mb_seller.png">
      <div class="container pt-4 pb-5">
        <h1>Basic information required to become a seller at MorningBag.com</h1>
        <p class="text-light text-center">To start selling with MorningBag.com, you need to sign up on the MorningBag
          seller website (www.morningbag.seller.com). The seller can sign up by providing certain basic information about
          himself. Certain basic information which is required during register is listed.</p>
      </div>
      <div class="banner-bottom">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-3 mb-2">
              <div class="card">
                <div class="card-body">
                  <img class="img-fluid w-100" src="assets/image/step-1.png">
                </div>
              </div>
              <div class="card-footer bg-white border-0 text-center">
                <h3>Step-1</h3>
                <p>Add your address & Business Detail</p>
              </div>
  
            </div>
            <div class="col-md-3 mb-2">
              <div class="card">
                <div class="card-body">
                  <img class="img-fluid w-100" src="assets/image/step-2.jpg">
                </div>
              </div>
              <div class="card-footer bg-white border-0 text-center">
                <h3>Step-2</h3>
                <p>Add your Bank Detail</p>
              </div>
            </div>
  
            <div class="col-md-3 mb-2">
              <div class="card">
                <div class="card-body">
                  <img class="img-fluid w-100" src="assets/image/step-3.jpg">
                </div>
              </div>
              <div class="card-footer bg-white border-0 text-center">
                <h3>Step-3</h3>
                <p>Add your Service Area</p>
              </div>
            </div>
  
            <div class="col-md-3 mb-2">
              <div class="card">
                <div class="card-body">
                  <img class="img-fluid w-100" src="assets/image/step-4.jpg">
                </div>
              </div>
              <div class="card-footer bg-white border-0 text-center">
                <h3>Step-4</h3>
                <p>Add your Product</p>
              </div>
            </div>
          </div>
          <div class="text-center mt-4">
            <a href="https://test.vendor.morningbag.com/" target="blank" class="btn btn-primary ">Start Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  