import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LocationService,UserService, ProductService, BagService } from 'src/app/_service';
import { Subscription } from 'rxjs';
import { SeoService } from 'src/app/_service/seo.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  
  owlOptions: any = {
    loop: true,
    singleItem: true,
    URLhashListener: true,
    slideSpeed: 300,
    startPosition: 'URLHash',
    paginationSpeed: 400,
    items: 1,
  };
  owlImageOptions: any = {
    loop: true,
    mouseDrag: true,
    stagePadding: 2,
    margin: 2,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 50,
    items: 5,
    slideBy: 5,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 4
      },
      740: {
        items: 5
      },
      940: {
        items: 6
      }
    },
    nav: true
  };
  productOwlOption: any = {
    loop: true,
    stagePadding: 5,
    margin: 0,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 50,
    items: 5,
    slideBy: 5,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1.2
      },
      400: {
        items: 2.2
      },
      740: {
        items: 3.2
      },
      940: {
        items: 5
      }
    },
    nav: true
  };
  @ViewChild('bulkOrder') bulkOrder: TemplateRef<any>;

  bulkOrderRef: BsModalRef;
  subscription: Subscription;
  product: any = {};
  city: any = {};
  params: any = {};
  bag: any;
 mostViewProducts: any = [];

  bulkOrderConfig = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "mb-city-modal"
  };
  bulkOrderForm: any;
  isShowSpinner: boolean = false

  constructor(
    private route: ActivatedRoute,
    private _LS: LocationService,
    private _PS: ProductService,
    private modalService: BsModalService,
    private _BS: BagService,
    private _SEO: SeoService,
    private toastr: ToastrService,
    private _US: UserService,
  ) { }


  DiscriptionBtn() {
    var x = document.getElementById("DiscriptionBox");
    if (x.style.height === "auto") {
      x.style.height = "140px";
    } else {
      x.style.height = "auto";
    }
  }

  ngOnInit() {
    this.bag = this._BS.productBag;
    this.city = this._LS.city;
    this.route.params.subscribe((params: Params) => {
      this.params = params;
      this.subscription = this._LS.cityDataSubject.subscribe(data => {
        // if (this.city.id != null && data != null) {
          this.getProduct(this.params.id);
        // }
       this.getRecentlyViewProduct();
      });
    })

    this.bulkOrderForm = new FormGroup({
      "productId": new FormControl(this.params.id),
      "sellerId": new FormControl('617d2982bd68c94d0bcb9200'),
      "name": new FormControl('', Validators.required),
      "phoneNo": new FormControl('', Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])),
      "quantity": new FormControl('', Validators.required),
      "address": new FormControl('', Validators.required),
    })
  }

  orderBulkProduct() {
    if(this.bulkOrderForm.valid){
        let responseData;
        this.isShowSpinner = true
        this._US.bulkOrder(this.bulkOrderForm.value).subscribe(data => {
          this.isShowSpinner = false
          responseData = data;
          if (responseData.success) {
            this.toastr.success(responseData.message);
            this.closeLoginModal();
          }else {
            this.toastr.warning(responseData.message)
          }
        }, (error) => {
          this.isShowSpinner = false
          this.toastr.error(error.error && error.error.message ? error.error.message : error.message)
        })
    }else{
       this.bulkOrderForm.markAllAsTouched()
    }
  }
  showBulkOrder() {
    this.bulkOrderRef = this.modalService.show(this.bulkOrder, this.bulkOrderConfig);
  }
  closeLoginModal() {
    this.bulkOrderRef.hide();
  }

  productResponse: boolean = false;
  getProduct(productId: any) {
    let responseData;
    let params = {
      productId: productId
    }
    if (this.city.id) {
      params["cityId"] = this.city.id
    }
    this._PS.getProductsById(params).subscribe(data => {
      responseData = data;
      this.product = responseData.product;
      if(this.product){
        this._SEO.updateSeo(this.product.seo);
        this.productResponse = true
      }
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message);
    })
  }

  incrementQuantity() {
    this._BS.incrementQuantity = this.product;
  }

  decrementQuantity() {
    this._BS.decrementQuantity = this.product;
  }

  getRecentlyViewProduct() {
    let params = {};
    if (this.city.id) {
      params["cityId"] = this.city.id
    }
    this._PS.productAvailableByCityId(params).subscribe((data: any) => {
     this.mostViewProducts = data.products[0].mostViewProduct;
    }, (error) => {
      this.toastr.error(error.error && error.error.message ? error.error.message : error.message);
    })
  }
}
