<div class="main akbOffers">
  <div class="akbOffersHead">Weekly Offers</div>
  <div class="container">    
    <div class="LatestOffer">
      <img src="https://images.aapkabazar.co/pamphlet/pamphlet20may.jpg">
      <div class="LatestOfferText">
        <h2>Latest Weekly Offers</h2>
        <h3>Valid 20/May/23 to Till Stock Last </h3>
        <a href="https://images.aapkabazar.co/pamphlet/pamphlet20may.pdf" target="_blank">View Offers </a>
      </div>
    </div>
    <div class="LatestOffer">
      <img src="https://images.aapkabazar.co/pamphlet/pamphlet13may.jpg">
      <div class="LatestOfferText">
        <h2>Previous Weekly Offers</h2>
        <h3>Valid 13/May/23 to to 19/may/23 </h3>
        <a href="https://images.aapkabazar.co/pamphlet/pamphlet13may.pdf" target="_blank">View Offers </a>
      </div>
    </div>
    <div class="LatestOffer">
      <img src="https://images.aapkabazar.co/pamphlet/pamphlet06may.jpg">
      <div class="LatestOfferText">
        <h2>Previous Weekly Offers</h2>
        <h3>Valid 06/May/23 to to 12/may/23 </h3>
        <a href="https://images.aapkabazar.co/pamphlet/pamphlet06may.pdf" target="_blank">View Offers </a>
      </div>
    </div>
    <div class="LatestOffer">
      <img src="https://images.aapkabazar.co/pamphlet/pamphlet29apr.jpg">
      <div class="LatestOfferText">
        <h2>Previous Weekly Offers</h2>
        <h3>Valid 29/Apr/23 to 05/may/23 </h3>
        <a href="https://images.aapkabazar.co/pamphlet/pamphlet29apr.pdf" target="_blank">View Offers </a>
      </div>
    </div>
    <div class="LatestOffer">
      <img src="https://images.aapkabazar.co/pamphlet/pamphlet27apr.jpg">
      <div class="LatestOfferText">
        <h2>Previous Weekly Offers</h2>
        <h3>Valid 22/Apr/23 to 27/Apr/23 </h3>
        <a href="https://images.aapkabazar.co/pamphlet/pamphlet27apr.pdf" target="_blank">View Offers </a>
      </div>
    </div>
  </div>
</div>