<main class="main">
  <section class="staticSection container">
    <div class="row align-items-center py-3 px-4">
      <span class="mainHeadding">CONTACT US</span>
    </div>
    <div class="ContactLeft">
      <h4>Contact Details</h4>
      <ul>
        <li>
          <div class="StoreImg">
            <img src="https://images.aapkabazar.co/sector07.jpg">
          </div>
          <h5>Aap Ka Bazar - Sector 7, Dwarka</h5>
          <p>
            <b>Address:</b><br />
            D-455, Palam Ext., Sector 7, Dwarka, New Delhi, (India) - 110075
          </p>
          <p>
            <b>Contact Number & Email:</b><br />
            +91-9150030303, +91-9958309650, &nbsp; <a href="mailto:support@aapkabazar.co">support@aapkabazar.co</a>
          </p>          
        </li>

        <li>
          <div class="StoreImg">
            <img src="https://images.aapkabazar.co/sector12.jpg">
          </div>
          <h5>Aap Ka Bazar - Sector 12, Dwarka</h5>
          <p>
            <b>Address:</b><br />
            12, Pocket 3, Sector 12, Dwarka, New Delhi, (India) - 110075
          </p>
          <p>
            <b>Contact Number:</b><br />
            +91-9150040404
          </p>          
        </li>

        <li>
          <div class="StoreImg">
            <img src="https://images.aapkabazar.co/mahavir.jpg">
          </div>
          <h5>Aap Ka Bazar - Mahavir Enclave, Palam</h5>
          <p>
            <b>Address:</b><br />
            RZ F1, Dabri-Palam Road, Near- Sulabh International, New Delhi, (India) - 110045
          </p>
          <p>
            <b>Contact Number:</b><br />
            011-43053964, +91-8595156858
          </p>          
        </li>

        <li>
          <div class="StoreImg">
            <img src="https://images.aapkabazar.co/sector23.jpg">
          </div>
          <h5>Aap Ka Bazar - Sector 23, Dwarka</h5>
          <p>
            <b>Address:</b><br />
            Plot-39, Sector 23A, Aditya Enclave, Near- Anytime Fitness GYM, Dwarka, New Delhi, (India) - 110075
          </p>
          <p>
            <b>Contact Number:</b><br />
            011-41631081, +91-9911039110
          </p>          
        </li>

      </ul>
    </div>
    <div class="ContactRight">
      <h4>Contact Form</h4>
      <div class="ContactForm">
        <ul class="ContactList">
          <li>
            <input type="text" placeholder="Enter Name">
          </li>
          <li>
            <input type="text" placeholder="Enter Email (Optional)">
          </li>
          <li>
            <input type="text" placeholder="Enter Mobile">
          </li>
          <li>
            <textarea placeholder="Write your query"></textarea>
          </li>
          <li>
            <button type="button">Submit</button>
          </li>
        </ul>
      </div>
    </div>

  </section>

</main>