import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sellwithus',
  templateUrl: './sellwithus.component.html',
  styleUrls: ['./sellwithus.component.scss']
})
export class SellwithusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
