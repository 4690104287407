import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-category-skeleton',
  templateUrl: './footer-category-skeleton.component.html',
  styleUrls: ['./footer-category-skeleton.component.scss']
})
export class FooterCategorySkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
