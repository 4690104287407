<div class="main">
  <div class="container offerBoxMob">
    <div class="bg-img">
      <img src="assets/image/left-head.png">
      <h2 class="head-2">This Week Offers</h2>
      <img src="assets/image/right-head.png">
    </div>

    <section class="offers">
      <div class="SliderOwl1">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of SliderOwl1">
            <ng-template carouselSlide [id]="slide.id">
              <a [routerLink]="slide.link">
                <img [src]="slide.img" [alt]="slide.alt">
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </section>

    <ul class="OfferBox4">       
      <!-- <li class="wt100">
            src="https://images.aapkabazar.co/banners/top_banner/app/toilet-M.jpg" alt=""></a>
      </li> -->
      <!-- <li class="wt100">
            src="https://images.aapkabazar.co/slider/MrWhiteSurf.jpeg" alt=""></a>
      </li> -->
      <li>
        <a [routerLink]="['/walnutakhrot-giri-500-gm-popular-quality/p/61eaecb12da73066de6b46fc']"><img
            src="https://images.aapkabazar.co/Offers/akhrot500.webp" alt=""></a>
      </li>
      <li>
        <a [routerLink]="['/mohuns-corn-flakes-900-gm/p/61eaecb02da73066de6b398d']"><img
            src="https://images.aapkabazar.co/Offers/mohan1kg.webp" alt=""></a>
      </li>
      <li>
        <a [routerLink]="['/haribo-chamallows-pink--white-imp-140-gm-/p/61eaecb02da73066de6b3975']"><img
            src="https://images.aapkabazar.co/Offers/haribo.webp" alt=""></a>
      </li>
      <li>
        <a [routerLink]="['/colgate-plax-freshmint-splash-mouthwash-250-ml/p/65d89bbe58534f9cc936f5e9']"><img
            src="https://images.aapkabazar.co/Offers/colgate250ml.webp" alt=""></a>
      </li>

      <li>
        <a [routerLink]="['/funfoods-veg-mayonnaise-original-875-gm/p/61eaecaf2da73066de6b37ef']"><img
            src="https://images.aapkabazar.co/Offers/funfood%20veg%20mayo.jpg" alt=""></a>
      </li>
      <li>
        <a [routerLink]="['/del-monte-eggless-mayo--900-gm/p/61eaecaf2da73066de6b38c8']"><img
            src="https://images.aapkabazar.co/Offers/offerbn3.jpg" alt=""></a>
      </li>

      <!-- <li class="wt100">
        <a [routerLink]="['/wintex-toilet-roll-pack-of-10-pcs/p/61ed212c742f0368e149aa57']"><img
            src="https://images.aapkabazar.co/slider/wintex-m.jpg" alt=""></a>
      </li> -->
      <li>
        <a [routerLink]="['/cheetos-flamin-hot-crunchy-cheese-flavoured-sancks-2268-gm/p/65ab628e5e271d8225538693']"><img
            src="https://images.aapkabazar.co/Offers/cheetos.webp" alt=""></a>
      </li>
      <li>
        <a [routerLink]="['/popcorn-200gm--50-off/p/61eaecaf2da73066de6b3364']"><img
            src="https://images.aapkabazar.co/Offers/sc2.jpg" alt=""></a>
      </li>
      <li class="wt100">
        <a [routerLink]="['/beauty--cosmetics/c/66334062b016ae8ec620691f']"><img
            src="https://images.aapkabazar.co/Offers/mediumBanner3.jpg" alt=""></a>
      </li>
      <li>
        <a [routerLink]="['/masalas-oils--dry-fruits/masalas--spices/c/66309ebc8d438b7d0647dfac/66309eef8d438b7d0647dfc0']"><img
            src="https://images.aapkabazar.co/Offers/FoodNew4.jpg" alt=""></a>
      </li>
      <li>
        <a [routerLink]="['/veeba-veg-eggless-mayonnaise-875-gm/p/61eaecb02da73066de6b3aad']"><img
            src="https://images.aapkabazar.co/Offers/veeba_eggless.webp" alt=""></a>
      </li>
      <!-- <li>
        <a [routerLink]="['/epigamia-milk-shake-rose-180-ml--tetra-pack/p/624689251e8e405e33fa6034']"><img
            src="https://images.aapkabazar.co/Offers/epigamiaRose.jpg" alt=""></a>
      </li> -->
      
      <!-- <li class="wt100">
        <a [routerLink]="['/pedigree-puppy-chicken--milk-10-kg-/p/61eaecae2da73066de6b3250']"><img
            src="https://images.aapkabazar.co/slider/pedigeree.jpg" alt=""></a>
      </li> -->
      <li class="wt100">
        <a [routerLink]="['/detergents--cleaning/c/6633444bb016ae8ec6206a84']"><img
            src="https://images.aapkabazar.co/Offers/mediumBanner17.jpg" alt=""></a>
      </li>

      <!-- <li class="wt100">
        <a [routerLink]="['/kissan-jam-mixed-fruit-700-gm/p/61eaecae2da73066de6b32b2']"><img
            src="https://images.aapkabazar.co/slider/kishanJam.webp" alt=""></a>
      </li> -->
      <!-- <li class="wt100">
        <a [routerLink]="['/wimpex-toilet-roll-pack-of-10-pcs/p/61ed212c742f0368e149aa35']"><img
            src="https://images.aapkabazar.co/Offers/toiletRoll.jpg" alt=""></a>
      </li>       -->

      <!-- <li class="wt100">
        <a [routerLink]="['/fortune-besan-500-kg-x-2/p/61eaecb12da73066de6b43d0']"><img
            src="https://images.aapkabazar.co/slider/fortuneBesan.jpg" alt=""></a>
      </li> -->
      <li class="wt100">
        <a [routerLink]="['/fortune-soyabean-refined-oil-3-ltr-1-ltr-x-3-/p/61eaecb22da73066de6b4836']"><img
            src="https://images.aapkabazar.co/slider/soya3ltr.jpg" alt=""></a>
      </li>
      <li class="wt100">
        <a [routerLink]="['/surf-excel-easy-wash-blue-5-kg/p/61eaecb22da73066de6b491a']"><img
            src="https://images.aapkabazar.co/Offers/surfexcel.webp" alt=""></a>
      </li>  
      <!-- <li class="wt100">
        <a [routerLink]="['/real-activ-100-tender-coconut-water-200-ml/p/65f431f1baf9ea0efaabb61c']"><img
            src="https://images.aapkabazar.co/Offers/realwater1l.webp" alt=""></a>
      </li>   -->
      <li class="wt100">
        <a [routerLink]="['/nandos-garlic-medium-periperi-sauce-250-gm/p/65c320b57d1bf0eaa04c846c']"><img
            src="https://images.aapkabazar.co/Offers/nando250ml.webp" alt=""></a>
      </li>  
      <li class="wt100">
        <a [routerLink]="['/prime-lemon-lime-hydration-drink-500-ml/p/65d6e3e955e9ac3e1a583e71']"><img
            src="https://images.aapkabazar.co/slider/prime-drink.webp" alt=""></a>
      </li>  
      <li class="wt100">
        <a [routerLink]="['/samyang-buldak-3x-spicy-hot-chicken-flavour-noodles-140-gm/p/65cdab5f65eddc58fb7b9be2']"><img
            src="https://images.aapkabazar.co/slider/samyangbuldak.webp" alt=""></a>
      </li>  
      <li class="wt100">
        <a [routerLink]="['/pringles-ketchup-chips-165-gm/p/65bf32619ac917ea93e9ad15']"><img
            src="https://images.aapkabazar.co/Offers/pringles.webp" alt=""></a>
      </li>
      <li class="wt100">
        <a [routerLink]="['/monster-ultra-golden-pineapple-energy-drink-500-ml/p/6606c3d656fcc348a90eed28']"><img
            src="https://images.aapkabazar.co/Offers/monster.webp" alt=""></a>
      </li>  
      <li class="wt100">
        <a [routerLink]="['/abbies-classic-pancake-syrup-710-ml/p/65c320b57d1bf0eaa04c8472']"><img
            src="https://images.aapkabazar.co/Offers/pancakesyrup.webp" alt=""></a>
      </li>  
      
    </ul>
  </div>
</div>
