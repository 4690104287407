<div class="main DownloadApp">
  <div class="container">
    <div class="mainAppBox">
      <div class="boxLeft">
        <div class="AppHead">
          <a href="/"><img class="logoDownload" src="../../../assets/image/logoDownload.png"></a>
        </div>
        <h2>
          <strong>Wholesale</strong><br />
          <span>Grocery</span>
        </h2>
        <h3>
          <strong>Order Online</strong><br />
          <span>Download the app</span>
        </h3>
        <ul class="AppList">
          <li>
            <a href="https://play.google.com/store/apps/details?id=co.aapkabazar.app"><img
                src="../../../assets/image/Play-Download.png"></a>
          </li>
          <li>
            <a href="https://apps.apple.com/be/app/aap-ka-bazar-online-grocery/id1640031723"><img
                src="../../../assets/image/iOS-Download.png"></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>