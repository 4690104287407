// utm.service.ts
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UtmService {
  constructor(private router: Router, private route: ActivatedRoute) {}

  captureUtmParameters() {
    this.route.queryParams.subscribe((params) => {
      const utmSource = params["utm_source"];
      const utmMedium = params["utm_medium"];
      const utmCampaign = params["utm_campaign"];
      const utm = params;
      localStorage.setItem("utm", JSON.stringify(utm));

      if (utmSource || utmMedium || utmCampaign) {
        localStorage.setItem("utm_source", utmSource || "");
        localStorage.setItem("utm_medium", utmMedium || "");
        localStorage.setItem("utm_campaign", utmCampaign || "");
      }
    });
  }
}
