import { Component, OnInit } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

  customOptions: CarouselModule = {
    loop: true,
    autoplay:true,
    autoplayTimeout:7000,
    autoplayHoverPause:false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    margin:20,
    stagePadding: 40,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
        margin:10
      },
      767: {items: 1},
      940: {items: 3}
    },
    nav: true
  }  
  SliderOwl1 = [   
    // {id: 1, link:"/tags/6411d185ee406b9ad569be3c", img: "https://images.aapkabazar.co/slider/nishanamkeen.jpg", alt:""}, 
    {id: 2, link:"/munakka-1-kg-premium-quality/p/61eaecb12da73066de6b461a", img: "https://images.aapkabazar.co/slider/munnaka.jpg", alt:""}, 
    {id: 2, link:"/wimpex-toilet-rolls-10-pack-/p/61ed212c742f0368e149aa35", img: "https://images.aapkabazar.co/Offers/toilet_wipes.webp", alt:""}, 
    {id: 3, link:"/comfort-rose-fresh-fabric-conditioner-imported-2-ltr/p/65aa78a1a9d51ad69cba1e30", img: "https://images.aapkabazar.co/Offers/comfort2l.webp", alt:""},
    //{id: 3, link:"/khadi-honey-1-kg/p/61ed30e9742f0368e149bbd1", img: "https://images.aapkabazar.co/slider/khadiHoney.jpg"},
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
