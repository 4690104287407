<main class="main">
  <!--Carousel Wrapper-->
  <mdb-carousel class="d-none">
    <!--First slide-->
    <mdb-carousel-item>
      <img class="d-block img-fluid" src="assets/image/Grocery-Products-image/about-us.jpg" alt="First slide">
    </mdb-carousel-item>
    <!--/First slide-->

  </mdb-carousel>



  <!-- latest about us -->
  <section class="staticSection container">
    <div class="row align-items-center py-3 px-4">
      <span class="mainHeadding">ABOUT US</span>
      <div class="abountHBox">
        <h3>AKB: Pioneering Excellence, Delivering the Best Quality to Your Doorstep.</h3>
        <span class="subContent">Aapkabazar.co is Delhi's Premier Online Grocery and Food Delivery Store with 28,000+
          products and 1000+ brands in our catalog, you'll find everything you need. That's why our tagline is
          #YahaSabMilega - Everything is here!.</span><br /><br />
        <span class="subContent">We're not just online; AKB operates four large stores across Delhi in Sector 7 Dwarka,
          Sector 12 Dwarka, Sector 23 Dwarka, and Mahavir Enclave. Our mission is to expand our reach, making groceries
          affordable for all customers by offering a wide range of products.</span><br /><br />
        <span class="subContent">From fresh dairy products to rice and dals, spices to snacks, beverages to bakery
          items,
          frozen goods to seasonings, decorative items to cosmetics, cookware to health supplements, and even pooja
          samagri
          and a plethora of packaged products. We offer a vast selection in every category, each item exclusively
          handpicked
          and hygienically packaged to ensure you discover the best quality at unbeatable prices.</span>
      </div>
      <div class="MainContent">
        <div class="AboutFirst">
          <div class="FirstLeft">
            <h4 style="width:100%">AKB: Where Quality Meets Convenience
            </h4>
            <p>
              Aapkabazar.co offers a seamless browsing experience for all products to say goodbye to traffic jams,
              parking fees, long queues, and lugging heavy bags. Online grocery shopping has never been easier before
              AKB which made the entire monthly shopping list now just a click away.<br />
              Simply pick your desired item/items, choose your payment method, and your order will be promptly delivered
              to your doorstep with a 100% return facility, no matter the season, weekday, weekend, or even during
              festivals and national holidays. Our delivery team ensures on-time delivery, just like superheroes!
            </p>
          </div>
          <div class="FirstRight">
            <img src="https://images.aapkabazar.co/About3.png">
          </div>
        </div>

        <div class="AboutSecond">
          <div class="SecondLeft">
            <h4 style="width:100%">A Grocery Store with Inspiring Story
            </h4>
            <p>
              In 2005, AKB began as a humble "Kirana Ki Dukan" with an investment of just 50 thousand rupees. There were
              fewer than 100 customers, and the founders had no helping hand. They personally handled sales/purchases on
              a small two-wheeler bike.
              <br>
              Despite the lack of business knowledge, the Founders firmly believed in "Karm Hi Pooja Hai". Therefore,
              with a brief morning prayer at 4 a.m., the rest of their time was devoted to the grocery store.
              For them, business was a 24/7 commitment therefore they never counted their working hours, often waking up
              early and returning home late at night. To supervise this journey Mr. Vikas Agrawal the eldest brother of
              their family played the role of a strong pillar and with the dedication and relentless effort of the
              founders transformed AKB from a single store to two, then three, now four, and finally online in 2021.
              <br>
              Furthermore, their other brother, Mr. Akash Agrawal, previously serving as a senior chartered accountant
              in a multinational company, decided to join and become another strong pillar of the organisation therefore
              founders always believe they have reached this point because of their family support.
              <br>
              From serving less than 100 customers, AKB now caters to thousands every day, and soon, they will be in the
              lakh. Today, there are numerous helping hands, all working tirelessly and handling everything with a
              smile.
              However, none of this would have been possible without the unwavering support and love of loyal customers.
              It's why a Small Grocery Store became Aap Ka Bazar - a store of the people, by the people, and for the
              people.
            </p>
          </div>
          <div class="SecondRight">
            <img src="https://images.aapkabazar.co/About1.png">
          </div>
        </div>

        <div class="AboutThird">
          <div class="ThirdLeft">
            <h4 style="width:100%">Every Purchase Powers AKB's Vision for a Better Society
            </h4>
            <p>
              With a mission to serve society by making commodities accessible with just a few clicks/steps, AKB is
              committed to fostering a positive environment for customers, vendors, and staff alike. <br />
              We embarked on this grocery journey with a clear objective that is to deliver the utmost satisfaction as
              the finest quality grocery provider, as a national service-oriented entrepreneur, and as a fair-minded,
              compassionate job creator. AKB consistently garners positive reviews and continues to move forward.
              Thank You for Visiting!
            </p>
          </div>
          <div class="ThirdRight">
            <img src="https://images.aapkabazar.co/About2.png">
          </div>
        </div>
      </div>
    </div>

    <section class="text-center about-us-2">
      <span class="mainHeadding">What We Provide?</span>
      <ul class="aboutList">
        <li>
          <img src="assets/image/icon/icon-1.svg" alt="">
          <h4>Best Prices &amp; Offers</h4>
        </li>
        <li>
          <img src="assets/image/icon/icon-4.svg" alt="">
          <h4>Wide Assortment</h4>
        </li>
        <li>
          <img src="assets/image/icon/icon-5.svg" alt="">
          <h4>Easy Returns</h4>
        </li>
        <li>
          <img src="assets/image/icon/icon-2.svg" alt="">
          <h4>Fast Delivery</h4>
        </li>
        <li>
          <img src="assets/image/icon/icon-4.svg" alt="">
          <h4>Pure &amp; Hygenically Packed</h4>
        </li>
        <li>
          <img src="assets/image/icon/icon-6.svg" alt="">
          <h4>Great Daily Deals Discount</h4>
        </li>
      </ul>
    </section>
  </section>

</main>