<main class="main">
    <div class="container">
      <h4>WE DELIVER TO</h4>
      <ul class="socity">
        <h5>Gr. Noida West</h5>
        <li class="pointer"  [routerLink]="['/']">ajnara homes</li>
        <li class="pointer"  [routerLink]="['/']">ajnara le garden</li>
        <li class="pointer"  [routerLink]="['/']">cherry county</li>
      </ul>
    </div>
  </main>
  